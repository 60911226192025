
.shoptab{
    display: flex;
    padding: 10px 30px;
    @media #{$device-sm} {
        padding: 10px;
    }
    background-color: var(--darkdeep3);
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    flex-wrap: wrap;
  &__inner{
    ul{
        li{
            padding-right: 10px;
            button{
                border: none;
                background: none;
                svg{
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    color: var(--blackColor);
                }
                &:hover,&.active{
                    svg{
                        color:var(--primaryColor)
                    }
                }
            }
        }
    }
  }
  &__shoing__wrap{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    p{
        margin: 0;
    }
  } 
  &__select{
    margin-right: 50px;
    align-items: center;
    color: var(--blackColor);
    label{
        margin-right: 10px;
    }
    select{
        height: 40px;
        padding: 10px;
        cursor: pointer;
        border: none;
        background: none;
        color: var(--blackColor);
        @media #{$device-sm} {
            max-width: 170px;
        }
    }
  } 
}



.shopsidebar{
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        h2{
            font-size: 22px;
        }
    }
    &__remove{
        a{
            position: relative;
            &:hover{
                &:after{
                    background: var(--primaryColor);
                }
            }
            &:after{
                position: absolute;
                content: "";
                width: 100%;
                height: 1px;
                background: var(--blackColor);
                bottom: -1px;
                left: 0;
            }
        }
    }
    &__bitton{
        margin-bottom: 30px;
    }
    &__widget{
        box-shadow: 0 0 10px 2px rgb(0 0 0 / 5%);
        padding: 20px;
        margin-bottom: 50px;
    }
    &__list{
        margin-top: 10px;
        ul{
            li{
            
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
               
            }
        }
    }
}



.shopsidebar__widget{
    summary {
        color: var(--blackColor);
    }
    .shopsidebar__box label {
        color: var(--blackColor);
    }
}

.swiper.modal__gallery {
    margin-top: 10px;
}