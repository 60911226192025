.error{
    color: red;
}

.text-justify{
    text-align: justify;
}

.signup-link{
    color:#5F2DED
}