/*------------------------------
Training Area
-------------------------------*/

.trainingarea__wraper {
    border: 1px solid rgba(126, 133, 139, 0.18);
    padding: 26px 30px 26px 26px;
    @media #{$device-sm} {
        padding: 20px;
    }
    border-radius: var(--borderRadius);
    transition: var(--transition);
    margin-bottom: 30px;
    &:hover {
        box-shadow: rgb(0 0 0 / 10%) 0px 4px 30px 0px;
        border-color: var(--primaryColor);
    }
    &.trainingarea__wraper__2 {
        &:hover {
            border-color: var(--greencolor);
        }
        .trainingarea__icon {
            i {
                color: var(--greencolor);
                border: 1px solid rgba(68 206 169 / 18%);
            }
        }
        .trainingarea__content {
            a {
                &:hover {
                    color: var(--greencolor);
                }
            }
        }
    }
    &.trainingarea__wraper__3 {
        &:hover {
            border-color: var(--secondaryColor2);
        }
        .trainingarea__icon {
            i {
                color: var(--secondaryColor2);
                border: 1px solid var(--secondaryColor2);
            }
        }
        .trainingarea__content {
            a {
                &:hover {
                    color: var(--secondaryColor2);
                }
            }
        }
    }
    .trainingarea__icon {
        display: flex;
        align-items: center;
        i {
            width: 71.94px;
            height: 69.44px;
            line-height: 69.44px;
            border: 1px solid rgba(95 45 237 / 15%);
            text-align: center;
            border-radius: 100%;
            color: var(--primaryColor);
            font-size: 26px;
            margin-right: 35px;
            background: rgba(142, 102, 255, .05);
        }
        .trainingarea__content {
            h6 {
                font-size: 20px;
                color: var(--blackColor);
                font-weight: 600;
                margin-bottom: 5px;
                @media #{$device-lg} {
                    font-size: 18px;
                }
            }
            p {
                font-size: 14px;
                color: var(--lightGrey3);
                margin-bottom: 0;
            }
        }
    }
}