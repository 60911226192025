/*------------------------------
Mobile Menu
-------------------------------*/


/*Mobile menu*/

.mob_menu_wrapper {
    display: none;
    padding-top: 15px;
    padding-bottom: 15px;
}

.mobile-off-canvas .mobile-aside-button {
    color: var(--darkdeep1);
    font-size: 30px;
}

.mobile-logo img {
    max-width: 150px;
}

.mobile-off-canvas .mobile-aside-button:hover {
    color: var(--secondaryColor);
}

.header-right-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.mobile-off-canvas {
    margin-left: 20px;
}

.mobile-off-canvas {
    text-align: right;
}

.mobile-aside-button {
    color: var(--darkdeep1);
    font-size: 20px;
}

.header_area .mob_menu_wrapper {
    display: none;
}

.wrapper .body-overlay {
    background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
}

.wrapper.overlay-active .body-overlay {
    opacity: 1;
    visibility: visible;
}

.mobile-off-canvas-active .mobile-aside-close {
    width: 40px;
    height: 40px;
    display: inline-block;
    background: var(--darkdeep1);
    color: var(--whiteColor);
    line-height: 40px;
    text-align: center;
    top: 0;
    left: -40px;
    font-size: 18px;
    position: absolute;
    cursor: pointer;
    transition: var(--transition);
    &:hover {
        background: var(--secondaryColor);
    }
}

.mobile-off-canvas-active {
    position: fixed;
    top: 0;
    width: 330px;
    min-height: 100vh;
    bottom: 0;
    z-index: 111;
    right: -330px;
    visibility: hidden;
    opacity: 1;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    background-color: var(--whiteColor);
    -webkit-box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
    z-index: 9999;
}

@media only screen and (max-width: 767px) {
    .mobile-off-canvas-active {
        width: 280px;
        right: -280px;
    }
}

.mobile-off-canvas-active.inside {
    visibility: visible;
    opacity: 1;
    right: 0px;
}

.mobile-aside-close .mobile-off-canvas-active .mobile-aside-close i {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    color: var(--whiteColor);
}

.mobile-off-canvas-active .mobile-aside-close:hover i {
    color: var(--whiteColor);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.mobile-off-canvas-active .header-mobile-aside-wrap {
    padding: 40px 30px 50px;
    overflow: auto;
    height: 100%;
    box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
}

@media only screen and (max-width: 767px) {
    .mobile-off-canvas-active .header-mobile-aside-wrap {
        padding: 30px 20px 50px;
    }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search {
    border-bottom: 1px solid var(--borderColor);
    margin-bottom: 27px;
    padding-bottom: 40px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form {
    position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form input {
    background-color: var(--whitegrey2);
    border: none;
    border-radius: 0px;
    height: 50px;
    padding: 0 60px 0 15px;
    width: 100%;
    font-size: 14px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
    background-color: transparent;
    border-color: var(--borderColor);
    -o-border-image: none;
    border-image: none;
    border-radius: 5px 0 0 5px;
    border-style: none none none solid;
    border-width: medium medium medium 1px;
    color: var(--darkdeep1);
    font-size: 18px;
    height: 100%;
    padding: 0 15px 0 14px;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
    .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
        font-size: 16px;
    }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button:hover {
    color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button i {
    margin-top: 5px;
    display: inline-block;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap {
    border-bottom: 1px solid var(--borderColor);
    margin-bottom: 36px;
    padding-bottom: 27px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav {
    height: 100%;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li {
    display: block;
    position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand {
    line-height: 50;
    top: -5px;
    left: 95%;
    width: 30px;
    position: absolute;
    height: 50px;
    text-align: center;
    cursor: pointer;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
    display: block;
    position: relative;
    width: 10px;
    margin-top: 25px;
    border-bottom: 1px solid var(--darkdeep1);
    -webkit-transition: all 250ms ease-out;
    transition: all 250ms ease-out;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
    top: 0;
    width: 100%;
    content: "";
    display: block;
    position: absolute;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    border-bottom: 1px solid var(--darkdeep1);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children.active>.menu-expand i::before {
    -webkit-transform: rotate(0);
    transform: rotate(0);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
    font-size: 16px;
    text-transform: capitalize;
    line-height: 18px;
    position: relative;
    display: inline-block;
    padding: 10px 0;
    font-family: var(--contentFont);
    color: var(--darkdeep1);
    font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a:hover {
    color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a {
    padding: 10px 15px 5px;
    font-size: 14px;
    color: var(--darkdeep1);
    font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
    color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li ul li a {
    padding: 10px 30px 5px;
    font-size: 14px;
    color: var(--darkdeep1);
    font-weight: 300;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
    border-bottom: 1px solid var(--borderColor);
    margin-bottom: 31px;
    padding-bottom: 37px;
}

@media only screen and (max-width: 767px) {
    .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
        display: block;
    }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
    position: relative;
    margin-bottom: 15px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a {
    font-size: 16px;
    display: block;
    letter-spacing: 0.2px;
    font-family: var(--contentFont);
    color: var(--darkdeep1);
    font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
    float: right;
    font-size: 15px;
    position: relative;
    top: 8px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
    margin-top: 5px;
    display: none;
    background-color: var(--whiteColor);
    -webkit-box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
    padding: 22px 30px 26px;
    width: 100%;
    z-index: 11;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
    padding-bottom: 10px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
    padding-bottom: 0px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: var(--contentFont);
    font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
    color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:hover>a {
    color: var(--secondaryColor);
}

@media only screen and (max-width: 767px) {
    .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
        margin-bottom: 5px;
    }
    .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
        margin-bottom: 0;
    }
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap {
    margin-bottom: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a {
    font-size: 16px;
    margin-right: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a:last-child {
    margin-right: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.facebook {
    color: #3b5999;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.twitter {
    color: #55acee;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.pinterest {
    color: var(--darkdeep1);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.instagram {
    color: var(--darkdeep1);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.google {
    color: var(--darkdeep1);
}

@media only screen and (max-width: 991px) {
    .desktop__menu__wrapper {
        display: none;
    }
    .mob_menu_wrapper {
        display: block;
    }
    .header__topbar {
        display: none;
    }
}


/* mobail__menu__end */

.mob_menu_wrapper .headerarea__right .header__cart::before {
    top: 5px;
    right: -5px;
}

.mob_menu_wrapper .headerarea__right .header__cart .header__right__dropdown__wrapper {
    left: auto;
    right: -50px;
}

.mob_menu_wrapper .headerarea__right .header__cart {
    padding: 0;
}












.mobile-language-active{
    display: none !important;
}