/*------------------------------
Team Area
-------------------------------*/

.teamarea {
    @media #{$device-lg} {
        padding-top: 40px;
        padding-bottom: 50px;
    }
    @media #{$device-md} {
        padding-top: 40px;
        padding-bottom: 50px;
    }
    @media #{$device-sm} {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.single__team {
    @media #{$device-md} {
        margin-bottom: 30px;
    }
    @media #{$device-sm} {
        margin-bottom: 30px;
    }
    .teamarea__img {
        position: relative;
        margin-bottom: 20px;
        img {
            width: 100%;
        }
        &:hover {
            .teamarea__icon {
                right: 20px;
                opacity: 1;
                visibility: visible;
            }
        }
        .teamarea__icon {
            position: absolute;
            top: 20px;
            right: 0;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
            ul {
                li {
                    display: block;
                    i {
                        height: 41px;
                        width: 41px;
                        line-height: 41px;
                        text-align: center;
                        font-weight: 400;
                        font-size: 13px;
                        color: var(--darkdeep4);
                        display: inline-block;
                        margin-bottom: 10px;
                        background: var(--whiteColor);
                        box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
                        border-radius: 5px;
                        transition: var(--transition);
                        &:hover {
                            background: var(--primaryColor);
                            color: var(--whiteColor);
                        }
                    }
                }
            }
        }
    }
    .teamarea__content {
        p {
            margin: 0;
        }
        h5 {
            font-weight: 600;
            font-size: 24px;
            line-height: 38px;
            color: var(--blackColor);
        }
    }
}

.teamarea__margin {
    margin-bottom: 20px;
}