/*------------------------------
Grid Area
-------------------------------*/

.gridarea {
    padding-top: 100px;
    padding-bottom: 70px;
    background: var(--lightGrey7);
    @media #{$device-lg} {
        padding-top: 80px;
        padding-bottom: 50px;
    }
    @media #{$device-md} {
        padding-top: 70px;
        padding-bottom: 50px;
    }
    @media #{$device-sm} {
        padding-top: 50px;
        padding-bottom: 40px;
    }
    &.gridarea__2 {
        background: var(--whiteColor);
    }
}

.gridarea__wraper {
    background: var(--whiteColor);
    padding: 15px;
    box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.05);
    margin-bottom: 30px;
    &.gridarea__wraper__2 {
        .gridarea__content {
            .gridarea__heading {
                h3 {
                    font-size: 18px;
                    line-height: 25px;
                }
            }
            .gridarea__bottom {
                .gridarea__small__img {
                    .gridarea__small__content {
                        h6 {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                        }
                    }
                }
            }
        }
    }
    &:hover {
        .gridarea__img {
            img {
                transform: scale(1.05);
            }
        }
    }
    .gridarea__img {
        position: relative;
        overflow: hidden;
        margin-bottom: 20px;
        img {
            width: 100%;
            border-radius: var(--borderRadius);
        }
        .gridarea__small__button {
            position: absolute;
            top: 3px;
            left: 8px;
            .grid__badge {
                background: var(--secondaryColor);
                color: var(--whiteColor);
                border-radius: 3px;
                font-weight: 600;
                font-size: 12px;
                display: inline-block;
                text-align: center;
                padding: 5px 15px;
                line-height: 1;
                &.blue__color {
                    background: var(--blue);
                }
                &.pink__color {
                    background: var(--secondaryColor2);
                }
                &.green__color {
                    background: var(--greencolor2);
                }
                &.orange__color {
                    background: var(--orange);
                }
                &.yellow__color {
                    background: var(--yellow);
                }
            }
        }
        .gridarea__small__icon {
            position: absolute;
            top: 3px;
            right: 8px;
            i {
                width: 30px;
                height: 27px;
                line-height: 27px;
                background: rgb(0 0 0 / 16%);
                display: inline-block;
                text-align: center;
                color: var(--whiteColor);
                border-radius: 3px;
                transition: var(--transition);
                &:hover {
                    background: var(--primaryColor);
                }
            }
        }
    }
    .gridarea__content {
        .gridarea__list {
            margin-bottom: 15px;
            ul {
                display: flex;
                @media #{$device-sm} {
                    flex-wrap: wrap;
                }
                li {
                    font-size: 14px;
                    width: 50%;
                    @media #{$device-sm} {
                        width: 100%;
                    }
                    color: var(--blackColor);
                    i {
                        font-size: 18px;
                        color: var(--primaryColor);
                        margin-right: 5px;
                    }
                }
            }
        }
        .gridarea__heading {
            h3 {
                margin-bottom: 10px;
                font-size: 20px;
                font-weight: 600;
                line-height: 27px;
                a {
                    color: var(--blackColor);
                    &:hover {
                        color: var(--primaryColor);
                    }
                }
            }
        }
        .gridarea__price {
            font-size: 18px;
            font-weight: 600;
            color: var(--primaryColor);
            margin-bottom: 20px;
            &.green__color {
                color: var(--lightGrey4);
                span {
                    color: var(--greencolor);
                    font-size: 16px;
                }
            }
            del {
                color: var(--lightGrey4);
                font-size: 13px;
            }
            span {
                margin-left: 25px;
                del {
                    color: var(--secondaryColor3);
                    font-size: 16px;
                }
            }
        }
        .gridarea__bottom {
            border-top: 1px solid var(--lightGrey5);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 15px;
            @media #{$device-sm} {
                flex-wrap: wrap;
            }
            h6 {
                &:hover {
                    color: var(--primaryColor)
                }
            }
            .gridarea__small__img {
                display: flex;
                align-items: center;
                img {
                    max-width: 30px;
                    border-radius: 50px;
                }
            }
            .gridarea__small__content {
                margin-left: 15px;
                h6 {
                    margin-bottom: 0;
                }
            }
            .gridarea__star {
                color: var(--yellow);
                font-size: 15px;
                span {
                    color: var(--lightGrey6);
                    font-size: 13px;
                }
            }
        }
    }
}


.grid__filter {
    padding: 20px 40px;
    background: var(--whiteColor);
    margin-bottom: 25px;
    box-shadow: 0px 30px 40px 0px rgb(1 11 60 / 6%);
    margin-right: 50px;
    margin-left: 50px;
    border-radius: var(--borderRadius);
    text-align: center;
    @media #{$device-lg} {
        padding: 10px;
        margin-right: 0;
        margin-left: 0;
    }
    @media #{$device-md} {
        padding: 10px;
        margin-right: 0;
        margin-left: 0;
    }
    @media #{$device-sm} {
        padding: 10px;
        margin-right: 0;
        margin-left: 0;
    }
    button {
        border: none;
        background: none;
        margin: 0 15px;
        padding: 0 15px;
        position: relative;
        font-weight: 500;
        color: var(--contentColor);
        @media #{$device-lg} {
            margin: 0 12px;
            padding: 0 12px;
        }
        @media #{$device-md} {
            margin: 0 8px;
            padding: 0 8px;
            font-size: 12px;
        }
        @media #{$device-sm} {
            margin: 0 10px;
            padding: 0 10px;
        }
        &::before {
            width: 0;
            transition: var(--transition);
            position: absolute;
            content: "";
            height: 2px;
            background: var(--primaryColor);
            bottom: -20px;
            left: 0;
            @media #{$device-lg} {
                bottom: -10px;
            }
            @media #{$device-md} {
                bottom: -15px;
            }
            @media #{$device-sm} {
                bottom: -3px;
            }
        }
        &:hover {
            &::before {
                width: 100%;
            }
        }
        &:last-child {
            margin-right: 0;
            padding-right: 0;
            &::after {
                display: none;
            }
        }
        &:hover {
            color: var(--primaryColor);
        }
        &.active {
            color: var(--primaryColor);
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 2px;
                background: var(--primaryColor);
                bottom: -20px;
                left: 0;
                @media #{$device-lg} {
                    bottom: -10px;
                }
                @media #{$device-md} {
                    bottom: -15px;
                }
                @media #{$device-sm} {
                    bottom: -3px;
                }
            }
        }
        &::after {
            position: absolute;
            content: "";
            width: 2px;
            height: 15px;
            background: var(--contentColor);
            bottom: 8px;
            right: -15px;
            @media #{$device-md} {
                bottom: 2px;
            }
        }
    }
}

.gridarea__wraper {
    &.gridarea__course__list {
        display: flex;
        margin-bottom: 30px;
        padding-right: 30px;
        @media #{$device-sm},
        #{$device-md} {
            flex-wrap: wrap;
            padding-right: 15px;
        }
        .gridarea__img {
            margin-bottom: 0;
            width: 35%;
            @media #{$device-sm} {
                margin-right: 0px;
                margin-bottom: 20px;
                width: 100%;
            }
        }
        .gridarea__content {
            width: 65%;
            padding-left: 90px;
            @media #{$device-lg} {
                padding-left: 30px;
            }
            @media #{$device-md} {
                padding-left: 20px;
            }
            @media #{$device-sm} {
                padding-left: 0;
                width: 100%;
            }
            .gridarea__heading {
                max-width: 95%;
            }
            .gridarea__heading {
                h3 {
                    font-weight: 600;
                    font-size: 34px;
                    line-height: 37px;
                    @media #{$device-lg} {
                        font-size: 20px;
                        line-height: 30px;
                    }
                    @media #{$device-md} {
                        font-size: 20px;
                        line-height: 30px;
                    }
                    @media #{$device-sm} {
                        font-size: 20px;
                        line-height: 25px;
                    }
                }
            }
            .gridarea__price {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: rgba(114, 106, 137, 0.8);
                span {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: var(--greencolor2);
                }
            }
            .gridarea__bottom {
                .gridarea__details {
                    a {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: var(--blackColor);
                        @media #{$device-md} {
                            font-size: 14px;
                        }
                        @media #{$device-sm} {
                            font-size: 13px;
                        }
                        &:hover {
                            color: var(--primaryColor);
                        }
                    }
                }
            }
        }
    }
}

.gridarea__bottom__left {
    display: flex;
    align-items: center;
    .gridarea__star {
        margin-left: 35px;
    }
}

.grid__row {
    align-items: center;
    margin-bottom: 25px;
}

.grid__filter__2 {
    text-align: right;
    @media #{$device-md} {
        text-align: left;
    }
    @media #{$device-sm} {
        text-align: left;
    }
    button {
        border: none;
        background: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 29px;
        padding-right: 40px;
        color: var(--contentColor);
        @media #{$device-lg} {
            padding-right: 17px;
        }
        @media #{$device-sm} {
            padding-right: 20px;
        }
        &:last-child {
            padding-right: 0;
        }
        &.active {
            color: var(--primaryColor);
        }
        &:hover {
            color: var(--primaryColor);
        }
    }
}



.product__grid{

    &:hover{
        .product__grid__action{
            bottom:20px;
            visibility: visible;
            opacity: 1;
        }
    }

    &__action{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        transition: var(--transition);
        opacity: 0;
        visibility: hidden;
        ul{
            display: flex;
            justify-content: center;
            li{
                margin: 0 3px;
                a{
                    height: 45px;
                    width: 50px;
                    display: block;
                    border: 1px solid var(--borderColor);
                    text-align: center;
                    line-height: 45px;
                    border-radius: var(--borderRadius);
                    background-color: var(--whiteColor);
                    font-size: 14px;
                    &:hover{
                        background: var(--primaryColor);
                        border-color:  var(--primaryColor);
                        color:  var(--whiteColor);
                    }
                    &.grid__cart {
                        width: auto;
                        padding: 0 20px;
                        @media #{$device-sm} {
                            padding: 0 5px;
                        }
                    }
                }
                svg{
                    height: 18px;
                    position: relative;
                    top: -2px;
                    path{
                        transition: var(--transition);
                    }
                }

            }
        }
    }
}
.gridarea__wraper.product__grid .gridarea__content .gridarea__heading h3 {
	font-size: 22px;
	line-height: 1;
	margin-bottom: 15px;
	font-weight: 500;
    @media #{$device-sm} {
        font-size: 20px;
    }
}
.gridarea__wraper.product__grid .gridarea__content .gridarea__price {
	line-height: 1;
}
.gridarea__wraper.product__grid .gridarea__content .gridarea__price {
	font-size: 24px;
}
.gridarea__wraper.product__grid .gridarea__content .gridarea__price del {
	font-size: 16px;
}




.zoom__meeting__grid{
    &.gridarea__wraper .gridarea__content .gridarea__heading h3 {
        font-size: 22px;
        line-height: 1;
        @media #{$device-sm} {
            font-size: 18px;
        }
    }
    .zoom__meeting__id {
        p{
            font-size: 14px;
        }
        span {
            font-weight: 700;
            color: var(--secondaryColor);
            font-size: 18px;
            margin: 10px;
            @media #{$device-sm} {
                font-size: 14px;
            }
        }
    }
    .zoom__meeting__time{
        p{
            font-size: 14px;
            display: flex;
            align-items: center;
            margin: 5px 0;
        }
        span {
            font-weight: 700;
            color: var(--primaryColor);
            font-size: 26px;
            margin-left: 10px;
            @media #{$device-sm} {
                font-size: 20px;
            }
        }
    }

     .gridarea__content .gridarea__bottom .gridarea__small__content {
        margin-left: 15px;
        font-size: 12px;
        h5{
            margin: 0;
            font-size: 18px;
        }
    }
    .gridarea__content .gridarea__bottom .gridarea__small__img img {
        max-width: 50px;
    }

}

.zoom__meetings__details .course__details__sidebar--2 {
	top: 0;
}