/*------------------------------
Course
-------------------------------*/

.course__sidebar__wraper {
    border: 1px solid var(--borderColor);
    padding: 40px 25px 33px 20px;
    margin-bottom: 10px;
    @media #{$device-lg} {
        padding: 30px 15px 23px 10px;
    }
    @media #{$device-md} {
        padding: 30px 15px 23px 10px;
        margin-bottom: 30px;
    }
    @media #{$device-sm} {
        padding: 30px 15px 23px 10px;
        margin-bottom: 30px;
    }
}

.course__heading {
    h5 {
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
    }
}

.course__input {
    position: relative;
    input {
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        height: 46px;
        padding-left: 13px;
        border: none;
        background: rgba(245, 245, 245, 0.71);
    }
    .search__button {
        position: absolute;
        top: 23%;
        right: 12px;
        button {
            background: none;
            border: none;
            &:hover {
                color: var(--secondaryColor);
            }
        }
    }
}

.course__categories__list {
    ul {
        li {
            display: block;
            &:last-child {
                a {
                    margin: 0;
                }
            }
            a {
                font-weight: 500;
                font-size: 14px;
                line-height: 26px;
                border: 1px solid var(--borderColor);
                width: 100%;
                padding: 8px 13px;
                display: block;
                margin-bottom: 15px;
                &:hover {
                    background: var(--primaryColor);
                    color: var(--whiteColor);
                }
                span {
                    float: right;
                }
            }
        }
    }
}

.course__tag__list {
    ul {
        li {
            display: block;
            margin-bottom: 23px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: flex;
                align-items: center;
                .course__check__box {
                    height: 15px;
                    width: 14px;
                    border: 1px solid var(--darkdeep6);
                    margin-right: 13px;
                    &:hover {
                        background: var(--primaryColor);
                        border: 1px solid var(--borderColor);
                    }
                    &.active {
                        background: var(--primaryColor);
                        border: 1px solid var(--borderColor);
                    }
                }
                span {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 26px;
                    &.active {
                        color: var(--primaryColor);
                    }
                }
            }
        }
    }
}

.course__skill__list {
    ul {
        li {
            font-weight: 500;
            font-size: 15px;
            line-height: 26px;
            margin-bottom: 10px;
            display: block;
            &:last-child {
                margin: 0;
            }
        }
    }
}

.course__text__wraper {
    border: 1px solid var(--borderColor);
    display: flex;
    justify-content: space-between;
    padding: 10px 13px;
    align-items: center;
    margin-bottom: 30px;
    @media #{$device-sm} {
        flex-wrap: wrap;
    }
    .course__text {
        @media #{$device-sm} {
            margin-bottom: 20px;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: var(--blackColor);
            margin: 0;
        }
    }
    .course__icon {
        ul {
            li {
                &.short__by__new {
                    padding-left: 80px;
                    padding-right: 10px;
                    @media #{$device-sm-small} {
                        padding-left: 50px;
                    }
                    .form-select {
                        border: none;
                        background-color: none;
                        appearance: down;
                    }
                }
                a {
                    margin-right: 11px;
                    &.active {
                        color: var(--primaryColor)
                    }
                }
            }
        }
    }
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list {
    @media #{$device-md},
    #{$device-sm} {
        padding-right: 15px;
    }
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__img {
    width: 40%;
    @media #{$device-md},
    #{$device-sm} {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__content {
    width: 60%;
    padding-left: 30px;
    @media #{$device-md},
    #{$device-sm} {
        width: 100%;
        padding-left: 0;
    }
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading h3 {
    font-size: 30px;
    line-height: 33px;
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__list ul li {
    width: 30%;
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading h3 {
    font-size: 26px;
    line-height: 30px;
}

.course__button__wraper {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;
}

.course__button {
    a {
        height: 26px;
        width: 114px;
        background: var(--primaryColor);
        color: var(--whiteColor);
        display: inline-block;
        text-align: center;
        line-height: 23px;
        font-weight: 600;
        font-size: 14px;
        margin-right: 20px;
        border-radius: var(--borderRadius);
        border: 1px solid var(--primaryColor);
        &:hover {
            background-color: var(--whiteColor);
            color: var(--primaryColor);
            border: 1px solid var(--primaryColor);
        }
        &.course__2 {
            background: var(--indigo);
            border: 1px solid var(--indigo);
            &:hover {
                background-color: var(--whiteColor);
                color: var(--indigo);
                border: 1px solid var(--indigo);
            }
        }
    }
}

.course__date {
    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin: 0;
        span {
            color: var(--blackColor);
        }
    }
}

.course__details__heading {
    h3 {
        font-weight: 700;
        font-size: 36px;
        line-height: 58px;
        color: var(--blackColor);
        @media #{$device-sm} {
            font-size: 32px;
            line-height: 43px;
        }
    }
}

.course__details__price {
    margin-bottom: 30px;
    ul {
        li {
            margin-right: 20px;
            .course__price {
                font-weight: 500;
                font-size: 21px;
                line-height: 25px;
                color: var(--primaryColor);
                del {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    color: var(--lightGrey4);
                }
            }
            .course__details__date {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: var(--blackColor);
                i {
                    color: var(--primaryColor);
                }
            }
            .course__star {
                i {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 15px;
                    color: var(--yellow);
                }
                span {
                    color: var(--blackColor);
                }
            }
        }
    }
}

.course__details__paragraph {
    margin-bottom: 30px;
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        @media #{$device-sm} {
            font-size: 14px;
        }
    }
}

.course__details__wraper {
    background: var(--darkdeep3);
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    ul {
        width: 50%;
        border-right: 2px solid var(--borderColor);
        padding: 55px 70px 55px 50px;
        @media #{$device-lg} {
            padding: 35px 30px 35px 30px;
        }
        @media #{$device-sm} {
            padding: 10px 10px 10px 10px;
            width: 100%;
        }
        &:last-child {
            border: none
        }
        li {
            color: var(--contentColor2);
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            @media #{$device-sm} {}
            &:last-child {
                margin-bottom: 0;
            }
            span {
                color: var(--deepgreen);
                font-weight: 500;
                @media #{$device-lg} {
                    font-size: 14px;
                }
                @media #{$device-sm} {}
            }
        }
    }
}

.course__details__tab__wrapper {
    margin-bottom: 40px;
}

.content__cirriculum__wrap {
    .accordion-button {
        font-size: 20px;
        color: var(--headingColor);
        font-weight: 700;
        box-shadow: none;
    }
    .accordion-body {
        padding: 10px 30px;
        @media #{$device-sm} {
            padding: 10px;
        }
    }
    .accordion-button:focus {
        box-shadow: none;
    }
}

.scc__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--borderColor);
    padding: 15px 0;
    flex-wrap: wrap;
}

.scc__wrap:last-child {
    border-bottom: none;
}

.scc__info {
    display: flex;
    align-items: center;
}

.scc__info h5 {
    margin: 0;
    font-size: 16px;
    font-weight: 300;
}

.scc__info i {
    margin-right: 10px;
    font-size: 16px;
}

.scc__info h5 span {
    font-weight: 500;
}

.scc__wrap .question {
    display: inline-block;
    height: 24px;
    line-height: 23px;
    color: var(--whiteColor);
    padding: 0 10px;
    border-radius: var(--borderRadius);
    background: var(--primaryColor);
    margin-left: 20px;
}

.scc__meta {
    font-size: 14px;
}

.content__cirriculum__wrap .accordion-item {
    margin-bottom: 25px;
    border: 1px solid var(--borderColor);
}

.course__tap__wrap {
    margin-bottom: 30px;
    li {
        border-right: 2px solid var(--borderColor);
        &:last-child {
            border: none;
        }
        &:hover {
            button {
                background: var(--primaryColor);
                color: var(--whiteColor);
            }
        }
        button {
            padding: 15px 45px;
            border: none;
            background: var(--whiteColor);
            color: var(--blackColor);
            box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
            @media #{$device-lg} {
                padding: 12px 25px;
            }
            @media #{$device-md} {
                padding: 15px 25px;
            }
            @media #{$device-sm} {
                padding: 10px;
            }
            i {
                margin-right: 8px;
            }
            &.active {
                background: var(--primaryColor);
                color: var(--whiteColor);
            }
        }
    }
}

.experence__heading {
    h5 {
        font-weight: 700;
        font-size: 26px;
        line-height: 56px;
        color: var(--blackColor);
    }
}

.experence__description {
    margin-bottom: 20px;
    p {
        font-weight: 400;
        line-height: 30px;
        font-size: 18px;
        color: var(--darkdeep4);
        margin-bottom: 20px;
        @media #{$device-lg} {
            font-size: 14px;
            line-height: 27px;
        }
        @media #{$device-sm} {
            font-size: 14px;
            line-height: 27px;
        }
    }
}

.course__list__wraper {
    .blog__details__list__2 {
        margin-bottom: 20px;
    }
}

.course__details__populer__list {
    li {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        .course__details__populer__content {
            margin-left: 20px;
            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: var(--primaryColor);
            }
            h6 {
                margin: 0;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                a {
                    color: var(--blackColor);
                    &:hover {
                        color: var(--primaryColor);
                    }
                }
            }
        }
    }
}

.review__box {
    background: var(--whiteColor);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    text-align: center;
    min-width: 200px;
    padding: 29px 10px;
    .review__number {
        font-weight: 800;
        font-size: 72px;
        line-height: 90px;
        color: var(--blackColor);
    }
    .review__icon {
        i {
            color: var(--secondaryColor);
        }
    }
    span {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: var(--blackColor);
    }
}

.review__wrapper {
    .single__progress__bar {
        position: relative;
        .rating__text {
            display: inline-block;
            position: relative;
            top: 19px;
            i {
                color: var(--secondaryColor);
            }
        }
        .progress {
            max-width: 85%;
            margin-left: 38px;
            height: 10px;
            background: var(--borderColor);
            @media #{$device-sm} {
                margin-right: 25px;
            }
            .progress-bar {
                background-color: var(--secondaryColor);
            }
        }
        span {
            position: absolute;
            right: 0;
            top: 50%;
        }
    }
}

.property__comment {
    .property__comment__list {
        list-style: none;
        border-top: 1px solid var(--borderColor2);
        padding-top: 35px;
        position: relative;
        margin-top: 35px;
        &:first-child {
            margin-top: 0;
        }
        .property__comment__img {
            max-width: 100px;
            margin-right: 30px;
            float: left;
            img {
                width: 100%;
                border-radius: 100%;
            }
        }
        .property__comment__comment {
            h6 {
                margin-bottom: 5px;
                font-size: 18px;
                font-weight: 600;
                a {
                    color: var(--headingColor);
                    &:hover {
                        color: var(--secondaryColor);
                    }
                }
            }
            .property__sidebar__icon {
                ul {
                    li {
                        i {
                            color: var(--secondaryColor);
                        }
                    }
                }
            }
            p {
                font-size: 14px;
                margin: 0;
            }
            span {
                position: absolute;
                padding: 0 25px 0;
                border: 2px solid var(--borderColor2);
                display: inline-block;
                border-radius: 25px;
                font-size: 14px;
                font-weight: 700;
                height: 40px;
                line-height: 36px;
                top: 27px;
                right: 0;
                transition: .3s;
                @media #{$device-sm-small} {
                    top: 172px;
                }
                &:hover {
                    border-color: var(--secondaryColor);
                }
            }
        }
    }
}

.add__a__review__wrapper {
    padding: 50px;
    background-color: var(--lightGrey12);
    margin-bottom: 50px;
    @media #{$device-sm} {
        padding: 20px;
    }
    h4 {
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 15px;
    }
    .add__a__review {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        h6 {
            margin: 0;
            margin-right: 15px;
        }
        .property__sidebar__icon {
            ul {
                li {
                    i {
                        color: var(--secondaryColor);
                        &:hover {
                            color: var(--primaryColor);
                        }
                    }
                }
            }
        }
    }
    .add__a__review__input {
        margin-bottom: 30px;
        input {
            padding: 15px 20px;
            min-height: 65px;
            width: 100%;
            border: 2px solid var(--whiteColor);
            font-size: 15px;
        }
        textarea {
            padding: 15px 20px;
            min-height: 150px;
            width: 100%;
            border: 2px solid var(--whiteColor);
            font-size: 15px;
            position: relative;
        }
    }
    .add__a__review__button {
        margin-top: 30px;
    }
}

.property__facts__feature {
    &.property__facts__feature__2 {
        margin-top: 60px;
        margin-bottom: 40px;
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 25px;
        border-left: 2px solid var(--secondaryColor);
        padding-left: 10px;
    }
    .single__grid__details__icon {
        margin-bottom: 25px;
        li {
            width: 33.33%;
        }
    }
    p {
        margin: 24px 0;
        font-size: 16px;
    }
}

.event__details__icon i {
    font-size: 22px;
}

.event__details__date a {
    color: var(--contentColor);
    font-weight: 700;
    &:hover {
        color: var(--secondaryColor);
    }
}

.course__details__top--2 {
    padding-top: 70px;
    position: relative;
    z-index: 9;
}

.course__details__sidebar--2 {
    position: relative;
    top: -340px;
    z-index: 10;
    @media #{$device-md},
    #{$device-sm} {
        top: 0;
    }
    .event__details__date {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .event__details__list ul li {
        padding-bottom: 18px;
        margin-bottom: 18px;
        color: var(--blackColor);
    }
}




.lesson__content__main {
    position: relative;
}

.lesson__content__wrap {
    position: absolute;
    z-index: 999;
    background: var(--primaryColor);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    @media #{$device-sm-small} {
        top: 5px 100px;
    }
    h3{
        color: var(--whiteColor);
        font-size: 22px;
        margin: 0;
        @media #{$device-sm-small} {
            font-size: 16px;
        }
    }
    a{
        color: var(--whiteColor);
    }
}

.course__details__sidebar .event__sidebar__wraper .event__price__wraper {
    margin-bottom: 20px;
}


.course__summery__lists{
    margin-top: 20px;
    li {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid var(--borderColor);
        .course__summery__item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            color: var(--contentColor);
            .sb_content {
                background: var(--borderColor);
                padding: 6px 10px;
                line-height: 13px;
                font-size: 12px;
                border-radius: 50px;
            }
            .sb_label {
                font-weight: 500;
            }
        }
    }
}

.course__summery__button {
    text-align: center;
    span{
        font-size: 13px;
        color: var(--contentColor);
    }
    .default__button {    
        width: 100%;
        margin-bottom: 10px;
    }
    p {
        margin-top: 20px;
        font-size: 14px;
        margin-bottom: 5px;
    }
    .default__button--3{
        font-size: 20px;
        font-weight: 700;
    }
}

.content__cirriculum__wrap .accordion-button span {
    font-size: 12px;
    background: var(--borderColor);
    padding: 4px 10px;
    margin-left: 10px;
    line-height: 1;
    border-radius: 20px;
    font-weight: 700;
}
