.dashboardarea__left__img {
  position: relative;
}

.upload__icon__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
}

.upload__icon__label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
}

.upload__icon__overlay:hover svg {
  stroke: #fff;
}
.image__loader__wrapper {
  width: 120px; /* Set this to the width of your image */
  height: 120px; /* Set this to the height of your image */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Optional background for loader */
  border-radius: 50%; /* To make it round, if the image is round */
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


