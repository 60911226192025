/*********************************************************************************

	Template Name: Edurock - Education LMS Template
    Author: Techboot
    Support: techbootmail@gmail.com
    Description: Edurock -  Education, online course, bootstrap 5.0 LMS Template.
    Version: 1.0.0

**********************************************************************************/
/**************************************************************

	Start Style sheet Indexing
	|
	|___ Default Styles
	|	|
	|	|___ Variables
	|	|___ Mixins
	|	|___ Reset
	|	|___ Typography
	|	|___ Extend
	|	|___ Animations
	|	|___ Short Code
	|	|___ Spacing
	|
	|
	|___Header Styles
	|	|___ header
	|	|___ main_menu
    |	|___ mobile_menu
	|
	|
	|___Section Styles
	|	|___ Hero Banner 
	|	|___ Education 
	|	|___ Training
	|	|___ About
	|	|___ Popular
	|	|___ Grid
	|	|___ Event
	|	|___ Register
	|	|___ testimonial
	|	|___ Counter
	|	|___ Team
	|
	|___Page Styles
	|	|___ Instructor 
	|	|___ Blog 
	|	|___ Course 
	|	|___ Contact 
	|	|___ Error 
	|	|___ Breadcrumb 
	|
	|___Footer Styles
	|	|___ Footer 
	|	|___ Copyright
	|
	|___Light/Dark Styles
	|	|___ Light/Dark 
	|
	|___ End Style Sheet Indexing

***************************************************************/
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Inter:wght@300;400;500;600;700;800;900&display=swap");
:root {
  --bodyColor: #222222;
  --bodyBg: #fbfbfb;
  --bodyBg1: #F7F7F7;
  --whiteColor: #ffffff;
  --whitegrey: #6A7C92;
  --whitegrey1: #EDEDED;
  --whitegrey2: #F2F6F7;
  --whitegrey3: #E7DEFF;
  --headingColor: #222222;
  --contentColor: #5F6C76;
  --contentColor2: #5C727D;
  --primaryColor: #5F2DED;
  --secondaryColor: #F2277E;
  --secondaryColor2: #FF275A;
  --secondaryColor3: #FF2828;
  --blackColor: #000000;
  --blackColor2: #191B1E;
  --darkblack: #0C0E2B;
  --darkcolor: #263546;
  --darkgray: #9FA2CE;
  --deepgray: #202942;
  --lightGrey: #7B8696;
  --lightGrey2: #7B8697;
  --lightGrey3: #7E858B;
  --lightGrey4: #A2A9B6;
  --lightGrey5: #F0F0F5;
  --lightGrey8: #F0F1F3;
  --lightGrey6: #6D6E75;
  --lightGrey7: #F3F4FD;
  --lightGrey9: #9F98B2;
  --lightGrey10: #F5F5FE;
  --lightGrey11: #F7F8FD;
  --lightGrey12: #f0f4f7;
  --greencolor: #44CEA9;
  --greencolor2: #1EC902;
  --greencolor3: #2EC14A;
  --orange: #FE2323;
  --borderColor: #eeeeee;
  --borderColor1: #E5E5E5;
  --borderColor2: #dddddd;
  --borderColor3: #f8f8f8;
  --borderColor4: #cacaca;
  --darkdeep1: #170F2F;
  --darkdeep2: #37404E;
  --darkdeep3: #F4F4F8;
  --darkdeep4: #949392;
  --darkdeep5: #62605D;
  --darkdeep6: #7E8285;
  --darkdeep7: #C4C4C4;
  --indigo: #B13BFF;
  --deepgreen: #133344;
  --deepblue: #505975;
  --deepred: #DC164D;
  --skycolor: #03A9F4;
  --yellow: #FF912C;
  --yellow1: #F0B410;
  --blue: #2864FF;
  --naveBlue: #17093e;
  --borderRadius: 4px;
  --borderRadius2: 20px;
  --borderRadius3: 10px;
  --transition: .3s;
  --bodyFont: "Inter", sans-serif;
  --headingFont: "Hind", sans-serif;
  --contentFont: "Inter", sans-serif;
  --disabledColor: #ccc; /* Light gray */
  --disabledTextColor: #999; /* Muted gray */
}

.is_dark {
  --bodyColor: #fff;
  --bodyBg: #0C0E2B;
  --bodyBg1: #0C0E2B;
  --headingColor: #fff;
  --blackColor: #fff;
  --blackColor2: #fff;
  --whiteColor: #0C0E2B;
  --darkdeep2: #fff;
  --darkblack: #fff;
  --lightGrey: #0C0E2B;
  --lightGrey7: #170F2F;
  --lightGrey10: #170F2F;
  --lightGrey11: #170F2F;
  --contentColor: #fff;
  --darkdeep5: #fff;
  --borderColor: #312a57;
  --whitegrey: #fff;
  --whitegrey1: #312a57;
  --whitegrey2: #312a57;
  --deepgray: #fff;
  --borderColor2: #fff;
  --darkdeep3: #17093e;
  --contentColor2: #fff;
  --deepgreen: #fff;
  --bs-body-color:#fff;
}

/*------------------------------
Default CSS
-------------------------------*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--bodyFont);
  background-color: var(--bodyBg);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  position: relative;
}

p {
  font-size: 16px;
  margin-bottom: 15px;
  color: var(--contentColor);
}

a {
  text-decoration: none;
  transition: var(--transition);
}

p,
a,
.btn,
button,
p,
input,
select,
textarea,
li,
img {
  -webkit-transition: all var(--transition) ease-out 0s;
  -moz-transition: all var(--transition) ease-out 0s;
  -ms-transition: all var(--transition) ease-out 0s;
  -o-transition: all var(--transition) ease-out 0s;
  transition: all var(--transition) ease-out 0s;
}

a {
  color: var(--contentColor);
}

a:hover {
  color: var(--primaryColor);
}

input:focus-visible {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headingColor);
  font-family: var(--headingFont);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: var(--headingFont);
  color: var(--blackColor);
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
  display: inline-block;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-right: var(--bs-gutter-x, 0.9375rem);
  padding-left: var(--bs-gutter-x, 0.9375rem);
  --bs-gutter-x: .9375rem;
}

.container-fluid.full__width__padding {
  padding: 0 30px;
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .container-fluid.full__width__padding {
    padding: 0 150px;
  }
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .container-fluid.full__width__padding {
    padding: 0 50px;
  }
}

.row {
  --bs-gutter-x: 1.875rem;
}

.column__custom__class {
  padding-left: 15px;
  padding-right: 15px;
}

.row__custom__class {
  margin-left: -15px;
  margin-right: -15px;
  padding: 0;
}

.sp_100 {
  padding: 100px 0;
}

.sp_90 {
  padding: 90px 0;
}

.sp_80 {
  padding: 80px 0;
}

.sp_70 {
  padding: 70px 0;
}

.sp_60 {
  padding: 60px 0;
}

.sp_50 {
  padding: 50px 0;
}

.sp_40 {
  padding: 40px 0;
}

.sp_30 {
  padding: 30px 0;
}

.sp_20 {
  padding: 20px 0;
}

.sp_top_120 {
  padding-top: 120px;
}

.sp_top_100 {
  padding-top: 100px;
}

.sp_top_90 {
  padding-top: 90px;
}

.sp_top_80 {
  padding-top: 80px;
}

.sp_top_70 {
  padding-top: 70px;
}

.sp_top_60 {
  padding-top: 60px;
}

.sp_top_50 {
  padding-top: 50px;
}

.sp_top_40 {
  padding-top: 40px;
}

.sp_top_30 {
  padding-top: 30px;
}

.sp_top_20 {
  padding-top: 20px;
}

.sp_bottom_110 {
  padding-bottom: 110px;
}

.sp_bottom_120 {
  padding-bottom: 120px;
}

.sp_bottom_100 {
  padding-bottom: 100px;
}

.sp_bottom_90 {
  padding-bottom: 90px;
}

.sp_bottom_80 {
  padding-bottom: 80px;
}

.sp_bottom_70 {
  padding-bottom: 70px;
}

.sp_bottom_60 {
  padding-bottom: 60px;
}

.sp_bottom_50 {
  padding-bottom: 50px;
}

.sp_bottom_40 {
  padding-bottom: 40px;
}

.sp_bottom_30 {
  padding-bottom: 30px;
}

.sp_bottom_20 {
  padding-bottom: 20px;
}

.col--30 {
  padding-left: 30px;
  padding-right: 30px;
}

#scrollUp {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--whiteColor);
  color: var(--primaryColor);
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
  position: fixed;
  display: block;
  box-shadow: 0px 10px 50px rgba(13, 38, 59, 0.15);
}

#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 20px;
  transition: 0.5s;
}

#scrollUp:hover i {
  background: var(--primaryColor);
  color: var(--whiteColor);
}

@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px) {
  .container {
    max-width: 1200px;
  }
}

.default__small__button {
  padding: 5px 23px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: var(--primaryColor);
  border-radius: var(--borderRadius2);
  background: var(--whitegrey3);
  display: inline-block;
  text-align: center;
  margin-bottom: 20px;
}
.default__small__button.default__small__button__2 {
  background: rgba(255, 255, 255, 0.07);
  border-radius: 14.5px;
  color: var(--whiteColor);
}

.default__button {
  padding: 10px 25px;
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  display: inline-block;
  text-align: center;
  border-radius: var(--borderRadius);
  font-size: 15px;
  border: 1px solid var(--primaryColor);
}
.default__button:disabled {
  background-color: var(--disabledColor); /* A lighter shade or gray color for disabled state */
  color: var(--blackColor); /* Lighter or muted text color */
  border: 1px solid var(--disabledColor); /* Matching border color */
  cursor: not-allowed;
  opacity: 0.7; 
}
.default__button:hover {
  background-color: var(--whiteColor);
  color: var(--primaryColor);
  border-color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}
.default__button.default__button--2 {
  background-color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
}
.default__button.default__button--2:hover {
  background-color: var(--whiteColor);
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
}
.default__button.default__button--3 {
  background-color: transparent;
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
}
.default__button.default__button--3:hover {
  color: var(--whiteColor);
  border: 1px solid var(--primaryColor);
  background-color: var(--primaryColor);
}
.default__button.default__button--4 {
  background-color: transparent;
  border: 1px solid var(--secondaryColor);
  color: var(--secondaryColor);
}
.default__button.default__button--4:hover {
  color: var(--whiteColor);
  border: 1px solid var(--secondaryColor);
  background-color: var(--secondaryColor);
}

@keyframes dot-anim {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes dot-anim-2 {
  0% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes up-do-anim {
  0%, 25%, 50%, 75%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes cir-anim {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes move5 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.theme__shadow__circle {
  position: fixed;
  left: -250px;
  top: 200px;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  background-image: linear-gradient(45deg, var(--secondaryColor), var(--primaryColor));
  opacity: 0.1;
  -webkit-filter: blur(100px);
  filter: blur(100px);
}

.theme__shadow__circle.shadow__right {
  left: auto;
  top: 100px;
  right: -250px;
  bottom: auto;
  opacity: 0.1;
  background-image: linear-gradient(45deg, var(--primaryColor), var(--secondaryColor));
}

.section__title {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .section__title {
    margin-bottom: 20px;
  }
}
.section__title .section__title__heading h2 {
  font-size: 42px;
  line-height: 54px;
  margin-bottom: 0;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .section__title .section__title__heading h2 {
    font-size: 38px;
    line-height: 50px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .section__title .section__title__heading h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section__title .section__title__heading h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
@media (max-width: 767px) {
  .section__title .section__title__heading h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

.heading__underline span {
  position: relative;
}
.heading__underline span:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 7px;
  background: var(--secondaryColor);
  bottom: 22px;
  left: 0;
  z-index: -1;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .heading__underline span:after {
    bottom: 17px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .heading__underline span:after {
    bottom: 19px;
  }
}
@media (max-width: 767px) {
  .heading__underline span:after {
    bottom: 16px;
  }
}

.section__title__2 .section__small__title {
  margin-bottom: 13px;
}
.section__title__2 .section__small__title span {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  color: var(--secondaryColor);
}
.section__title__2 .section__title__heading__2 {
  margin-bottom: 13px;
}
.section__title__2 .section__title__heading__2.section__title__heading__3 h2 {
  color: var(--blackColor);
}
.section__title__2 .section__title__heading__2.heading__fontsize__1 h2 {
  font-size: 65px;
  line-height: 72px;
}
.section__title__2 .section__title__heading__2.heading__fontsize__2 h2 {
  font-size: 47px;
  line-height: 50px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .section__title__2 .section__title__heading__2.heading__fontsize__2 h2 {
    font-size: 42px;
    line-height: 48px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .section__title__2 .section__title__heading__2.heading__fontsize__2 h2 {
    font-size: 35px;
    line-height: 45px;
  }
}
@media (max-width: 767px) {
  .section__title__2 .section__title__heading__2.heading__fontsize__2 h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
.section__title__2 .section__title__heading__2 h2 {
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.5px;
  color: var(--whiteColor);
}

#loading {
  background-color: var(--whiteColor);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999999999;
  margin-top: 0px;
  top: 0px;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 200px;
  width: 200px;
  margin-top: -100px;
  margin-left: -100px;
}

#object {
  width: 80px;
  height: 80px;
  background-color: var(--primaryColor);
  -webkit-animation: animate 1s infinite ease-in-out;
  animation: animate 1s infinite ease-in-out;
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: perspective(160px);
  }
  50% {
    -webkit-transform: perspective(160px) rotateY(-180deg);
  }
  100% {
    -webkit-transform: perspective(160px) rotateY(-180deg) rotateX(-180deg);
  }
}
@keyframes animate {
  0% {
    transform: perspective(160px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(160px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
    -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
  }
  100% {
    transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
    -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
  }
}
.mode_switcher.my_switcher {
  position: fixed;
  right: -50px;
  top: 100px;
  z-index: 9999999;
  transition: 0.3s;
}

.mode_switcher.my_switcher:hover {
  right: 0;
}

@media (min-width: 1500px) and (max-width: 1920px) {
  .mode_switcher.my_switcher {
    top: 300px;
  }
}
.mode_switcher.my_switcher button {
  display: flex;
  font-size: 20px;
  color: var(--whiteColor);
  background: var(--primaryColor);
  padding: 10px;
  line-height: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 90px;
  box-shadow: 0px 6px 34px rgba(215, 216, 222, 0.15);
  border: none;
}

.mode_switcher.my_switcher button svg {
  width: 20px;
  margin-right: 10px;
}

.mode_switcher.my_switcher button span {
  font-size: 16px;
}

.light__mode {
  display: none;
}

.is_dark .light__mode {
  display: block;
}

.is_dark .dark__mode {
  display: none;
}

/*****************************************************
    37. Preloader CSS Here 
****************************/
#back__preloader {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  display: -webkit- flex;
  display: -ms- flex;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  background: #ffffff;
  position: fixed;
  z-index: 999999;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.back__loader_logo {
  position: absolute;
}

.back__loader_logo img {
  width: 40px;
}

#back__circle_loader {
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 5px;
  border-top-color: #465fe9;
  border-right-color: #465fe9;
  border-bottom-color: #d5eaff;
  border-left-color: #d5eaff;
  border-radius: 50%;
  -webkit-animation: spinLoader 2s linear infinite;
  animation: spinLoader 2s linear infinite;
}

@-webkit-keyframes spinLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.modal__wraper {
  max-width: 1000px;
  margin: 150px auto;
  max-height: 750px;
  overflow: auto;
}
@media (min-width: 992px) and (max-width: 1365px), (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .modal__wraper {
    margin: 50px auto;
  }
}
.modal__wraper .modal-content {
  padding: 15px;
  background: var(--whiteColor);
}
.modal__wraper .modal-body {
  padding: 0;
}
.modal__wraper .btn-close {
  position: absolute;
  right: 10px;
  z-index: 99;
  background-color: var(--whiteColor);
}

/*------------------------------
Header Area
-------------------------------*/
.header__area.sticky {
  position: fixed;
  top: 0;
  background: var(--whiteColor);
  z-index: 9999;
  right: 0;
  left: 0;
  width: 100%;
  transition: 0.4s;
  -webkit-box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.15);
  -moz-box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.15);
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.15);
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
}

.headerarea .container {
  position: relative;
}
.headerarea__left {
  display: flex;
  height: 100%;
  align-items: center;
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .headerarea .container {
    max-width: 1330px;
  }
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .headerarea.headerarea__2 .container {
    max-width: 1590px;
  }
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .headerarea.headerarea__3 .container {
    max-width: 1460px;
  }
}
.headerarea__main__menu {
  text-align: center;
}
.headerarea__main__menu nav ul > li {
  position: relative;
}
.headerarea__main__menu nav ul > li a {
  padding: 40px 20px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  color: var(--blackColor);
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .headerarea__main__menu nav ul > li a {
    padding: 30px 15px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__main__menu nav ul > li a {
    padding: 20px 10px;
    font-size: 14px;
  }
}
.headerarea__main__menu nav ul > li:hover > a {
  color: var(--primaryColor);
}
.headerarea__main__menu nav ul > li .headerarea__submenu {
  position: absolute;
  background-color: var(--whiteColor);
  min-width: 200px;
  transition: 0.3s;
  top: 110%;
  left: 0;
  padding: 14px 0;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  box-shadow: 0 0 50px 10px rgba(15, 157, 193, 0.1);
  border-bottom-right-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
  text-align: left;
}
.headerarea__main__menu nav ul > li .headerarea__submenu > li {
  display: block;
  margin: 0;
  border-left: 2px solid transparent;
}
.headerarea__main__menu nav ul > li .headerarea__submenu > li:hover {
  border-left: 2px solid var(--primaryColor);
}
.headerarea__main__menu nav ul > li .headerarea__submenu > li:hover > a {
  color: var(--primaryColor);
  background-color: var(--whitegrey1);
}
.headerarea__main__menu nav ul > li .headerarea__submenu > li a {
  padding: 10px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerarea__main__menu nav ul > li:hover .headerarea__submenu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}
.headerarea__right {
  text-align: right;
  display: flex;
  justify-content: end;
  height: 100%;
  align-items: center;
}
.headerarea__right .header__cart {
  padding: 0 20px;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__right .header__cart {
    padding: 0px 10px;
  }
}
.headerarea__right .header__cart:hover > a {
  color: var(--secondaryColor);
}
.headerarea__right .header__cart:hover .header__right__dropdown__wrapper {
  visibility: visible;
  opacity: 1;
  top: 100%;
  transition: 0.3s;
}
.headerarea__right .header__cart > a {
  font-size: 24px;
  color: var(--blackColor);
  position: relative;
}
.headerarea__right .header__cart > a::before {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  position: absolute;
  top: 0;
  right: -10px;
  width: 15px;
  height: 15px;
  padding: 0 4px;
  content: "3";
  text-align: center;
  border-radius: 100%;
  background: var(--secondaryColor);
  color: var(--whiteColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__right .header__cart > a::before {
    top: 7px;
    right: 11px;
  }
}
.headerarea__right .header__cart .header__right__dropdown__wrapper {
  position: absolute;
  width: 300px;
  max-height: 700px;
  padding: 20px;
  border-bottom: 3px solid var(--borderColor);
  background-color: var(--whiteColor);
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.15);
  top: 120%;
  left: -117px;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__right .header__cart .header__right__dropdown__wrapper {
    left: -150px;
  }
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__close {
  position: absolute;
  right: 0;
  top: -10px;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__close a :hover {
  color: var(--secondaryColor);
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 30px;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner .single__header__right__dropdown {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner .single__header__right__dropdown .header__right__dropdown__img {
  width: 30%;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner .single__header__right__dropdown .header__right__dropdown__img a {
  border: 1px solid var(--borderColor);
  display: inline-block;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner .single__header__right__dropdown .header__right__dropdown__img img {
  width: 100%;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner .single__header__right__dropdown .header__right__dropdown__content {
  text-align: left;
  width: 70%;
  margin-left: 15px;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner .single__header__right__dropdown .header__right__dropdown__content a {
  font-size: 14px;
  line-height: 20px;
  color: var(--darkblack);
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner .single__header__right__dropdown .header__right__dropdown__content a:hover {
  color: var(--secondaryColor);
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner .single__header__right__dropdown .header__right__dropdown__content p {
  color: var(--darkblack);
  font-size: 14px;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__inner .single__header__right__dropdown .header__right__dropdown__content p span {
  color: var(--secondaryColor);
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .dropdown__price {
  text-align: left;
  font-size: 17px;
  margin-bottom: 20px;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .dropdown__price span {
  float: right;
  color: var(--secondaryColor);
  font-weight: 700;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__button a {
  display: block;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  padding: 10px 0px;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__button a.white__color {
  border: 1px solid var(--secondaryColor);
  margin-bottom: 20px;
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__button a.white__color:hover {
  background: var(--secondaryColor);
  color: var(--whiteColor);
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__button a.blue__color {
  background: var(--darkblack);
  color: var(--whiteColor);
}
.headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__button a.blue__color:hover {
  background: var(--secondaryColor);
}
.headerarea__login {
  margin-right: 15px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__login {
    margin-right: 7px;
  }
}
.headerarea__login a {
  color: var(--blackColor);
  font-size: 15px;
  font-weight: 600;
  padding: 8px 15px;
  border: 1px solid var(--borderColor1);
  display: inline-block;
  text-align: center;
  border-radius: var(--borderRadius);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__login a {
    padding: 8px 15px;
    font-size: 12px;
  }
}
.headerarea__login a:hover {
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  border-color: var(--primaryColor);
}
.headerarea__button a {
  padding: 8px 15px;
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  display: inline-block;
  text-align: center;
  border-radius: var(--borderRadius);
  font-size: 15px;
  border: 1px solid var(--primaryColor);
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .headerarea__button a {
    padding: 8px 10px;
    font-size: 12px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .headerarea__button a {
    padding: 8px 15px;
    font-size: 12px;
  }
}
.headerarea__button a:hover {
  background-color: var(--whiteColor);
  color: var(--primaryColor);
  border-color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}

.headerarea__submenu--third {
  position: absolute;
  right: 0;
  left: 100%;
  background-color: var(--whiteColor);
  min-width: 200px;
  top: 0;
  padding: 14px 0;
  opacity: 1;
  z-index: 999;
  box-shadow: 0 0 50px 10px rgba(15, 157, 193, 0.1);
  border-bottom-right-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
  text-align: left;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}

.headerarea__main__menu nav ul > li .headerarea__submenu li:hover .headerarea__submenu--third {
  opacity: 1;
  visibility: visible;
}

.headerarea__main__menu .headerarea__submenu .headerarea__submenu--third > li {
  border-left: 2px solid transparent;
  width: 100%;
}

.headerarea__main__menu .headerarea__submenu .headerarea__submenu--third > li:hover {
  border-left: 2px solid var(--primaryColor);
  background-color: var(--whitegrey1);
}

@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px), (min-width: 992px) and (max-width: 1365px) {
  .transparent__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-bottom: 1px solid var(--borderColor);
    z-index: 999;
  }
}

.headerarea__search__wrap {
  padding: 20px 0;
  border-bottom: 1px solid var(--borderColor);
}
.headerarea__search__wrap .headerarea__login {
  margin-right: 0;
}

.topbararea {
  background: var(--blackColor2);
  padding: 5px 0px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .topbararea {
    display: none;
  }
}
@media (max-width: 767px) {
  .topbararea {
    display: none;
  }
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .topbararea .container {
    max-width: 1460px;
  }
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .topbararea.topbararea--2 .container {
    max-width: 1590px;
  }
}

.topbar__left ul li {
  color: var(--whiteColor);
  font-size: 14px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .topbar__left ul li {
    font-size: 12px;
  }
}

.topbar__right {
  display: flex;
  justify-content: end;
  align-items: center;
}
.topbar__right .topbar__icon {
  margin-right: 5px;
}
.topbar__right .topbar__icon i {
  color: var(--primaryColor);
  font-size: 15px;
}
.topbar__right .topbar__text {
  margin-right: 37px;
}
.topbar__right .topbar__text p {
  margin: 0;
  color: var(--whiteColor);
  font-size: 14px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .topbar__right .topbar__text p {
    font-size: 12px;
  }
}
.topbar__right .topbar__list ul li:last-child a {
  margin-right: 0;
}
.topbar__right .topbar__list ul li a {
  font-size: 15px;
  color: var(--whiteColor);
  margin-right: 13px;
}
.topbar__right .topbar__list ul li a:hover {
  color: var(--primaryColor);
}

.headerarea__2 .row {
  align-items: center;
}
.headerarea__2__inner {
  display: flex;
}
.headerarea__2__info__img {
  height: 24px;
  width: 24px;
  position: relative;
  margin-right: 70px;
}
.headerarea__2__info__img:last-child {
  margin-right: 0;
}
.headerarea__2__info__img:hover .language__dropdown {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.headerarea__2__info__img a {
  display: flex;
  align-items: center;
}
.headerarea__2__info__img a img {
  border-radius: 100%;
  width: 100%;
  margin-right: 5px;
}
.headerarea__2__info__img .language__dropdown {
  box-shadow: 0 0 37px rgba(0, 0, 0, 0.07);
  position: absolute;
  z-index: 99;
  top: 50px;
  left: 0;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
}
.headerarea__2__info__img .language__dropdown li {
  display: block;
}
.headerarea__2__info__img .language__dropdown li a {
  display: block;
  padding: 10px;
  color: var(--blackColor);
  font-size: 0.8125rem;
  background-color: var(--whiteColor);
  min-width: 200px;
}
.headerarea__2__info__img .language__dropdown li a:hover {
  background: var(--darkdeep4);
  color: var(--whiteColor);
}
.headerarea__2__info__img .language__dropdown li a img {
  width: 18px;
  margin-right: 10px;
  margin-right: 10px;
  border-radius: 100%;
}
.headerarea__2__input {
  position: relative;
}
.headerarea__2__input input {
  width: 100%;
  border-radius: 50px;
  padding: 8px 0 7px 15px;
  border: 1px solid var(--borderColor);
  background: transparent;
}
.headerarea__2__input i {
  position: absolute;
  right: 27px;
  bottom: 17px;
}

.headerarea .mega__menu__wrapper {
  width: 100%;
  padding: 30px !important;
  max-height: 650px;
  overflow: auto;
}
.headerarea .mega__menu__item li {
  display: block;
  text-align: left;
}
.headerarea .mega__menu__item li a {
  padding: 10px;
  border-left: 2px solid transparent;
}
.headerarea .mega__menu__item li a:hover {
  background-color: var(--whitegrey1);
  border-left: 2px solid var(--primaryColor);
}
.headerarea .mega__menu__label {
  position: relative;
  background: var(--whitegrey3);
  font-size: 12px;
  padding: 5px 15px;
  line-height: 1;
  margin-left: 5px;
  color: var(--primaryColor);
  border-radius: var(--borderRadius);
}
.headerarea .mega__menu__label.new {
  color: var(--secondaryColor);
}
.headerarea .mega__menu__thumb__label {
  position: absolute;
  background: var(--secondaryColor);
  font-size: 12px;
  padding: 5px 15px;
  line-height: 1;
  color: var(--whiteColor);
  border-radius: var(--borderRadius);
  top: 5px;
  left: 5px;
}
.headerarea .mega__menu__title {
  margin-bottom: 10px;
}
.headerarea .mega__menu__title a {
  padding: 10px;
  text-align: left;
  font-size: 18px;
  border-bottom: 1px solid var(--borderColor);
}
.headerarea .mega__menu__img a {
  padding: 0;
}
.headerarea .mega__menu__img img {
  width: 100%;
  border-radius: var(--borderRadius);
}
.headerarea .mega__menu__thumb {
  position: relative;
  box-shadow: 0px 0 5px 5px rgba(0, 0, 0, 0.05);
  margin: 15px 0;
  transition: var(--transition);
}
.headerarea .mega__menu__thumb:hover {
  box-shadow: 0px 0 5px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}
.headerarea .mega__menu__thumb a {
  padding: 0;
  overflow: hidden;
}
.headerarea .mega__menu__thumb a:hover .mega__menu__thumb__title {
  bottom: 0;
  opacity: 1;
}
.headerarea .mega__menu__thumb img {
  width: 100%;
  border-radius: var(--borderRadius);
}
.headerarea .mega__menu__thumb__title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  color: var(--whiteColor);
  font-size: 14px;
  background: var(--primaryColor);
  padding: 10px;
  line-height: 1;
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  transition: var(--transition);
  opacity: 0;
  text-align: center;
}
.headerarea .mega__menu__grid__5 {
  padding: 15px 30px !important;
}
@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px), (min-width: 992px) and (max-width: 1365px) {
  .headerarea .mega__menu__grid__5 .mega__menu__single__wrap {
    width: 20%;
  }
}
.headerarea .headerarea__submenu li a {
  color: var(--contentColor);
}
.headerarea .tab__button__wrap {
  margin-bottom: 15px;
}
.headerarea .tab__button__wrap li button {
  padding: 12px 0;
  font-size: 18px;
}
.headerarea .tab__button__wrap li.nav-item {
  width: 50%;
}

/*------------------------------
Mobile Menu
-------------------------------*/
/*Mobile menu*/
.mob_menu_wrapper {
  display: none;
  padding-top: 15px;
  padding-bottom: 15px;
}

.mobile-off-canvas .mobile-aside-button {
  color: var(--darkdeep1);
  font-size: 30px;
}

.mobile-logo img {
  max-width: 150px;
}

.mobile-off-canvas .mobile-aside-button:hover {
  color: var(--secondaryColor);
}

.header-right-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobile-off-canvas {
  margin-left: 20px;
}

.mobile-off-canvas {
  text-align: right;
}

.mobile-aside-button {
  color: var(--darkdeep1);
  font-size: 20px;
}

.header_area .mob_menu_wrapper {
  display: none;
}

.wrapper .body-overlay {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.wrapper.overlay-active .body-overlay {
  opacity: 1;
  visibility: visible;
}

.mobile-off-canvas-active .mobile-aside-close {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: var(--darkdeep1);
  color: var(--whiteColor);
  line-height: 40px;
  text-align: center;
  top: 0;
  left: -40px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
  transition: var(--transition);
}
.mobile-off-canvas-active .mobile-aside-close:hover {
  background: var(--secondaryColor);
}

.mobile-off-canvas-active {
  position: fixed;
  top: 0;
  width: 330px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: -330px;
  visibility: hidden;
  opacity: 1;
  -webkit-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active {
    width: 280px;
    right: -280px;
  }
}
.mobile-off-canvas-active.inside {
  visibility: visible;
  opacity: 1;
  right: 0px;
}

.mobile-aside-close .mobile-off-canvas-active .mobile-aside-close i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  color: var(--whiteColor);
}

.mobile-off-canvas-active .mobile-aside-close:hover i {
  color: var(--whiteColor);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-off-canvas-active .header-mobile-aside-wrap {
  padding: 40px 30px 50px;
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap {
    padding: 30px 20px 50px;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 27px;
  padding-bottom: 40px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form {
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form input {
  background-color: var(--whitegrey2);
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
  background-color: transparent;
  border-color: var(--borderColor);
  -o-border-image: none;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: var(--darkdeep1);
  font-size: 18px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
    font-size: 16px;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button:hover {
  color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 36px;
  padding-bottom: 27px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav {
  height: 100%;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li {
  display: block;
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid var(--darkdeep1);
  -webkit-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  border-bottom: 1px solid var(--darkdeep1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
  font-family: var(--contentFont);
  color: var(--darkdeep1);
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a:hover {
  color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a {
  padding: 10px 15px 5px;
  font-size: 14px;
  color: var(--darkdeep1);
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
  color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li ul li a {
  padding: 10px 30px 5px;
  font-size: 14px;
  color: var(--darkdeep1);
  font-weight: 300;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 31px;
  padding-bottom: 37px;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
    display: block;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
  position: relative;
  margin-bottom: 15px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
  margin-bottom: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  font-size: 16px;
  display: block;
  letter-spacing: 0.2px;
  font-family: var(--contentFont);
  color: var(--darkdeep1);
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
  float: right;
  font-size: 15px;
  position: relative;
  top: 8px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: var(--contentFont);
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
  color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: var(--secondaryColor);
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
    margin-bottom: 5px;
  }
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap {
  margin-bottom: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a {
  font-size: 16px;
  margin-right: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a:last-child {
  margin-right: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.facebook {
  color: #3b5999;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.twitter {
  color: #55acee;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.pinterest {
  color: var(--darkdeep1);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.instagram {
  color: var(--darkdeep1);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.google {
  color: var(--darkdeep1);
}

@media only screen and (max-width: 991px) {
  .desktop__menu__wrapper {
    display: none;
  }
  .mob_menu_wrapper {
    display: block;
  }
  .header__topbar {
    display: none;
  }
}
/* mobail__menu__end */
.mob_menu_wrapper .headerarea__right .header__cart::before {
  top: 5px;
  right: -5px;
}

.mob_menu_wrapper .headerarea__right .header__cart .header__right__dropdown__wrapper {
  left: auto;
  right: -50px;
}

.mob_menu_wrapper .headerarea__right .header__cart {
  padding: 0;
}

.mobile-language-active {
  display: none !important;
}

/*------------------------------
Mobile Menu
-------------------------------*/
/*Mobile menu*/
.mob_menu_wrapper {
  display: none;
  padding-top: 15px;
  padding-bottom: 15px;
}

.mobile-off-canvas .mobile-aside-button {
  color: var(--darkdeep1);
  font-size: 30px;
}

.mobile-logo img {
  max-width: 150px;
}

.mobile-off-canvas .mobile-aside-button:hover {
  color: var(--secondaryColor);
}

.header-right-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobile-off-canvas {
  margin-left: 20px;
}

.mobile-off-canvas {
  text-align: right;
}

.mobile-aside-button {
  color: var(--darkdeep1);
  font-size: 20px;
}

.header_area .mob_menu_wrapper {
  display: none;
}

.wrapper .body-overlay {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}

.wrapper.overlay-active .body-overlay {
  opacity: 1;
  visibility: visible;
}

.mobile-off-canvas-active .mobile-aside-close {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: var(--darkdeep1);
  color: var(--whiteColor);
  line-height: 40px;
  text-align: center;
  top: 0;
  left: -40px;
  font-size: 18px;
  position: absolute;
  cursor: pointer;
  transition: var(--transition);
}
.mobile-off-canvas-active .mobile-aside-close:hover {
  background: var(--secondaryColor);
}

.mobile-off-canvas-active {
  position: fixed;
  top: 0;
  width: 330px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: -330px;
  visibility: hidden;
  opacity: 1;
  -webkit-transition: all 400ms ease-out;
  transition: all 400ms ease-out;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active {
    width: 280px;
    right: -280px;
  }
}
.mobile-off-canvas-active.inside {
  visibility: visible;
  opacity: 1;
  right: 0px;
}

.mobile-aside-close .mobile-off-canvas-active .mobile-aside-close i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  color: var(--whiteColor);
}

.mobile-off-canvas-active .mobile-aside-close:hover i {
  color: var(--whiteColor);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-off-canvas-active .header-mobile-aside-wrap {
  padding: 40px 30px 50px;
  overflow: auto;
  height: 100%;
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap {
    padding: 30px 20px 50px;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 27px;
  padding-bottom: 40px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form {
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form input {
  background-color: var(--whitegrey2);
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
  background-color: transparent;
  border-color: var(--borderColor);
  -o-border-image: none;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: var(--darkdeep1);
  font-size: 18px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
    font-size: 16px;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button:hover {
  color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 36px;
  padding-bottom: 27px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav {
  height: 100%;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li {
  display: block;
  position: relative;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid var(--darkdeep1);
  -webkit-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  border-bottom: 1px solid var(--darkdeep1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
  font-family: var(--contentFont);
  color: var(--darkdeep1);
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a:hover {
  color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a {
  padding: 10px 15px 5px;
  font-size: 14px;
  color: var(--darkdeep1);
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
  color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li ul li a {
  padding: 10px 30px 5px;
  font-size: 14px;
  color: var(--darkdeep1);
  font-weight: 300;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 31px;
  padding-bottom: 37px;
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
    display: block;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
  position: relative;
  margin-bottom: 15px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
  margin-bottom: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  font-size: 16px;
  display: block;
  letter-spacing: 0.2px;
  font-family: var(--contentFont);
  color: var(--darkdeep1);
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
  float: right;
  font-size: 15px;
  position: relative;
  top: 8px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: var(--whiteColor);
  -webkit-box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: var(--contentFont);
  font-weight: 500;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
  color: var(--secondaryColor);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: var(--secondaryColor);
}

@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
    margin-bottom: 5px;
  }
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap {
  margin-bottom: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a {
  font-size: 16px;
  margin-right: 20px;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a:last-child {
  margin-right: 0;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.facebook {
  color: #3b5999;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.twitter {
  color: #55acee;
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.pinterest {
  color: var(--darkdeep1);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.instagram {
  color: var(--darkdeep1);
}

.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.google {
  color: var(--darkdeep1);
}

@media only screen and (max-width: 991px) {
  .desktop__menu__wrapper {
    display: none;
  }
  .mob_menu_wrapper {
    display: block;
  }
  .header__topbar {
    display: none;
  }
}
/* mobail__menu__end */
.mob_menu_wrapper .headerarea__right .header__cart::before {
  top: 5px;
  right: -5px;
}

.mob_menu_wrapper .headerarea__right .header__cart .header__right__dropdown__wrapper {
  left: auto;
  right: -50px;
}

.mob_menu_wrapper .headerarea__right .header__cart {
  padding: 0;
}

.mobile-language-active {
  display: none !important;
}

/*------------------------------
Hero Banner Area
-------------------------------*/
.herobannerarea {
  background: var(--darkdeep1);
  padding-top: 80px;
  padding-bottom: 140px;
  position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobannerarea {
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .herobannerarea {
    padding-top: 50px;
    padding-bottom: 205px;
  }
}
.herobannerarea.herobannerarea__2 {
  background: var(--lightGrey11);
  padding-top: 155px;
  padding-bottom: 250px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobannerarea.herobannerarea__2 {
    padding-top: 100px;
    padding-bottom: 150px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobannerarea.herobannerarea__2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .herobannerarea.herobannerarea__2 .container {
    max-width: 1370px;
  }
}
.herobannerarea.herobannerarea__2 .herobannerarea__content__wraper {
  padding-right: 135px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea.herobannerarea__2 .herobannerarea__content__wraper {
    padding-right: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobannerarea.herobannerarea__2 .herobannerarea__content__wraper {
    padding-right: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobannerarea.herobannerarea__2 .herobannerarea__content__wraper {
    padding-right: 0px;
  }
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__2 .herobannerarea__content__wraper {
    padding-right: 0px;
  }
}
.herobannerarea .row {
  align-items: center;
}

.herobannerarea__text.herobannerarea__text__2 p {
  color: var(--blackColor);
}
.herobannerarea__text p {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: var(--whiteColor);
}
@media (max-width: 767px) {
  .herobannerarea__text p {
    font-size: 15px;
    line-height: 22px;
  }
}

.hreobannerarea__button {
  margin-top: 45px;
  display: flex;
  align-items: center;
}
.hreobannerarea__button a.herobannerarea__button__1 {
  padding: 17px 30px;
  background: var(--whiteColor);
  box-shadow: 0px 4px 13px 1px rgba(95, 45, 237, 0.16);
  border-radius: var(--borderRadius);
  transition: var(--transition);
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: var(--darkdeep2);
  border: 1px solid var(--whiteColor);
  margin-right: 30px;
}
@media (max-width: 767px) {
  .hreobannerarea__button a.herobannerarea__button__1 {
    padding: 12px 20px;
    font-size: 14px;
    line-height: 14px;
    margin-right: 6px;
  }
}
.hreobannerarea__button a.herobannerarea__button__1:hover {
  border: 1px solid var(--whiteColor);
  color: var(--whiteColor);
  background: var(--darkblack);
}
.hreobannerarea__button .herobannerarea__button__2 {
  background: none;
  color: var(--whiteColor);
  box-shadow: none;
}
.hreobannerarea__button .herobannerarea__button__2:hover {
  color: var(--secondaryColor);
}

.herobannerarea__icon .hero__icon__1 {
  left: 50%;
  bottom: 15%;
  animation: cir-anim 6s linear 0s infinite alternate;
}
@media (max-width: 767px) {
  .herobannerarea__icon .hero__icon__1 {
    left: 40%;
    bottom: 54%;
  }
}
.herobannerarea__icon .hero__icon__2 {
  top: 10%;
  left: 5%;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobannerarea__icon .hero__icon__2 {
    top: 4%;
    left: 42%;
  }
}
@media (max-width: 767px) {
  .herobannerarea__icon .hero__icon__2 {
    top: 1%;
    left: 65%;
  }
}
.herobannerarea__icon .hero__icon__3 {
  top: 5%;
  left: 45%;
}
.herobannerarea__icon .hero__icon__4 {
  bottom: 15%;
  right: 5%;
}
.herobannerarea__icon img {
  position: absolute;
}

.aboutarea__img__2 img {
  width: 100%;
}

.hreobannerarea__button__2 {
  margin-top: 30px;
}
.hreobannerarea__button__2 .hreobannerarea__button__3 {
  background: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  color: var(--whiteColor);
  margin-left: 28px;
}
@media (max-width: 767px) {
  .hreobannerarea__button__2 .hreobannerarea__button__3 {
    margin-left: 0px;
  }
}
.hreobannerarea__button__2 .hreobannerarea__button__3:hover {
  border: 1px solid var(--secondaryColor);
  color: var(--secondaryColor);
  background: var(--whiteColor);
}

.herobannerarea__icon__2 .herobanner__common__img {
  position: absolute;
  z-index: 9;
}
.herobannerarea__icon__2 .herobanner__common__img.herobanner__img__1 {
  left: 40px;
  bottom: 233px;
  opacity: 0.33;
  animation: 3s linear 0s infinite normal none running move5;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__1 {
    bottom: 112px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__1 {
    bottom: 112px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__1 {
    left: 248px;
    bottom: 143px;
  }
}
.herobannerarea__icon__2 .herobanner__common__img.herobanner__img__2 {
  top: 110px;
  left: 50px;
  animation: cir-anim 6s linear 0s infinite alternate;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__2 {
    top: 64px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__2 {
    top: 75px;
    left: 30px;
  }
}
@media (max-width: 767px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__2 {
    top: 0px;
    left: 0px;
  }
}
.herobannerarea__icon__2 .herobanner__common__img.herobanner__img__3 {
  top: 50px;
  left: 210px;
  animation: 3s linear 0s infinite alternate none running dot-anim;
}
@media (max-width: 767px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__3 {
    left: 140px;
    display: none;
  }
}
.herobannerarea__icon__2 .herobanner__common__img.herobanner__img__4 {
  top: 80px;
  left: 872px;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__4 {
    left: 595px;
  }
}
.herobannerarea__icon__2 .herobanner__common__img.herobanner__img__5 {
  top: 100px;
  right: 110px;
  animation: 3s linear 0s infinite alternate none running dot-anim-2;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__5 {
    right: 82px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__5 {
    top: 13px;
    right: 90px;
  }
}
@media (max-width: 767px) {
  .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__5 {
    top: 0px;
    right: 0px;
  }
}

.herobannerarea__box {
  margin-left: 70px;
  margin-right: 70px;
  border-radius: 15px;
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.05);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobannerarea__box {
    margin-left: 40px;
    margin-right: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobannerarea__box {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .herobannerarea__box {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.herobannerarea__title .herobannerarea__small__title {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .herobannerarea__title .herobannerarea__small__title {
    margin-bottom: 5px;
  }
}
.herobannerarea__title .herobannerarea__small__title span {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: var(--secondaryColor);
}
.herobannerarea__title .herobannerarea__title__heading__2.herobannerarea__title__heading__3 h2 {
  font-size: 65px;
  line-height: 72px;
  color: var(--blackColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobannerarea__title .herobannerarea__title__heading__2.herobannerarea__title__heading__3 h2 {
    font-size: 48px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .herobannerarea__title .herobannerarea__title__heading__2.herobannerarea__title__heading__3 h2 {
    font-size: 35px;
    line-height: 42px;
  }
}
.herobannerarea__title .herobannerarea__title__heading__2 h2 {
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.5px;
  color: var(--whiteColor);
}
.herobannerarea__title .herobannerarea__title__heading__2 h2 span {
  color: var(--secondaryColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .herobannerarea__title .herobannerarea__title__heading__2 h2 {
    font-size: 50px;
    line-height: 62px;
  }
}
@media (max-width: 767px) {
  .herobannerarea__title .herobannerarea__title__heading__2 h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .herobannerarea__clms .hero__course {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .herobannerarea__clms .hero__course {
    margin-top: 30px;
    padding: 0 30px;
  }
}
.herobannerarea__clms .gridarea__wraper.gridarea__wraper__2 .gridarea__content .gridarea__heading h3 {
  font-size: 24px;
  line-height: 1;
}
.herobannerarea__clms .gridarea__wraper.gridarea__wraper__2 .gridarea__content .gridarea__bottom .gridarea__small__img .gridarea__small__content h6 {
  font-size: 16px;
}
.herobannerarea__clms .gridarea__wraper {
  padding: 25px;
  border-radius: 10px;
}
.herobannerarea__clms .swiper-3d .swiper-slide-shadow {
  background: none;
}
.herobannerarea__clms.herobannerarea.herobannerarea__2 {
  padding-top: 70px;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .herobannerarea__clms.herobannerarea.herobannerarea__2 {
    padding: 50px 0;
  }
}

@media (min-width: 1500px) and (max-width: 1920px) {
  .herobannerarea__online__course .herobannerarea__title .herobannerarea__title__heading__2.herobannerarea__title__heading__3 h2 {
    font-size: 90px;
    line-height: 95px;
  }
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea__online__course .herobannerarea__title .herobannerarea__title__heading__2.herobannerarea__title__heading__3 h2 {
    font-size: 90px;
    line-height: 95px;
  }
}
@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea__online__course.herobannerarea {
    padding-top: 120px;
    padding-bottom: 160px;
  }
}

.herobanner__video {
  position: relative;
}
@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .herobanner__video {
    margin-top: 30px;
  }
}
.herobanner__video > img {
  width: 100%;
  border-radius: 10px;
}
.herobanner__video .video__pop__btn {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}

.slider__controls__wrap.slider__controls__pagination .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: transparent;
  outline: 2px solid var(--blackColor);
  outline-offset: 2px;
  margin: 0 8px;
}
.slider__controls__wrap.slider__controls__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  outline-color: var(--primaryColor);
  background: var(--primaryColor);
}
.slider__controls__wrap .swiper-pagination {
  bottom: 20px;
}
.slider__controls__wrap.slider__controls__arrows .arrow-btn {
  border: 1px solid var(--lightGrey4);
  width: 50px;
  height: 50px;
  border-radius: var(--borderRadius);
  transition: var(--transition);
  transform: translateY(-50%);
  margin-top: 0;
}
.slider__controls__wrap.slider__controls__arrows .arrow-btn:after {
  font-size: 20px;
  font-weight: 700;
  color: var(--lightGrey4);
  transition: var(--transition);
}
.slider__controls__wrap.slider__controls__arrows .arrow-btn:hover {
  border: 1px solid var(--primaryColor);
  background-color: var(--primaryColor);
}
.slider__controls__wrap.slider__controls__arrows .arrow-btn:hover:after {
  color: var(--whiteColor);
}

.herobannerarea .slider__controls__arrows .arrow-btn {
  right: 30px;
  opacity: 0;
  border-radius: 100px;
}
@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea .slider__controls__arrows .arrow-btn {
    height: 70px;
    width: 70px;
  }
}
.herobannerarea .slider__controls__arrows .swiper-button-prev {
  left: 30px;
}
.herobannerarea:hover .slider__controls__arrows .arrow-btn {
  right: 0;
  opacity: 1;
}
.herobannerarea:hover .slider__controls__arrows .swiper-button-prev {
  left: 0;
}

.hero__course .slider__controls__wrap .swiper-pagination {
  bottom: -20px;
}

@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea.herobannerarea__marketplace {
    padding-top: 130px;
    padding-bottom: 200px;
  }
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__marketplace {
    padding-bottom: 50px;
  }
}
.herobannerarea.herobannerarea__marketplace .herobannerarea__content__wraper {
  max-width: 80%;
  margin: auto;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__marketplace .herobannerarea__icon {
    position: relative;
    z-index: -9;
  }
}

.search__form {
  position: relative;
}
.search__form input {
  width: 100%;
  height: 60px;
  border-radius: 50px;
  border: 1px solid var(--borderColor);
  padding: 0 150px 0 260px;
}
@media (max-width: 767px) {
  .search__form input {
    padding: 0 140px 0 170px;
  }
}
.search__form .search__form__categories {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  left: 30px;
  align-items: center;
  cursor: pointer;
}
.search__form .search__form__categories svg {
  color: var(--blackColor);
  width: 20px;
}
.search__form .search__form__categories .icon {
  margin-right: 5px;
  height: 20px;
  line-height: 1;
}
.search__form button {
  display: flex;
}
.search__form button svg {
  width: 20px;
  margin-right: 5px;
}
.search__form .wide {
  border: none;
  background: none;
  cursor: pointer;
}
.search__form .search__btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translatey(-50%);
}
@media (min-width: 320px) and (max-width: 575px) {
  .search__form .search__btn {
    right: 0px;
    top: 70px;
    transform: translatey(0);
  }
}
.search__form .search__btn button {
  border-radius: 50px;
}

.keyfeatures {
  margin-top: 50px;
}
.keyfeatures li {
  color: var(--whiteColor);
  margin: 0 10px;
}

.herobannerarea__img img {
  width: 100%;
}

.herobannerarea__price {
  font-size: 40px;
  font-weight: 700;
  color: var(--primaryColor);
  display: flex;
  align-items: center;
  line-height: 1;
  margin-top: 30px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .herobannerarea__price {
    font-size: 30px;
  }
}
.herobannerarea__price del {
  color: var(--lightGrey4);
  font-size: 30px;
  font-weight: normal;
  margin-left: 10px;
}
.herobannerarea__price span {
  font-size: 12px;
  background: var(--secondaryColor);
  color: var(--whiteColor);
  padding: 5px 10px;
  border-radius: 5px;
  line-height: 1;
  margin-left: 15px;
}

.text-center .herobannerarea__price {
  justify-content: center;
}

.text-end .herobannerarea__price {
  justify-content: end;
}

@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea.herobannerarea__ecomarece {
    padding-top: 70px;
    padding-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__ecomarece {
    padding-bottom: 50px;
  }
}
.herobannerarea.herobannerarea__ecomarece .slider__controls__wrap .swiper-pagination {
  bottom: 80px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobannerarea.herobannerarea__ecomarece .slider__controls__wrap .swiper-pagination {
    bottom: 100px;
  }
}

@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea.herobannerarea__kindergarten {
    padding-top: 70px;
    padding-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__kindergarten {
    padding-bottom: 50px;
  }
}
.herobannerarea.herobannerarea__kindergarten .slider__controls__wrap .swiper-pagination {
  bottom: 30px;
}

.herobannerarea.herobannerarea__university {
  padding: 0;
}
.herobannerarea.herobannerarea__university .herobannerarea__single__slider {
  padding: 140px 0 170px;
}
@media (min-width: 992px) and (max-width: 1365px), (max-width: 767px) {
  .herobannerarea.herobannerarea__university .herobannerarea__single__slider {
    padding: 100px 0 150px;
  }
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__university .herobannerarea__single__slider {
    padding: 50px 0 100px;
  }
}
.herobannerarea.herobannerarea__university .herobannerarea__single__slider .herobannerarea__title h2 {
  line-height: 1.2;
  color: var(--whiteColor);
}
@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea.herobannerarea__university .herobannerarea__single__slider .herobannerarea__title h2 {
    font-size: 75px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .herobannerarea.herobannerarea__university .herobannerarea__single__slider .herobannerarea__title h2 {
    font-size: 50px;
  }
}
.herobannerarea.herobannerarea__university .herobannerarea__single__slider .herobannerarea__text p {
  color: var(--whiteColor);
}
.herobannerarea.herobannerarea__university .herobannerarea__single__slider::after {
  position: absolute;
  left: 0;
  top: 0;
  background: #000;
  height: 100%;
  width: 100%;
  content: "";
  opacity: 0.7;
  z-index: 9;
}
.herobannerarea.herobannerarea__university .swiper.university__slider__thumb {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.herobannerarea.herobannerarea__university .swiper.university__slider__thumb .swiper-wrapper {
  display: flex;
  justify-content: center;
}
.herobannerarea.herobannerarea__university .swiper.university__slider__thumb .swiper-wrapper .swiper-slide {
  max-height: 80px;
  max-width: 150px;
  cursor: pointer;
}
.herobannerarea.herobannerarea__university .swiper.university__slider__thumb .swiper-wrapper .swiper-slide img {
  width: 100%;
  border-radius: 10px;
}
.herobannerarea.herobannerarea__university .hreobannerarea__button__2 {
  margin-top: 50px;
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__university .hreobannerarea__button__2 {
    margin-top: 20px;
  }
}
.herobannerarea.herobannerarea__university .default__button {
  padding: 15px 40px;
  border-radius: 50px;
  font-size: 16px;
}
.herobannerarea.herobannerarea__university .container {
  position: relative;
  z-index: 999;
}
.herobannerarea.herobannerarea__university .herobannerarea__content__wraper {
  padding: 0;
}
.herobannerarea.herobannerarea__university .footerarea__icon__2 {
  text-align: center;
  margin-top: 50px;
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__university .footerarea__icon__2 {
    margin-top: 20px;
  }
}
.herobannerarea.herobannerarea__university .footerarea__icon__2 ul li a {
  width: 60px;
  height: 57px;
  line-height: 57px;
  border-radius: 50px;
  font-size: 20px;
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__university .footerarea__icon__2 ul li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
.herobannerarea.herobannerarea__university .footerarea__icon__2 p {
  color: var(--whiteColor);
}

@media (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea.herobannerarea__machine__learning {
    padding-top: 155px;
    padding-bottom: 180px;
  }
}
.herobannerarea.herobannerarea__machine__learning .herobannerarea__content__wraper {
  padding-right: 0;
}
.herobannerarea.herobannerarea__machine__learning .herobannerarea__title__heading__3 h2 {
  color: var(--whiteColor);
}
.herobannerarea.herobannerarea__machine__learning .herobannerarea__text__2 p {
  color: var(--whiteColor);
}
.herobannerarea.herobannerarea__machine__learning .herobannerarea__icon__2 .herobanner__common__img.herobanner__img__1 {
  left: 40px;
  bottom: 10%;
}
.herobannerarea.herobannerarea__machine__learning .slider__controls__wrap.slider__controls__pagination .swiper-pagination-bullet {
  outline: 2px solid var(--whiteColor);
}
.herobannerarea.herobannerarea__machine__learning .slider__controls__wrap.slider__controls__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--whiteColor);
}

@media (min-width: 1500px) and (max-width: 1920px), (min-width: 1366px) and (max-width: 1499px) {
  .herobannerarea.herobannerarea__single__course {
    padding-top: 100px;
    padding-bottom: 120px;
  }
}
@media (max-width: 767px) {
  .herobannerarea.herobannerarea__single__course {
    padding-bottom: 50px;
  }
}
.herobannerarea.herobannerarea__single__course .herobannerarea__content__wraper {
  padding-right: 0;
}
.herobannerarea .summery__single__course {
  margin: 50px 0 0;
  background: var(--borderColor);
  padding: 30px;
  border-radius: var(--borderRadius3);
}
.herobannerarea .summery__single__course ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.herobannerarea .summery__single__course ul li {
  margin: 0;
}
.herobannerarea .summery__single__course ul li .course__price {
  font-size: 30px;
  font-weight: 700;
}

/*------------------------------
Education Area
-------------------------------*/
.educationarea {
  padding-top: 240px;
  padding-bottom: 175px;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .educationarea {
    padding-top: 200px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea {
    padding-top: 50px;
  }
}
@media (max-width: 767px) {
  .educationarea {
    padding-top: 50px;
  }
}
.educationarea .row {
  align-items: center;
}
.educationarea__wrapper {
  padding-right: 160px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .educationarea__wrapper {
    padding-right: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea__wrapper {
    padding-right: 0px;
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .educationarea__wrapper {
    padding-right: 0px;
    padding-bottom: 30px;
  }
}
.educationarea__wrapper .educationarea__heading {
  margin-bottom: 18px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .educationarea__wrapper .educationarea__heading {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea__wrapper .educationarea__heading {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .educationarea__wrapper .educationarea__heading {
    margin-bottom: 0;
  }
}
.educationarea__wrapper .educationarea__heading h2 {
  font-size: 60px;
  font-family: var(--headingFont);
  line-height: 72px;
  font-weight: 700;
  color: var(--blackColor);
  margin: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .educationarea__wrapper .educationarea__heading h2 {
    font-size: 50px;
    line-height: 62px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea__wrapper .educationarea__heading h2 {
    font-size: 40px;
    line-height: 62px;
  }
}
@media (max-width: 767px) {
  .educationarea__wrapper .educationarea__heading h2 {
    font-size: 40px;
    line-height: 52px;
  }
}
.educationarea .educationarea__content {
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .educationarea .educationarea__content {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea .educationarea__content {
    margin-bottom: 20px;
  }
}
.educationarea .educationarea__content p {
  font-size: 18px;
  line-height: 30px;
  color: var(--contentColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .educationarea .educationarea__content p {
    font-size: 16px;
    line-height: 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea .educationarea__content p {
    font-size: 15px;
    line-height: 25px;
  }
}
@media (max-width: 767px) {
  .educationarea .educationarea__content p {
    font-size: 15px;
    line-height: 25px;
  }
}
.educationarea .educationarea__bottom__button input {
  height: 58px;
  width: 311px;
  padding-left: 13px;
  border: 1px solid var(--lightGrey2);
  border-radius: var(--borderRadius);
  margin-right: 12px;
}
.educationarea .educationarea__bottom__button input::placeholder {
  color: var(--lightGrey);
}
@media (max-width: 767px) {
  .educationarea .educationarea__bottom__button input {
    margin-bottom: 20px;
    margin-right: 0;
  }
}
.educationarea .educationarea__bottom__button a {
  padding: 14px 39px;
}
.educationarea .hero__shape {
  position: absolute;
}
.educationarea .hero__shape__1 {
  right: 16%;
  top: 60%;
  animation: 3s linear 0s infinite alternate none running dot-anim;
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea .hero__shape__1 {
    right: 31px;
    top: 70%;
  }
}
.educationarea .hero__shape__2 {
  right: 9%;
  top: 58%;
  animation: 3s linear 0s infinite alternate none running dot-anim-2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea .hero__shape__2 {
    right: 12%;
  }
}
.educationarea .hero__shape__3 {
  left: 50%;
  bottom: 15%;
  animation: cir-anim 6s linear 0s infinite alternate;
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea .hero__shape__3 {
    left: 4%;
  }
}
.educationarea .hero__shape__4 {
  left: 53%;
  top: 43%;
  animation: cir-anim 6s linear 0s infinite alternate;
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea .hero__shape__4 {
    left: 9%;
  }
}
@media (max-width: 767px) {
  .educationarea .hero__shape__4 {
    top: 41%;
  }
}

.educationarea__right__wraper {
  position: relative;
  height: 100%;
}
.educationarea__right__wraper .educationarea__img {
  text-align: right;
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea__right__wraper .educationarea__img {
    text-align: center;
  }
}
.educationarea__right__wraper .educationarea__img .education__img__2 {
  position: absolute;
  bottom: -93px;
  left: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea__right__wraper .educationarea__img .education__img__2 {
    left: 30px;
  }
}
.educationarea__right__wraper .educationarea__img .education__img__3 {
  position: absolute;
  z-index: -9;
  left: 20px;
  top: 40px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea__right__wraper .educationarea__img .education__img__3 {
    left: 30px;
  }
}
.educationarea__right__wraper .educationarea__img .education__img__4 {
  position: absolute;
  top: -22px;
  z-index: -1;
  right: -16px;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea__right__wraper .educationarea__img .education__img__4 {
    right: 155px;
  }
}
.educationarea__right__wraper .educationarea__small__img {
  position: absolute;
  right: 40px;
  bottom: -105px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .educationarea__right__wraper .educationarea__small__img {
    right: -50px;
    bottom: -125px;
    max-width: 160px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea__right__wraper .educationarea__small__img {
    right: 219px;
  }
}
@media (max-width: 767px) {
  .educationarea__right__wraper .educationarea__small__img {
    bottom: -136px;
  }
}
.educationarea__right__wraper .educationarea__small__img a img + img {
  margin-left: -35px;
}
.educationarea__right__wraper .educationarea__small__img a:hover img + img {
  margin-left: -30px;
}
.educationarea__right__wraper .educationarea__small__img .educationarea__small__para p {
  font-size: 14px;
  color: var(--lightGrey3);
}
.educationarea__right__wraper .educationarea__small__img .educationarea__small__para p span {
  color: var(--blackColor);
}
.educationarea__right__wraper .educationarea__small__img__2 {
  position: absolute;
  background: var(--whiteColor);
  width: 342px;
  height: 75px;
  top: 41px;
  right: -166px;
  padding: 10px;
  box-shadow: 0px 10px 20px 0px rgba(0, 5, 52, 0.1);
  animation: move5 3s infinite linear;
  display: flex;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .educationarea__right__wraper .educationarea__small__img__2 {
    right: -86px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .educationarea__right__wraper .educationarea__small__img__2 {
    right: -26px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .educationarea__right__wraper .educationarea__small__img__2 {
    right: 9px;
  }
}
@media (max-width: 767px) {
  .educationarea__right__wraper .educationarea__small__img__2 {
    right: 0;
    top: 24px;
    width: 300px;
  }
}
.educationarea__right__wraper .educationarea__small__img__2 img {
  margin-right: 20px;
}
.educationarea__right__wraper .educationarea__small__img__2 .educationarea__small__content span {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: var(--greencolor3);
}
.educationarea__right__wraper .educationarea__small__img__2 .educationarea__small__content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
}

.educationarea__img .aboutimg__4__img__1 {
  width: 100%;
}
@media (max-width: 767px) {
  .educationarea__img .aboutimg__4__img__2 {
    width: 100%;
  }
}

/*------------------------------
Training Area
-------------------------------*/
.trainingarea__wraper {
  border: 1px solid rgba(126, 133, 139, 0.18);
  padding: 26px 30px 26px 26px;
  border-radius: var(--borderRadius);
  transition: var(--transition);
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .trainingarea__wraper {
    padding: 20px;
  }
}
.trainingarea__wraper:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 30px 0px;
  border-color: var(--primaryColor);
}
.trainingarea__wraper.trainingarea__wraper__2:hover {
  border-color: var(--greencolor);
}
.trainingarea__wraper.trainingarea__wraper__2 .trainingarea__icon i {
  color: var(--greencolor);
  border: 1px solid rgba(68, 206, 169, 0.18);
}
.trainingarea__wraper.trainingarea__wraper__2 .trainingarea__content a:hover {
  color: var(--greencolor);
}
.trainingarea__wraper.trainingarea__wraper__3:hover {
  border-color: var(--secondaryColor2);
}
.trainingarea__wraper.trainingarea__wraper__3 .trainingarea__icon i {
  color: var(--secondaryColor2);
  border: 1px solid var(--secondaryColor2);
}
.trainingarea__wraper.trainingarea__wraper__3 .trainingarea__content a:hover {
  color: var(--secondaryColor2);
}
.trainingarea__wraper .trainingarea__icon {
  display: flex;
  align-items: center;
}
.trainingarea__wraper .trainingarea__icon i {
  width: 71.94px;
  height: 69.44px;
  line-height: 69.44px;
  border: 1px solid rgba(95, 45, 237, 0.15);
  text-align: center;
  border-radius: 100%;
  color: var(--primaryColor);
  font-size: 26px;
  margin-right: 35px;
  background: rgba(142, 102, 255, 0.05);
}
.trainingarea__wraper .trainingarea__icon .trainingarea__content h6 {
  font-size: 20px;
  color: var(--blackColor);
  font-weight: 600;
  margin-bottom: 5px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .trainingarea__wraper .trainingarea__icon .trainingarea__content h6 {
    font-size: 18px;
  }
}
.trainingarea__wraper .trainingarea__icon .trainingarea__content p {
  font-size: 14px;
  color: var(--lightGrey3);
  margin-bottom: 0;
}

/*------------------------------
About Area
-------------------------------*/
.aboutarea {
  position: relative;
}

.aboutarea__img__inner {
  position: relative;
  height: 100%;
}
@media (max-width: 767px) {
  .aboutarea__img__inner {
    margin-top: 90px;
  }
}
.aboutarea__img__inner .aboutarea__img .aboutimg__1 {
  width: 100%;
}
.aboutarea__img__inner .aboutarea__img .aboutimg__2 {
  position: absolute;
  left: 0;
  margin: auto;
  right: 0;
  top: 0;
}
@media (max-width: 767px) {
  .aboutarea__img__inner .aboutarea__img .aboutimg__2 {
    top: -20%;
    width: 100%;
  }
}

.aboutarea__content__wraper {
  padding-left: 35px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__content__wraper {
    margin-top: 30px;
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .aboutarea__content__wraper {
    margin-top: 30px;
    padding-left: 0;
  }
}
.aboutarea__content__wraper .aboutarea__headding {
  margin-bottom: 13px;
}
.aboutarea__content__wraper .aboutarea__headding h2 {
  font-size: 45px;
  line-height: 54px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .aboutarea__content__wraper .aboutarea__headding h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
.aboutarea__content__wraper .aboutarea__para {
  margin-bottom: 13px;
}
.aboutarea__content__wraper .aboutarea__para.aboutarea__para__2 {
  border-left: 2px solid var(--primaryColor);
  padding-left: 13px;
}
.aboutarea__content__wraper .aboutarea__para p {
  line-height: 29px;
}
@media (max-width: 767px) {
  .aboutarea__content__wraper .aboutarea__para p {
    font-size: 14px;
    line-height: 27px;
  }
}
.aboutarea__content__wraper .aboutarea__list ul li {
  margin-bottom: 14px;
  line-height: 30px;
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .aboutarea__content__wraper .aboutarea__list ul li {
    font-size: 14px;
    line-height: 25px;
  }
}
.aboutarea__content__wraper .aboutarea__list ul li i {
  height: 16px;
  width: 16px;
  line-height: 16px;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  border-radius: 100%;
  margin-right: 12px;
}
@media (max-width: 767px) {
  .aboutarea__content__wraper .aboutarea__list ul li i {
    margin-right: 5px;
  }
}
.aboutarea__content__wraper .aboutarea__bottom__button {
  margin-top: 20px;
}
.aboutarea__content__wraper .aboutarea__bottom__button a i {
  font-size: 16px;
}

.aboutarea__2 .aboutarea__content__wraper {
  padding-left: 0;
}
.aboutarea__2 .aboutarea__content__wraper .aboutarea__headding {
  margin-bottom: 25px;
}
.aboutarea__2 .aboutarea__content__wraper .aboutarea__para {
  margin-bottom: 25px;
}

.aboutarea__list__2 ul li {
  margin-bottom: 22px;
  color: var(--blackColor);
  font-weight: 500;
  display: block;
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__list__2 ul li {
    margin-bottom: 12px;
  }
}
@media (max-width: 767px) {
  .aboutarea__list__2 ul li {
    margin-bottom: 8px;
    font-size: 13px;
  }
}
.aboutarea__list__2 ul li:hover i {
  color: var(--whiteColor);
  background: var(--primaryColor);
}
.aboutarea__list__2 ul li i {
  width: 31px;
  height: 33px;
  line-height: 31px;
  text-align: center;
  background: rgba(95, 45, 237, 0.04);
  color: var(--primaryColor);
  transition: var(--transition);
  display: inline-block;
  margin-right: 15px;
}

.about__right__wraper__2 {
  position: relative;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__right__wraper__2 {
    margin-bottom: 30px;
  }
}
.about__right__wraper__2 .aboutarea__2__img__1 {
  margin-left: 70px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .about__right__wraper__2 .aboutarea__2__img__1 {
    margin-left: 0px;
  }
}
.about__right__wraper__2 .aboutarea__2__img__2 {
  position: absolute;
  bottom: 0;
  right: 15px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__right__wraper__2 .aboutarea__2__img__2 {
    right: 143px;
  }
}
@media (max-width: 767px) {
  .about__right__wraper__2 .aboutarea__2__img__2 {
    bottom: 91px;
    right: -17px;
  }
}
.about__right__wraper__2 .aboutarea__2__img__3 {
  position: absolute;
  top: -18px;
  left: 30px;
  z-index: -1;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
.about__right__wraper__2 .aboutarea__2__img__4 {
  position: absolute;
  top: 30%;
  left: 0;
  z-index: -1;
}

.aboutarea__2__text {
  background: var(--whiteColor);
  display: flex;
  border-left: 4px solid var(--primaryColor);
  max-width: 290px;
  box-shadow: 0px 30px 40px 0px rgba(1, 11, 60, 0.06);
  align-items: center;
  justify-content: center;
  padding: 25px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: move5 3s infinite linear;
}
@media (max-width: 767px) {
  .aboutarea__2__text {
    padding: 12px 10px;
  }
}
.aboutarea__2__text .aboutarea__counter {
  display: flex;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: var(--primaryColor);
  padding-right: 10px;
}
.aboutarea__2__text p {
  line-height: 26px;
  font-weight: 700;
  color: var(--blackColor);
  margin: 0;
}

.about__right__wraper__4 {
  position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__right__wraper__4 {
    margin-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .about__right__wraper__4 {
    margin-bottom: 80px;
  }
}
.about__right__wraper__4 .aboutimg__4__img__2 {
  position: absolute;
  left: 0;
  top: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__right__wraper__4 .aboutimg__4__img__2 {
    left: -23px;
  }
}

@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__5 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__5 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .aboutarea__5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.aboutarea__5__img {
  background-image: url(../img/about/about_8.png);
  position: relative;
}
.aboutarea__5__img img {
  width: 100%;
  border-radius: 10px;
}

.aboutarea__content__wraper__5 {
  margin-left: 65px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__content__wraper__5 {
    margin-left: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__content__wraper__5 {
    margin-left: 0px;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .aboutarea__content__wraper__5 {
    margin-left: 0px;
    margin-top: 30px;
  }
}
.aboutarea__content__wraper__5 .section__title {
  margin-bottom: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__content__wraper__5 .section__title {
    margin-bottom: 10px;
  }
}
.aboutarea__content__wraper__5 .aboutarea__5__small__icon__wraper {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 25px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__content__wraper__5 .aboutarea__5__small__icon__wraper {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
.aboutarea__content__wraper__5 .aboutarea__5__small__icon__wraper .aboutarea__5__small__icon {
  margin-right: 17px;
}
.aboutarea__content__wraper__5 .aboutarea__5__small__icon__wraper .aboutarea__small__heading {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--blackColor2);
}
.aboutarea__content__wraper__5 .aboutarea__5__small__icon__wraper .aboutarea__small__heading span {
  font-weight: 700;
}
.aboutarea__content__wraper__5 .aboutarea__bottom__button__5 {
  margin-top: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__content__wraper__5 .aboutarea__bottom__button__5 {
    margin-top: 10px;
  }
}

.about__button__wrap {
  margin-bottom: 50px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__button__wrap {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .about__button__wrap {
    margin-bottom: 10px;
  }
}
.about__button__wrap li {
  margin-right: 35px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__button__wrap li {
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .about__button__wrap li {
    margin-right: 14px;
  }
}
.about__button__wrap li button {
  padding: 15px 59px;
  border: none;
  background: var(--bodyBg1);
  text-transform: uppercase;
  color: var(--blackColor2);
  font-weight: 700;
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.08);
  position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__button__wrap li button {
    padding: 10px 40px;
  }
}
@media (max-width: 767px) {
  .about__button__wrap li button {
    padding: 10px 25px;
    margin-bottom: 10px;
  }
}
.about__button__wrap li button:after {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  background: var(--primaryColor);
  left: 0;
  bottom: 0;
  transition: var(--transition);
}
.about__button__wrap li button:hover:after {
  width: 100%;
}
.about__button__wrap li button.active:after {
  width: 100%;
}

.aboutarea__content__tap__wraper .paragraph__1 {
  margin-bottom: 25px;
}
.aboutarea__content__tap__wraper .aboutarea__tap__heading {
  margin-bottom: 30px;
}
.aboutarea__content__tap__wraper .aboutarea__tap__heading h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 0;
}
.aboutarea__content__tap__wraper img {
  width: 100%;
}

.brandarea {
  background: var(--lightGrey7);
}

.brandarea__wraper {
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
}
.brandarea__wraper .brandarea__img {
  width: 20%;
  margin: 25px 0 45px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .brandarea__wraper .brandarea__img {
    width: 25%;
  }
}
@media (max-width: 767px) {
  .brandarea__wraper .brandarea__img {
    width: 50%;
  }
}
.brandarea__wraper.brandarea__wraper__2 {
  background: var(--whiteColor);
  border-radius: 6px;
  z-index: 11;
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.05);
}
.brandarea__wraper.brandarea__wraper__2 .brandarea__img {
  margin: 45px 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .brandarea__wraper.brandarea__wraper__2 .brandarea__img {
    margin: 35px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .brandarea__wraper.brandarea__wraper__2 .brandarea__img {
    margin: 25px 0;
  }
}
@media (max-width: 767px) {
  .brandarea__wraper.brandarea__wraper__2 .brandarea__img {
    margin: 20px 0;
  }
}

.brandarea__2 {
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .brandarea__2 {
    transform: translateY(0);
  }
}
.brandarea__2 .container {
  max-width: 1510px;
}

.brandarea .section__title .section__title__heading h2 {
  max-width: 80%;
  margin: auto;
}
@media (max-width: 767px) {
  .brandarea .section__title .section__title__heading h2 {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tution__img {
    margin-bottom: 20px;
  }
}
.tution__img img {
  border-radius: 10px;
}

@media (min-width: 992px) and (max-width: 1365px) {
  .abouttabarea {
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .abouttabarea {
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .abouttabarea {
    padding-bottom: 50px;
  }
}

@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__content__tap__wraper .single__event__wraper .eventarea__content__wraper .single__event__heading h4 {
    font-size: 20px;
    margin-right: 0;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .aboutarea__content__tap__wraper .single__event__wraper .eventarea__img {
    width: 40%;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .aboutarea__content__tap__wraper .single__event__wraper .eventarea__img {
    margin-bottom: 20px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .aboutarea__content__tap__wraper .single__event__wraper.single__award .eventarea__content__wraper {
    width: 60%;
  }
}

.aboutarea__single__course .aboutarea__img img {
  width: 100%;
}

.about__padding__wrap {
  background: var(--darkdeep3);
  margin: 0 150px;
  padding: 120px 0;
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
  border-radius: 20px;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .about__padding__wrap {
    margin: 0 50px;
    padding: 50px 0;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__padding__wrap {
    margin: 0 50px;
    padding: 40px 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__padding__wrap {
    margin: 0 50px;
    padding: 40px 30px;
  }
}
@media (max-width: 767px) {
  .about__padding__wrap {
    margin: 0 10px;
    padding: 10px;
  }
}
.about__padding__wrap .instructor__list > ul {
  flex-wrap: wrap;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__padding__wrap .instructor__list > ul > li {
    width: 50%;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__padding__wrap .educationarea__img img {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__padding__wrap .aboutarea__5_img img {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .about__padding__wrap .tution__img img {
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .about__padding__wrap .tution__img img {
    width: 100%;
  }
}

.about__single__course__counter {
  padding-top: 30px;
}

/*------------------------------
Popular Area
-------------------------------*/
.populerarea__wraper {
  margin-bottom: 65px;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 991px) {
  .populerarea__heading {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .populerarea__heading {
    margin-bottom: 10px;
  }
}
.populerarea__heading a {
  margin-bottom: 25px;
}
.populerarea__heading h2 {
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .populerarea__heading h2 {
    font-size: 37px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .populerarea__heading h2 {
    font-size: 30px;
    line-height: 40px;
  }
}

.populerarea__content {
  padding-left: 50px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .populerarea__content {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .populerarea__content {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .populerarea__content {
    padding-left: 0;
  }
}
.populerarea__content p {
  line-height: 29px;
}

.populerarea__button {
  text-align: right;
}
@media (min-width: 768px) and (max-width: 991px) {
  .populerarea__button {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .populerarea__button {
    text-align: left;
  }
}
.populerarea__button a {
  background: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
}
.populerarea__button a:hover {
  background: var(--whiteColor);
  border: 1px solid var(--secondaryColor);
  color: var(--secondaryColor);
}

.single__service {
  padding: 30px;
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
  margin-bottom: 30px;
  transition: var(--transition);
  position: relative;
  border-radius: var(--borderRadius3);
  background: var(--whiteColor);
}
.single__service:hover {
  background: var(--primaryColor);
  transform: translateY(-5px);
}
.single__service:hover .service__small__img svg {
  opacity: 1;
}
.single__service:hover .service__img {
  color: var(--whiteColor);
}
.single__service:hover .service__img .service__icon path {
  fill: var(--whiteColor);
}
.single__service:hover .service__img .service__icon__bg path {
  fill: var(--whiteColor);
}
.single__service:hover .service__content h3 a {
  color: var(--whiteColor);
}
.single__service:hover .service__content h3 a:hover {
  color: var(--secondaryColor);
}
.single__service:hover .service__content p {
  color: var(--whiteColor);
}
.single__service:hover .service__button a {
  color: var(--whiteColor);
}
.single__service.ss_margin {
  margin-top: 50px;
}
@media (max-width: 767px) {
  .single__service.ss_margin {
    margin-top: 0;
  }
}
.single__service .service__content h3 a {
  color: var(--blackColor);
  font-family: var(--headingFont);
  transition: var(--transition);
}

.populerarea__2 {
  background-color: var(--lightGrey10);
}
.populerarea__2 .row {
  align-items: center;
}
.populerarea__2 .service__content h3 {
  font-size: 23px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
}
.populerarea__2 .service__button a:hover {
  color: var(--secondaryColor);
}
.populerarea__2 .single__service {
  padding: 35px;
}

.service__img {
  position: relative;
  margin-bottom: 10px;
}
.service__img img {
  max-width: 60px;
  margin-bottom: 10px;
}
.service__img svg {
  max-height: 60px;
  transition: var(--transition);
}
.service__img .service__bg__img svg {
  position: absolute;
  top: -13px;
  left: -10px;
  max-height: 60px;
  transition: var(--transition);
}

.service__content h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 5px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__content h3 {
    font-size: 17px;
    line-height: 15px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .service__content h3 {
    font-size: 17px;
  }
}
.service__content p {
  line-height: 29px;
  margin-bottom: 0;
}

.service__small__img svg {
  position: absolute;
  top: 20px;
  right: 30px;
  opacity: 0;
  max-height: 25px;
  max-width: 30px;
  transition: var(--transition);
}

@media (min-width: 992px) and (max-width: 1365px) {
  .populerarea__2 .service__content__2 h3 {
    font-size: 17px;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .populerarea__2 .service__content__2 h3 {
    margin-bottom: 0;
  }
}

.service__content__2 p {
  font-size: 14px;
  line-height: 24px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__content__2 p {
    font-size: 11px;
    line-height: 19px;
  }
}

.service__button {
  margin-top: 15px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .service__button {
    margin-top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service__button {
    margin-bottom: 0;
  }
}
.service__button a {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--blackColor);
}

.populerarea__2 {
  background: var(--lightGrey10);
}

@media (min-width: 768px) and (max-width: 991px) {
  .populerarea__content__wraper__2 {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .populerarea__content__wraper__2 {
    margin-bottom: 30px;
  }
}
.populerarea__content__wraper__2 .section__title {
  margin-bottom: 30px;
}
.populerarea__content__wraper__2 .populerarea__content__2 .populerarea__para__1 {
  margin-bottom: 35px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .populerarea__content__wraper__2 .populerarea__content__2 .populerarea__para__1 {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .populerarea__content__wraper__2 .populerarea__content__2 .populerarea__para__1 {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .populerarea__content__wraper__2 .populerarea__content__2 .populerarea__para__1 {
    margin-bottom: 10px;
  }
}
.populerarea__content__wraper__2 .populerarea__content__2 .populerarea__para__2 {
  margin-bottom: 40px;
  border-left: 3px solid var(--secondaryColor);
  padding-left: 10px;
}
.populerarea__content__wraper__2 .populerarea__button__2 .default__button {
  background: var(--secondaryColor);
  color: var(--whiteColor);
  border: 1px solid var(--secondaryColor);
}
.populerarea__content__wraper__2 .populerarea__button__2 .default__button:hover {
  border: 1px solid var(--secondaryColor);
  color: var(--secondaryColor);
  background: var(--whiteColor);
}

.populerarea__2 {
  background: var(--lightGrey10);
}

.service__wrap__content {
  position: relative;
}
.service__wrap__content .service__animate__shape__2 img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50px;
  animation: move5 3s infinite linear;
}

.about__wrap__content {
  position: relative;
}
.about__wrap__content .service__animate__shape__1 img {
  position: absolute;
  right: 50px;
  bottom: -50px;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}
@media (min-width: 768px) and (max-width: 991px) {
  .about__wrap__content .service__animate__shape__1 img {
    bottom: 38px;
  }
}
@media (max-width: 767px) {
  .about__wrap__content .service__animate__shape__1 img {
    bottom: 36px;
  }
}

.animate__content .single__service {
  width: 250px;
  margin: 0 10px;
}

.best__categories__item {
  margin-bottom: 30px;
}
.best__categories__item:hover .best__categories__link {
  background-color: var(--secondaryColor);
  color: var(--whiteColor);
}
.best__categories__item:hover .best__categories__name {
  color: var(--whiteColor);
}
.best__categories__link {
  display: flex;
  align-items: center;
  background-color: var(--borderColor);
  border-radius: 5px;
  padding: 20px 30px 15px;
  height: 100%;
  min-height: 70px;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  justify-content: center;
}
.best__categories__icon {
  flex-shrink: 0;
  max-width: 60px;
  width: 100%;
}
.best__categories__info {
  text-align: center;
}
.best__categories__name {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  margin: 0;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  display: block;
}
.best__categories__more {
  font-size: 12px;
}

/*------------------------------
Grid Area
-------------------------------*/
.gridarea {
  padding-top: 100px;
  padding-bottom: 70px;
  background: var(--lightGrey7);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .gridarea {
    padding-top: 80px;
    padding-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gridarea {
    padding-top: 70px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .gridarea {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}
.gridarea.gridarea__2 {
  background: var(--whiteColor);
}

.gridarea__wraper {
  background: var(--whiteColor);
  padding: 15px;
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.05);
  margin-bottom: 30px;
}
.gridarea__wraper.gridarea__wraper__2 .gridarea__content .gridarea__heading h3 {
  font-size: 18px;
  line-height: 25px;
}
.gridarea__wraper.gridarea__wraper__2 .gridarea__content .gridarea__bottom .gridarea__small__img .gridarea__small__content h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.gridarea__wraper:hover .gridarea__img img {
  transform: scale(1.05);
}
.gridarea__wraper .gridarea__img {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}
.gridarea__wraper .gridarea__img img {
  width: 100%;
  border-radius: var(--borderRadius);
}
.gridarea__wraper .gridarea__img .gridarea__small__button {
  position: absolute;
  top: 3px;
  left: 8px;
}
.gridarea__wraper .gridarea__img .gridarea__small__button .grid__badge {
  background: var(--secondaryColor);
  color: var(--whiteColor);
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  padding: 5px 15px;
  line-height: 1;
}
.gridarea__wraper .gridarea__img .gridarea__small__button .grid__badge.blue__color {
  background: var(--blue);
}
.gridarea__wraper .gridarea__img .gridarea__small__button .grid__badge.pink__color {
  background: var(--secondaryColor2);
}
.gridarea__wraper .gridarea__img .gridarea__small__button .grid__badge.green__color {
  background: var(--greencolor2);
}
.gridarea__wraper .gridarea__img .gridarea__small__button .grid__badge.orange__color {
  background: var(--orange);
}
.gridarea__wraper .gridarea__img .gridarea__small__button .grid__badge.yellow__color {
  background: var(--yellow);
}
.gridarea__wraper .gridarea__img .gridarea__small__icon {
  position: absolute;
  top: 3px;
  right: 8px;
}
.gridarea__wraper .gridarea__img .gridarea__small__icon i {
  width: 30px;
  height: 27px;
  line-height: 27px;
  background: rgba(0, 0, 0, 0.16);
  display: inline-block;
  text-align: center;
  color: var(--whiteColor);
  border-radius: 3px;
  transition: var(--transition);
}
.gridarea__wraper .gridarea__img .gridarea__small__icon i:hover {
  background: var(--primaryColor);
}
.gridarea__wraper .gridarea__content .gridarea__list {
  margin-bottom: 15px;
}
.gridarea__wraper .gridarea__content .gridarea__list ul {
  display: flex;
  justify-content: space-between; 
}
@media (max-width: 767px) {
  .gridarea__wraper .gridarea__content .gridarea__list ul {
    flex-wrap: wrap;
  }
}
.gridarea__wraper .gridarea__content .gridarea__list ul li {
  font-size: 14px;
  /* width: 100%; */
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .gridarea__wraper .gridarea__content .gridarea__list ul li {
    width: 100%;
  }
}
.gridarea__wraper .gridarea__content .gridarea__list ul li i {
  font-size: 18px;
  color: var(--primaryColor);
  margin-right: 5px;
}
.gridarea__wraper .gridarea__content .gridarea__heading h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}
.gridarea__wraper .gridarea__content .gridarea__heading h3 a {
  color: var(--blackColor);
}
.gridarea__wraper .gridarea__content .gridarea__heading h3 a:hover {
  color: var(--primaryColor);
}
.gridarea__wraper .gridarea__content .gridarea__price {
  font-size: 18px;
  font-weight: 600;
  color: var(--primaryColor);
  margin-bottom: 20px;
}
.gridarea__wraper .gridarea__content .gridarea__price.green__color {
  color: var(--lightGrey4);
}
.gridarea__wraper .gridarea__content .gridarea__price.green__color span {
  color: var(--greencolor);
  font-size: 16px;
}
.gridarea__wraper .gridarea__content .gridarea__price del {
  color: var(--lightGrey4);
  font-size: 13px;
}
.gridarea__wraper .gridarea__content .gridarea__price span {
  margin-left: 25px;
}
.gridarea__wraper .gridarea__content .gridarea__price span del {
  color: var(--secondaryColor3);
  font-size: 16px;
}
.gridarea__wraper .gridarea__content .gridarea__bottom {
  border-top: 1px solid var(--lightGrey5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}
@media (max-width: 767px) {
  .gridarea__wraper .gridarea__content .gridarea__bottom {
    flex-wrap: wrap;
  }
}
.gridarea__wraper .gridarea__content .gridarea__bottom h6:hover {
  color: var(--primaryColor);
}
.gridarea__wraper .gridarea__content .gridarea__bottom .gridarea__small__img {
  display: flex;
  align-items: center;
}
.gridarea__wraper .gridarea__content .gridarea__bottom .gridarea__small__img img {
  max-width: 30px;
  border-radius: 50px;
}
.gridarea__wraper .gridarea__content .gridarea__bottom .gridarea__small__content {
  margin-left: 15px;
}
.gridarea__wraper .gridarea__content .gridarea__bottom .gridarea__small__content h6 {
  margin-bottom: 0;
}
.gridarea__wraper .gridarea__content .gridarea__bottom .gridarea__star {
  color: var(--yellow);
  font-size: 15px;
}
.gridarea__wraper .gridarea__content .gridarea__bottom .gridarea__star span {
  color: var(--lightGrey6);
  font-size: 13px;
}

.grid__filter {
  padding: 20px 40px;
  background: var(--whiteColor);
  margin-bottom: 25px;
  box-shadow: 0px 30px 40px 0px rgba(1, 11, 60, 0.06);
  margin-right: 50px;
  margin-left: 50px;
  border-radius: var(--borderRadius);
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter {
    padding: 10px;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter {
    padding: 10px;
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .grid__filter {
    padding: 10px;
    margin-right: 0;
    margin-left: 0;
  }
}
.grid__filter button {
  border: none;
  background: none;
  margin: 0 15px;
  padding: 0 15px;
  position: relative;
  font-weight: 500;
  color: var(--contentColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter button {
    margin: 0 12px;
    padding: 0 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter button {
    margin: 0 8px;
    padding: 0 8px;
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .grid__filter button {
    margin: 0 10px;
    padding: 0 10px;
  }
}
.grid__filter button::before {
  width: 0;
  transition: var(--transition);
  position: absolute;
  content: "";
  height: 2px;
  background: var(--primaryColor);
  bottom: -20px;
  left: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter button::before {
    bottom: -10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter button::before {
    bottom: -15px;
  }
}
@media (max-width: 767px) {
  .grid__filter button::before {
    bottom: -3px;
  }
}
.grid__filter button:hover::before {
  width: 100%;
}
.grid__filter button:last-child {
  margin-right: 0;
  padding-right: 0;
}
.grid__filter button:last-child::after {
  display: none;
}
.grid__filter button:hover {
  color: var(--primaryColor);
}
.grid__filter button.active {
  color: var(--primaryColor);
}
.grid__filter button.active::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: var(--primaryColor);
  bottom: -20px;
  left: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter button.active::before {
    bottom: -10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter button.active::before {
    bottom: -15px;
  }
}
@media (max-width: 767px) {
  .grid__filter button.active::before {
    bottom: -3px;
  }
}
.grid__filter button::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  background: var(--contentColor);
  bottom: 8px;
  right: -15px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter button::after {
    bottom: 2px;
  }
}

.gridarea__wraper.gridarea__course__list {
  display: flex;
  margin-bottom: 30px;
  padding-right: 30px;
}
@media (max-width: 767px), (min-width: 768px) and (max-width: 991px) {
  .gridarea__wraper.gridarea__course__list {
    flex-wrap: wrap;
    padding-right: 15px;
  }
}
.gridarea__wraper.gridarea__course__list .gridarea__img {
  margin-bottom: 0;
  width: 35%;
}
@media (max-width: 767px) {
  .gridarea__wraper.gridarea__course__list .gridarea__img {
    margin-right: 0px;
    margin-bottom: 20px;
    width: 100%;
  }
}
.gridarea__wraper.gridarea__course__list .gridarea__content {
  width: 65%;
  padding-left: 90px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content {
    padding-left: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content {
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content {
    padding-left: 0;
    width: 100%;
  }
}
.gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading {
  max-width: 95%;
}
.gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading h3 {
  font-weight: 600;
  font-size: 34px;
  line-height: 37px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading h3 {
    font-size: 20px;
    line-height: 25px;
  }
}
.gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__price {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(114, 106, 137, 0.8);
}
.gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__price span {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--greencolor2);
}
.gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__bottom .gridarea__details a {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--blackColor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__bottom .gridarea__details a {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__bottom .gridarea__details a {
    font-size: 13px;
  }
}
.gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__bottom .gridarea__details a:hover {
  color: var(--primaryColor);
}

.gridarea__bottom__left {
  display: flex;
  align-items: center;
}
.gridarea__bottom__left .gridarea__star {
  margin-left: 35px;
}

.grid__row {
  align-items: center;
  margin-bottom: 25px;
}

.grid__filter__2 {
  text-align: right;
}
@media (min-width: 768px) and (max-width: 991px) {
  .grid__filter__2 {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .grid__filter__2 {
    text-align: left;
  }
}
.grid__filter__2 button {
  border: none;
  background: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  padding-right: 40px;
  color: var(--contentColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .grid__filter__2 button {
    padding-right: 17px;
  }
}
@media (max-width: 767px) {
  .grid__filter__2 button {
    padding-right: 20px;
  }
}
.grid__filter__2 button:last-child {
  padding-right: 0;
}
.grid__filter__2 button.active {
  color: var(--primaryColor);
}
.grid__filter__2 button:hover {
  color: var(--primaryColor);
}

.product__grid:hover .product__grid__action {
  bottom: 20px;
  visibility: visible;
  opacity: 1;
}
.product__grid__action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
}
.product__grid__action ul {
  display: flex;
  justify-content: center;
}
.product__grid__action ul li {
  margin: 0 3px;
}
.product__grid__action ul li a {
  height: 45px;
  width: 50px;
  display: block;
  border: 1px solid var(--borderColor);
  text-align: center;
  line-height: 45px;
  border-radius: var(--borderRadius);
  background-color: var(--whiteColor);
  font-size: 14px;
}
.product__grid__action ul li a:hover {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--whiteColor);
}
.product__grid__action ul li a.grid__cart {
  width: auto;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .product__grid__action ul li a.grid__cart {
    padding: 0 5px;
  }
}
.product__grid__action ul li svg {
  height: 18px;
  position: relative;
  top: -2px;
}
.product__grid__action ul li svg path {
  transition: var(--transition);
}

.gridarea__wraper.product__grid .gridarea__content .gridarea__heading h3 {
  font-size: 22px;
  line-height: 1;
  margin-bottom: 15px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .gridarea__wraper.product__grid .gridarea__content .gridarea__heading h3 {
    font-size: 20px;
  }
}

.gridarea__wraper.product__grid .gridarea__content .gridarea__price {
  line-height: 1;
}

.gridarea__wraper.product__grid .gridarea__content .gridarea__price {
  font-size: 24px;
}

.gridarea__wraper.product__grid .gridarea__content .gridarea__price del {
  font-size: 16px;
}

.zoom__meeting__grid.gridarea__wraper .gridarea__content .gridarea__heading h3 {
  font-size: 22px;
  line-height: 1;
}
@media (max-width: 767px) {
  .zoom__meeting__grid.gridarea__wraper .gridarea__content .gridarea__heading h3 {
    font-size: 18px;
  }
}
.zoom__meeting__grid .zoom__meeting__id p {
  font-size: 14px;
}
.zoom__meeting__grid .zoom__meeting__id span {
  font-weight: 700;
  color: var(--secondaryColor);
  font-size: 18px;
  margin: 10px;
}
@media (max-width: 767px) {
  .zoom__meeting__grid .zoom__meeting__id span {
    font-size: 14px;
  }
}
.zoom__meeting__grid .zoom__meeting__time p {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.zoom__meeting__grid .zoom__meeting__time span {
  font-weight: 700;
  color: var(--primaryColor);
  font-size: 26px;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .zoom__meeting__grid .zoom__meeting__time span {
    font-size: 20px;
  }
}
.zoom__meeting__grid .gridarea__content .gridarea__bottom .gridarea__small__content {
  margin-left: 15px;
  font-size: 12px;
}
.zoom__meeting__grid .gridarea__content .gridarea__bottom .gridarea__small__content h5 {
  margin: 0;
  font-size: 18px;
}
.zoom__meeting__grid .gridarea__content .gridarea__bottom .gridarea__small__img img {
  max-width: 50px;
}

.zoom__meetings__details .course__details__sidebar--2 {
  top: 0;
}

/*------------------------------
Event
-------------------------------*/
@media (min-width: 992px) and (max-width: 1365px) {
  .eventarea {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .eventarea {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .eventarea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.eventlistarea .container {
  position: relative;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .eventlistarea {
    padding-top: 50px;
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .eventlistarea {
    padding-top: 60px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .eventlistarea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.eventlistarea__bg svg {
  position: absolute;
  left: -135px;
  top: 20%;
  animation: 3s linear 0s infinite alternate none running dot-anim-2;
}

.eventarea__wraper {
  align-items: center;
}

.eventarea__section__title {
  max-width: 70%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .eventarea__section__title {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .eventarea__section__title {
    max-width: 100%;
  }
}

.eventarea__content p {
  position: relative;
  margin-bottom: 0;
}
.eventarea__content p::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: var(--darkdeep7);
  left: -30px;
}

.single__event__wraper {
  padding: 20px;
  box-shadow: 0px 30px 40px 0px rgba(1, 11, 60, 0.06);
  display: flex;
  background: var(--whiteColor);
  margin-bottom: 24px;
  transition: var(--transition);
  border-radius: var(--borderRadius);
}
.single__event__wraper:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__event__wraper {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .single__event__wraper {
    padding: 15px;
    flex-wrap: wrap;
  }
}
.single__event__wraper:hover {
  background: var(--primaryColor);
}
.single__event__wraper:hover .eventarea__content__wraper .eventarea__list ul li {
  color: var(--whiteColor);
}
.single__event__wraper:hover .eventarea__content__wraper .eventarea__list ul li i {
  color: var(--whiteColor);
}
.single__event__wraper:hover .eventarea__content__wraper .eventarea__list ul li a {
  color: var(--whiteColor);
}
.single__event__wraper:hover .eventarea__content__wraper .eventarea__list ul li a:hover {
  color: var(--secondaryColor);
}
.single__event__wraper:hover .eventarea__content__wraper .single__event__heading h4 a {
  color: var(--whiteColor);
}
.single__event__wraper:hover .eventarea__content__wraper .single__event__heading h4 a:hover {
  color: var(--secondaryColor);
}
.single__event__wraper:hover .eventarea__content__wraper .single__event__button a {
  color: var(--whiteColor);
}
.single__event__wraper:hover .eventarea__content__wraper .single__event__button a:hover {
  color: var(--secondaryColor);
}
.single__event__wraper.single__event__wraper__2 {
  justify-content: space-between;
  padding-left: 45px;
}
@media (max-width: 767px) {
  .single__event__wraper.single__event__wraper__2 {
    padding-left: 15px;
  }
}
.single__event__wraper.single__event__wraper__2 .eventarea__img {
  margin-right: 0;
  margin-left: 45px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__event__wraper.single__event__wraper__2 .eventarea__img {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .single__event__wraper.single__event__wraper__2 .eventarea__img {
    margin-left: 0px;
    width: 100%;
  }
}
.single__event__wraper.single__award {
  padding: 15px;
  margin-bottom: 30px;
}
.single__event__wraper.single__award .eventarea__content__wraper {
  padding: 0;
}
.single__event__wraper .eventarea__img {
  margin-right: 40px;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__event__wraper .eventarea__img {
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .single__event__wraper .eventarea__img {
    margin-right: 0px;
    width: 100%;
  }
}
.single__event__wraper .eventarea__content__wraper {
  padding: 18px 0;
}
.single__event__wraper .eventarea__content__wraper .eventarea__list {
  margin-bottom: 10px;
}
.single__event__wraper .eventarea__content__wraper .eventarea__list ul li {
  font-size: 14px;
  margin-right: 18px;
  color: var(--blackColor);
}
.single__event__wraper .eventarea__content__wraper .eventarea__list ul li i {
  color: var(--primaryColor);
  margin-right: 4px;
}
.single__event__wraper .eventarea__content__wraper .eventarea__list ul li span {
  color: var(--primaryColor);
}
.single__event__wraper .eventarea__content__wraper .eventarea__list ul li a {
  color: var(--primaryColor);
}
.single__event__wraper .eventarea__content__wraper .single__event__heading {
  margin-bottom: 15px;
}
.single__event__wraper .eventarea__content__wraper .single__event__heading h4 {
  font-size: 24px;
  font-weight: 600;
  margin-right: 40px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__event__wraper .eventarea__content__wraper .single__event__heading h4 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .single__event__wraper .eventarea__content__wraper .single__event__heading h4 {
    font-size: 15px;
  }
}
.single__event__wraper .eventarea__content__wraper .single__event__button a {
  color: var(--blackColor);
  font-size: 16px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__event__wraper .eventarea__content__wraper .single__event__button a {
    font-size: 14px;
  }
}
.single__event__wraper .eventarea__content__wraper .single__event__button a:hover {
  color: var(--primaryColor);
}
.single__event__wraper .eventarea__content__wraper .single__event__button a i {
  position: relative;
  top: 1px;
}

.tab__button__wrap {
  margin-bottom: 43px;
}
@media (max-width: 767px) {
  .tab__button__wrap {
    margin-top: 30px;
  }
}
.tab__button__wrap li {
  transition: var(--transition);
}
.tab__button__wrap li.nav-item {
  width: 20%;
  padding-right: 10px;
}
@media (max-width: 767px) {
  .tab__button__wrap li.nav-item {
    width: 50%;
  }
}
.tab__button__wrap li.nav-item:last-child {
  padding-right: 0;
}
.tab__button__wrap li button {
  border: none;
  padding: 18px 0;
  background: var(--lightGrey7);
  margin-right: 11px;
  font-size: 22px;
  font-weight: 600;
  font-family: var(--headingFont);
  color: var(--blackColor);
  width: 100%;
  border-radius: var(--borderRadius);
  position: relative;
  transition: var(--transition);
}
@media (min-width: 768px) and (max-width: 991px) {
  .tab__button__wrap li button {
    padding: 7px 0;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .tab__button__wrap li button {
    font-size: 18px;
    padding: 7px 0;
    margin-bottom: 10px;
  }
}
.tab__button__wrap li button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background: var(--whiteColor);
}
.tab__button__wrap li button:hover::after {
  width: 100%;
}
.tab__button__wrap li button::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 0;
  background: var(--primaryColor);
  top: 0;
  left: 0;
  transition: var(--transition);
  border-radius: 0px 0px 2px 2px;
}
.tab__button__wrap li button:hover {
  color: var(--primaryColor);
}
.tab__button__wrap li button.active {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  background: var(--whiteColor);
}
.tab__button__wrap li button.active::after {
  width: 100%;
}

.pricingarea__content__wraper {
  padding: 50px 40px 40px 40px;
  background: var(--lightGrey10);
  transition: var(--transition);
  border-radius: var(--borderRadius3);
}
@media (min-width: 768px) and (max-width: 991px) {
  .pricingarea__content__wraper {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .pricingarea__content__wraper {
    margin-bottom: 30px;
  }
}
.pricingarea__content__wraper:hover {
  background: var(--whiteColor);
  box-shadow: 0px 4px 49px rgba(0, 0, 0, 0.05);
}
.pricingarea__content__wraper .pricingarea__heading {
  margin-bottom: 55px;
}
.pricingarea__content__wraper .pricingarea__heading h6 {
  font-weight: 600;
  font-size: 28px;
  line-height: 45px;
  color: var(--blackColor);
}
.pricingarea__content__wraper .pricingarea__heading .pricingarea__number {
  align-items: center;
}
.pricingarea__content__wraper .pricingarea__heading .pricingarea__number h1 {
  font-weight: 500;
  font-size: 48px;
  color: var(--blackColor);
}
.pricingarea__content__wraper .pricingarea__heading .pricingarea__number h1 .price__currency {
  font-size: 58px;
  padding-right: 3px;
}
.pricingarea__content__wraper .pricingarea__heading .pricingarea__number h1 .price__durition {
  font-weight: 500;
  font-size: 22px;
  line-height: 35px;
  color: var(--contentColor2);
  padding-left: 3px;
}
.pricingarea__content__wraper .pricingarea__heading p {
  line-height: 19px;
  color: var(--blackColor);
  font-weight: 400;
  margin-bottom: 0;
}
.pricingarea__content__wraper .pricingarea__list ul li {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--deepblue);
  margin-bottom: 30px;
}
.pricingarea__content__wraper .pricingarea__list ul li i {
  height: 20px;
  width: 20px;
  line-height: 21px;
  text-align: center;
  border-radius: 50px;
  background: var(--secondaryColor);
  color: var(--whiteColor);
  margin-right: 15px;
  font-size: 12px;
  display: inline-block;
}
.pricingarea__content__wraper .pricingarea__list ul li i.close__button {
  background: var(--lightGrey6);
}
.pricingarea__content__wraper .pricingarea__list ul li i.dark__color__2 {
  background: var(--lightGrey6);
}
.pricingarea__content__wraper .pricingarea__plan__type {
  position: relative;
}
.pricingarea__content__wraper .pricingarea__plan__type img {
  position: absolute;
  top: -30px;
  right: 0;
  max-width: 70%;
}
.pricingarea__content__wraper .pricingarea__button {
  margin-bottom: 25px;
}
.pricingarea__content__wraper .pricingarea__button a {
  width: 100%;
}
.pricingarea__content__wraper .pricingarea__button.pricingarea__button__2 a {
  background: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
}
.pricingarea__content__wraper .pricingarea__button.pricingarea__button__2 a:hover {
  background: var(--whiteColor);
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
}
.pricingarea__content__wraper .pricingarea__text p {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

.eventarea__details__small__button {
  margin-bottom: 30px;
}
.eventarea__details__small__button a {
  height: 25px;
  width: 98px;
  background: var(--indigo);
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: var(--whiteColor);
  display: inline-block;
  text-align: center;
  border-radius: var(--borderRadius);
}

.event__details__heading h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
}
@media (max-width: 767px) {
  .event__details__heading h3 {
    font-size: 30px;
    line-height: 44px;
  }
}

.eventarea__details__list ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.eventarea__details__list ul li {
  margin-right: 76px;
}
@media (max-width: 767px) {
  .eventarea__details__list ul li {
    margin-right: 26px;
  }
}
.eventarea__details__list ul li .event__details__small__img {
  display: flex;
  align-items: center;
}

.event__details__text span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--blackColor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .event__details__text span {
    font-size: 11px;
  }
}
.event__details__text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .event__details__text p {
    font-size: 12px;
  }
}

.event__details__inner {
  margin-top: 35px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .event__details__inner {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .event__details__inner {
    margin-bottom: 30px;
  }
}
.event__details__inner .event__details__img__2 {
  margin-bottom: 35px;
}
.event__details__inner .event__details__img__2 img {
  width: 100%;
}
.event__details__inner .event__details__content {
  margin-bottom: 20px;
}
.event__details__inner .event__details__content h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
}
.event__details__inner .event__details__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: var(--darkdeep4);
}

.event__details__list__2 h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
}
.event__details__list__2 ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.event__details__list__2 ul li i {
  color: var(--primaryColor);
  margin-right: 3px;
}
.event__details__list__2 ul li p {
  margin: 0;
}

.event__sidebar__wraper {
  background: var(--whiteColor);
  box-shadow: 0px 5px 20px rgba(94, 122, 193, 0.14);
  border-radius: 5px;
  padding: 33px 25px;
  margin-bottom: 30px;
}
.event__sidebar__wraper .event__price__wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.event__sidebar__wraper .event__price__wraper .event__price {
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  color: var(--primaryColor);
}
.event__sidebar__wraper .event__price__wraper .event__price del {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--lightGrey4);
}
.event__sidebar__wraper .event__price__wraper .event__Price__button a {
  width: 81px;
  height: 27px;
  display: inline-block;
  background: var(--whitegrey1);
  color: var(--secondaryColor2);
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
}

.event__details__list ul li {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--borderColor);
}
.event__details__list ul li .event__details__icon {
  margin-right: 10px;
}

.event__details__date p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.event__details__date p span {
  color: var(--blackColor);
  margin-right: 7px;
}

.event__details__button a {
  padding: 10px 56px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .event__details__button a {
    padding: 10px 48px;
    font-size: 14px;
  }
}

.event__details__sidebar__heading h6 {
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  color: var(--blackColor);
}

.event__details__img__2 {
  margin-top: 25px;
  margin-bottom: 30px;
}

.event__thomas {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  color: var(--contentColor);
}

.event__details__share {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.event__details__share span {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-right: 7px;
  color: var(--contentColor);
}
.event__details__share .event__details__share__list ul li {
  margin-right: 11px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .event__details__share .event__details__share__list ul li {
    margin-right: 9px;
  }
}
.event__details__share .event__details__share__list ul li a {
  width: 40px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  background: var(--whitegrey2);
  border-radius: 3px;
  font-weight: 400;
  font-size: 16px;
  color: var(--skycolor);
}
.event__details__share .event__details__share__list ul li a:hover {
  color: var(--whiteColor);
  background: var(--primaryColor);
}
.event__details__share .event__details__share__list ul li a.sky__color {
  background-color: var(--whitegrey2);
  color: var(--skycolor);
}
.event__details__share .event__details__share__list ul li a.sky__color:hover {
  color: var(--whiteColor);
  background: var(--primaryColor);
}
.event__details__share .event__details__share__list ul li a.deep__red {
  background-color: var(--whitegrey1);
  color: var(--deepred);
}
.event__details__share .event__details__share__list ul li a.deep__red:hover {
  color: var(--whiteColor);
  background: var(--primaryColor);
}

/*------------------------------
Register Area
-------------------------------*/
.registerarea {
  background-image: url(../img/register/register__bg.png);
  position: relative;
  margin-bottom: 150px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .registerarea {
    padding-bottom: 80px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .registerarea {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 0;
  }
}
.registerarea::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  right: auto;
  background: rgba(38, 0, 148, 0.93);
  top: 0;
  bottom: 0;
}

.registerarea__section__title {
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
}
.registerarea__section__title .section__title__heading h2 {
  color: var(--whiteColor);
  z-index: 1;
}
.registerarea__section__title .section__title__heading h2 small {
  color: var(--yellow1);
  font-size: 42px;
}

.registerarea__wraper {
  max-width: 85%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .registerarea__wraper {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .registerarea__wraper {
    max-width: 100%;
  }
}

.registerarea__content {
  z-index: 1;
  position: relative;
}
.registerarea__content.course__details__video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.registerarea__video {
  display: flex;
  align-items: center;
}
.registerarea__video a {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: var(--secondaryColor);
  color: var(--whiteColor);
  border-radius: 100%;
  text-align: center;
  margin-right: 20px;
  display: inline-block;
  position: relative;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .registerarea__video a {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .registerarea__video a {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .registerarea__video a {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
.registerarea__video a img {
  width: auto;
}
.registerarea__video a::before {
  content: "";
  border: 2px solid var(--secondaryColor);
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  -webkit-animation: zoomBig 3.25s linear infinite;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.registerarea__video a::after {
  content: "";
  border: 2px solid var(--secondaryColor);
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  -webkit-animation: zoomBig 3.25s linear infinite;
  animation: zoomBig 3.25s linear infinite;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.registerarea__video p {
  font-weight: 600;
  color: var(--whiteColor);
  font-size: 22px;
  line-height: 34px;
  margin-bottom: 0;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .registerarea__video p {
    font-size: 18px;
    line-height: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .registerarea__video p {
    font-size: 18px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .registerarea__video p {
    font-size: 15px;
    line-height: 23px;
  }
}

.registerarea__form {
  position: relative;
  z-index: 1;
  padding: 40px 35px 35px 35px;
  background: var(--lightGrey10);
  box-shadow: 0px 30px 40px 0px rgba(1, 11, 60, 0.06);
  border-radius: var(--borderRadius);
  margin-bottom: -150px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .registerarea__form {
    padding: 30px 25px 25px 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .registerarea__form {
    margin-bottom: 0;
    margin-top: 60px;
  }
}
@media (max-width: 767px) {
  .registerarea__form {
    margin-bottom: 0;
    margin-top: 60px;
    padding: 30px 25px 25px 25px;
  }
}
.registerarea__form .registerarea__form__heading h4 {
  font-family: var(--bodyFont);
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}
.registerarea__form form .register__input {
  height: 50px;
  width: 100%;
  border: none;
  background: var(--lightGrey8);
  padding: 10px 15px;
  font-size: 16px;
  line-height: 54px;
  margin-bottom: 25px;
}

.input_form_error{
  margin-top: -20px;
  color: red;
}
.registerarea__form form .register__input.textarea {
  height: 155px;
  width: 100%;
}

.registerarea__img .register__1 {
  position: absolute;
  top: 8%;
  left: 8%;
  animation: 3s linear 0s infinite alternate none running dot-anim-2;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .registerarea__img .register__1 {
    left: 0%;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .registerarea__img .register__1 {
    top: 0%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .registerarea__img .register__1 {
    top: 0%;
    left: 0;
  }
}
@media (max-width: 767px) {
  .registerarea__img .register__1 {
    top: 0%;
    left: 0;
  }
}
.registerarea__img .register__2 {
  position: absolute;
  top: 50%;
  left: 11%;
  animation: cir-anim 6s linear 0s infinite alternate;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .registerarea__img .register__2 {
    left: 4%;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .registerarea__img .register__2 {
    left: 54%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .registerarea__img .register__2 {
    left: 71%;
    top: 24%;
  }
}
@media (max-width: 767px) {
  .registerarea__img .register__2 {
    left: 84%;
    top: 24%;
  }
}
.registerarea__img .register__3 {
  position: absolute;
  top: 60%;
  left: 3%;
  animation: 3s linear 0s infinite alternate none running dot-anim;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .registerarea__img .register__3 {
    left: 0%;
    top: 73%;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .registerarea__img .register__3 {
    top: 74%;
    left: 12%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .registerarea__img .register__3 {
    top: 3%;
    left: 83%;
  }
}
@media (max-width: 767px) {
  .registerarea__img .register__3 {
    top: 3%;
    left: 63%;
  }
}

@keyframes zoomBig {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: 0.5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
@keyframes zoomBig {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
    border-width: 3px;
  }
  40% {
    opacity: 0.5;
    border-width: 2px;
  }
  65% {
    border-width: 1px;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    border-width: 1px;
  }
}
/*------------------------------
Testimonial Area
-------------------------------*/
.testmonialarea {
  background: var(--lightGrey7);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .testmonialarea {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .testmonialarea {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .testmonialarea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.single__testimonial__wraper {
  padding: 40px 50px 50px 40px;
  box-shadow: 0px 30px 40px 0px rgba(1, 11, 60, 0.06);
  border-radius: var(--borderRadius);
  background: var(--whiteColor);
}
@media (max-width: 767px) {
  .single__testimonial__wraper {
    padding: 20px 10px 20px 10px;
  }
}
.single__testimonial__wraper .single__testimonial__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__testimonial__wraper .single__testimonial__inner {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .single__testimonial__wraper .single__testimonial__inner {
    margin-bottom: 10px;
  }
}
.single__testimonial__wraper .single__testimonial__inner .testimonial__img {
  display: flex;
  align-items: center;
}
.single__testimonial__wraper .single__testimonial__inner .testimonial__img .testimonial__name {
  margin-left: 22px;
}
.single__testimonial__wraper .single__testimonial__inner .testimonial__img .testimonial__name h6 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .single__testimonial__wraper .single__testimonial__inner .testimonial__img .testimonial__name h6 {
    font-size: 18px;
  }
}
.single__testimonial__wraper .single__testimonial__inner .testimonial__img .testimonial__name span {
  font-size: 14px;
  color: var(--lightGrey9);
}
.single__testimonial__wraper .single__testimonial__inner .testimonial__icon i {
  color: var(--yellow);
  font-size: 18px;
}
@media (max-width: 767px) {
  .single__testimonial__wraper .single__testimonial__inner .testimonial__icon i {
    font-size: 12px;
  }
}
.single__testimonial__wraper .single__testimonial__inner .testimonial__icon i.dark__color {
  color: var(--lightGrey6);
}
.single__testimonial__wraper .testimonial__content p {
  line-height: 32px;
  margin-bottom: 0;
}

.default__arrow .slick-arrow {
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: -50px;
  width: auto;
  z-index: 9;
  transform: translateY(-50%);
  cursor: pointer;
  transition: var(--transition);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .default__arrow .slick-arrow {
    left: -34px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .default__arrow .slick-arrow {
    left: -43px;
  }
}
@media (max-width: 767px) {
  .default__arrow .slick-arrow {
    left: -15px;
  }
}
.default__arrow .slick-arrow.next_class {
  left: auto;
  right: -50px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .default__arrow .slick-arrow.next_class {
    right: -34px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .default__arrow .slick-arrow.next_class {
    right: -43px;
  }
}
@media (max-width: 767px) {
  .default__arrow .slick-arrow.next_class {
    right: -15px;
  }
}
.default__arrow .slick-arrow:hover {
  color: var(--secondaryColor);
}

.aboutarea__3 {
  background: var(--lightGrey10);
  position: relative;
}
.aboutarea__3 .aboutarea__img__3 .aboutarea__3__img__1 {
  position: absolute;
  top: 110px;
  left: 216px;
  animation: 3s linear 0s infinite alternate none running dot-anim-2;
}
.aboutarea__3 .aboutarea__img__3 .aboutarea__3__img__2 {
  position: absolute;
  top: 360px;
  left: 162px;
  animation: cir-anim 6s linear 0s infinite alternate;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__3 .aboutarea__img__3 .aboutarea__3__img__2 {
    left: 318px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__3 .aboutarea__img__3 .aboutarea__3__img__2 {
    left: 262px;
  }
}
.aboutarea__3 .aboutarea__img__3 .aboutarea__3__img__3 {
  position: absolute;
  top: 430px;
  left: 156px;
  animation: 3s linear 0s infinite alternate none running dot-anim;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__3 .aboutarea__img__3 .aboutarea__3__img__3 {
    top: 433px;
    left: 196px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__3 .aboutarea__img__3 .aboutarea__3__img__3 {
    top: 630px;
    left: 476px;
  }
}

.aboutarea__3__section__title {
  margin-bottom: 20px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__content__wraper__3 {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .aboutarea__content__wraper__3 {
    margin-top: 50px;
  }
}

.aboutarea__3__button {
  margin-top: 45px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__3__button {
    margin-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__3__button {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .aboutarea__3__button {
    margin-top: 20px;
  }
}
.aboutarea__3__button a {
  background: var(--secondaryColor);
  color: var(--whiteColor);
  border: 1px solid var(--secondaryColor);
}
.aboutarea__3__button a:hover {
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  background: var(--whiteColor);
}

.aboutarea__para__3 {
  background: var(--whiteColor);
  padding: 50px 25px;
  position: relative;
}
.aboutarea__para__3::after {
  position: absolute;
  left: 27px;
  bottom: -14px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 15px solid var(--whiteColor);
  content: "";
}
.aboutarea__para__3 p {
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__para__3 p {
    font-size: 14px;
  }
}
.aboutarea__para__3 .aboutarea__icon__3 i {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  color: var(--whiteColor);
  background: var(--primaryColor);
  display: inline-block;
  position: absolute;
  top: -30px;
  left: -30px;
  font-size: 30px;
}
@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .aboutarea__para__3 .aboutarea__icon__3 i {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 20px;
    top: 0;
    left: 0;
  }
}

.aboutarea__img__3 {
  margin-top: 22px;
  display: flex;
  align-items: center;
}
.aboutarea__img__3 .aboutarea__img__name {
  margin-left: 80px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__img__3 .aboutarea__img__name {
    margin-left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .aboutarea__img__3 .aboutarea__img__name {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .aboutarea__img__3 .aboutarea__img__name {
    margin-left: 20px;
  }
}
.aboutarea__img__3 .aboutarea__img__name h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 5px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__img__3 .aboutarea__img__name h6 {
    font-size: 18px;
    line-height: 23px;
  }
}
.aboutarea__img__3 .aboutarea__img__name p {
  line-height: 17px;
  color: var(--lightGrey9);
  margin-bottom: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .aboutarea__img__3 .aboutarea__img__name p {
    font-size: 15px;
  }
}

.custom__review__grid {
  padding-right: 30px;
  padding-left: 30px;
}

@media (min-width: 992px) and (max-width: 1365px) {
  .testimonial__area__2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .testimonial__area__2 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .testimonial__area__2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.testimonial__area__2 .row {
  margin-right: -30px;
  margin-left: -30px;
}

.testimonialarea__3 {
  background: var(--lightGrey10);
  padding-top: 145px;
  padding-bottom: 154px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .testimonialarea__3 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .testimonialarea__3 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .testimonialarea__3 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.testimonialarea__paragraph__3 {
  position: relative;
}
.testimonialarea__paragraph__3 p {
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: var(--lightGrey);
}
@media (max-width: 767px) {
  .testimonialarea__paragraph__3 p {
    font-size: 16px;
    line-height: 33px;
  }
}
.testimonialarea__paragraph__3 .testimonial__quote i {
  font-size: 20px;
  color: var(--primaryColor);
}
.testimonialarea__paragraph__3 .testimonial__quote i.quote__left {
  padding-right: 5px;
}
.testimonialarea__paragraph__3 .testimonial__quote i.quote__right {
  padding-left: 5px;
}

.testimonial__group__img img {
  width: 100%;
}

.testimonialarea__person__3 {
  display: flex;
  align-items: center;
  margin-top: 45px;
}
@media (max-width: 767px) {
  .testimonialarea__person__3 {
    margin-top: 15px;
  }
}
.testimonialarea__person__3 .testimonial__img__3 {
  margin-right: 18px;
}
.testimonialarea__person__3 .testimonial__name h6 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}
.testimonialarea__person__3 .testimonial__name span {
  color: var(--primaryColor);
  font-weight: 500;
}

.testimonial__group__img {
  padding-left: 70px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .testimonial__group__img {
    padding-left: 0px;
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .testimonial__group__img {
    padding-left: 0px;
    margin-top: 70px;
  }
}

.testimonial__slider__active__3 .slick-arrow {
  height: 60px;
  width: 60px;
  background: var(--whiteColor);
  line-height: 68px;
  display: inline-block;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: var(--transition);
  z-index: 99;
  cursor: pointer;
}
.testimonial__slider__active__3 .slick-arrow:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.testimonial__slider__active__3 .slick-arrow.prev_class {
  right: 61px;
}
.testimonial__slider__active__3 .slick-arrow i {
  font-size: 30px;
}
@media (max-width: 767px) {
  .testimonial__slider__active__3 .slick-arrow {
    bottom: -50px;
  }
}

/*------------------------------
Counter Area
-------------------------------*/
@media (min-width: 992px) and (max-width: 1365px) {
  .counterarea {
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .counterarea {
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .counterarea {
    padding-bottom: 50px;
  }
}

.counterarea__text__wraper {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) and (max-width: 991px) {
  .counterarea__text__wraper {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .counterarea__text__wraper {
    margin-bottom: 30px;
  }
}
.counterarea__text__wraper .counter__img {
  margin-right: 16px;
}
.counterarea__text__wraper .counter__content__wraper .counter__number {
  font-weight: 700;
  font-size: 34px;
  line-height: 1.1;
  font-family: "Hind";
  color: var(--blackColor);
}
.counterarea__text__wraper .counter__content__wraper p {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: var(--blackColor);
  margin: 0;
}

/*------------------------------
Team Area
-------------------------------*/
@media (min-width: 992px) and (max-width: 1365px) {
  .teamarea {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .teamarea {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .teamarea {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .single__team {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .single__team {
    margin-bottom: 30px;
  }
}
.single__team .teamarea__img {
  position: relative;
  margin-bottom: 20px;
}
.single__team .teamarea__img img {
  width: 100%;
}
.single__team .teamarea__img:hover .teamarea__icon {
  right: 20px;
  opacity: 1;
  visibility: visible;
}
.single__team .teamarea__img .teamarea__icon {
  position: absolute;
  top: 20px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}
.single__team .teamarea__img .teamarea__icon ul li {
  display: block;
}
.single__team .teamarea__img .teamarea__icon ul li i {
  height: 41px;
  width: 41px;
  line-height: 41px;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: var(--darkdeep4);
  display: inline-block;
  margin-bottom: 10px;
  background: var(--whiteColor);
  box-shadow: 0px 10px 15px rgba(221, 221, 221, 0.15);
  border-radius: 5px;
  transition: var(--transition);
}
.single__team .teamarea__img .teamarea__icon ul li i:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.single__team .teamarea__content p {
  margin: 0;
}
.single__team .teamarea__content h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: var(--blackColor);
}

.teamarea__margin {
  margin-bottom: 20px;
}

/*------------------------------
Instructor Area
-------------------------------*/
@media (min-width: 992px) and (max-width: 1365px) {
  .expart__teacher {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .expart__teacher {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .expart__teacher {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.single__expart__teacher {
  text-align: center;
  margin: 30px 0;
}
.single__expart__teacher .teacher__img {
  margin-bottom: 30px;
  position: relative;
}
.single__expart__teacher .teacher__img svg {
  position: absolute;
  left: 0;
  top: -16px;
  right: 0;
  width: 100%;
  opacity: 0;
  transition: var(--transition);
}
.single__expart__teacher:hover .teacher__img svg {
  animation: cir-anim 8s linear 0s infinite alternate;
  opacity: 1;
}
.single__expart__teacher .teacher__name {
  margin-bottom: 10px;
}
.single__expart__teacher .teacher__name h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 45px;
  margin: 0;
}
.single__expart__teacher .teacher__name span {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--primaryColor);
}
.single__expart__teacher .teacher__icon ul li {
  margin-right: 5px;
}
.single__expart__teacher .teacher__icon ul li a {
  height: 34px;
  width: 34px;
  line-height: 34px;
  display: inline-block;
  background: rgba(95, 45, 237, 0.04);
  border-radius: 100%;
  color: var(--primaryColor);
}
.single__expart__teacher .teacher__icon ul li a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}

@media (min-width: 768px) and (max-width: 991px) {
  .instructor__sidebar {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .instructor__sidebar {
    margin-bottom: 30px;
  }
}

.instructor__sidebar__img {
  position: relative;
}
.instructor__sidebar__img img {
  width: 100%;
}
.instructor__sidebar__img .instructor__sidebar__small__img {
  position: absolute;
  top: 0;
  left: -21px;
  z-index: -1;
  animation: dot-anim-2 3s linear 0s infinite alternate;
}

.instructor__list > ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .instructor__list > ul {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .instructor__list > ul li {
    margin-bottom: 10px;
  }
}
.instructor__list > ul li .instructor__heading h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  margin: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .instructor__list > ul li .instructor__heading h3 {
    font-size: 30px;
    line-height: 44px;
  }
}
@media (max-width: 767px) {
  .instructor__list > ul li .instructor__heading h3 {
    font-size: 25px;
    line-height: 34px;
  }
}
.instructor__list > ul li .instructor__heading p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .instructor__list > ul li .instructor__heading p {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .instructor__list > ul li .instructor__heading p {
    font-size: 14px;
  }
}
.instructor__list > ul li .instructor__review span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--blackColor);
}
.instructor__list > ul li .instructor__review .instructor__star i {
  color: var(--yellow);
}
.instructor__list > ul li .instructor__follow span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--blackColor);
}
.instructor__list > ul li .instructor__follow .instructor__icon li {
  margin: 0 5px;
}
.instructor__list > ul li .instructor__follow .instructor__icon li:first-child {
  margin-left: 0;
}
.instructor__list > ul li .instructor__button a {
  padding: 8px 40px;
}

.online__course__wrap {
  margin-top: 50px;
}

.instructor__content__wraper {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid var(--borderColor);
}
.instructor__content__wraper h6 {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
}
.instructor__content__wraper p {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
}

.instructor__heading__2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.instructor__heading__2 h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2px;
  margin-bottom: 0;
}

/*------------------------------
Blog
-------------------------------*/
.single__blog__wraper {
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
}

.blogarea__content__wraper {
  box-shadow: 0px 3px 40px rgba(14, 37, 49, 0.078);
  border-radius: var(--borderRadius);
  margin-bottom: 30px;
}
.blogarea__content__wraper:hover .blogarea__img img {
  transform: scale(1.05);
}
.blogarea__content__wraper .blogarea__img {
  position: relative;
  overflow: hidden;
}
.blogarea__content__wraper .blogarea__img img {
  width: 100%;
}
.blogarea__content__wraper .blogarea__img .blogarea__date {
  position: absolute;
  top: 20px;
  left: 20px;
  background: var(--primaryColor);
  display: inline-block;
  text-align: center;
  font-weight: 600;
  line-height: 36px;
  color: var(--whiteColor);
  border-radius: var(--borderRadius);
  font-family: var(--contentFont);
  font-size: 30px;
  padding: 8px 24px;
}
@media (max-width: 767px) {
  .blogarea__content__wraper .blogarea__img .blogarea__date {
    line-height: 20px;
    font-size: 16px;
    padding: 5px 15px;
  }
}
.blogarea__content__wraper .blogarea__img .blogarea__date.small__date {
  line-height: 30px;
  font-size: 24px;
  padding: 7px 22px;
}
@media (max-width: 767px) {
  .blogarea__content__wraper .blogarea__img .blogarea__date.small__date {
    line-height: 20px;
    font-size: 16px;
    padding: 5px 15px;
  }
}
.blogarea__content__wraper .blogarea__img .blogarea__date span {
  display: block;
}
.blogarea__content__wraper .blogarea__text__wraper {
  padding: 40px 35px 35px 35px;
}
@media (max-width: 767px) {
  .blogarea__content__wraper .blogarea__text__wraper {
    padding: 20px;
  }
}
.blogarea__content__wraper .blogarea__text__wraper.blogarea__text__wraper__2 {
  padding: 25px 20px;
}
.blogarea__content__wraper .blogarea__text__wraper.blogarea__text__wraper__2 h3 {
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .blogarea__content__wraper .blogarea__text__wraper.blogarea__text__wraper__2 h3 {
    font-size: 24px;
    line-height: 30px;
  }
}
.blogarea__content__wraper .blogarea__text__wraper h3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 53px;
}
@media (max-width: 767px) {
  .blogarea__content__wraper .blogarea__text__wraper h3 {
    font-size: 24px;
    line-height: 30px;
  }
}
.blogarea__content__wraper .blogarea__text__wraper h3 a {
  color: var(--blackColor);
}
.blogarea__content__wraper .blogarea__text__wraper h3 a:hover {
  color: var(--primaryColor);
}
.blogarea__content__wraper .blogarea__text__wraper .blogarea__para p {
  line-height: 29px;
}
.blogarea__content__wraper .blogarea__text__wraper .blogarea__icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__person {
  display: flex;
  align-items: center;
}
.blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__person .blogarea__img {
  width: 44px;
  height: 44px;
  margin-right: 12px;
}
.blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__person .blogarea__img img {
  width: 100%;
}
.blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__person .blogarea__name {
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__person .blogarea__name {
    font-size: 13px;
    line-height: 21px;
  }
}
.blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__person .blogarea__name span {
  color: var(--darkdeep5);
}
@media (max-width: 767px) {
  .blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__list ul li {
    margin-bottom: 3px;
  }
}
.blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__list ul li a {
  height: 41px;
  width: 41px;
  line-height: 41px;
  border: 1px solid var(--borderColor);
  border-radius: var(--transition);
  display: inline-block;
  text-align: center;
  font-size: 15px;
  color: var(--darkdeep4);
  border-radius: var(--borderRadius);
}
@media (max-width: 767px) {
  .blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__list ul li a {
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 12px;
  }
}
.blogarea__content__wraper .blogarea__text__wraper .blogarea__icon .blogarea__list ul li a:hover {
  color: var(--primaryColor);
}

.single__blog__img {
  position: relative;
  padding: 10px;
}
.single__blog__img img {
  width: 100%;
}

.single__blog__button {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.single__blog__button a {
  padding: 10px 48px;
  background: var(--blackColor2);
  border: 1px solid var(--blackColor2);
  border-radius: 114px;
  font-size: 15px;
  line-height: 18px;
}
.single__blog__button a:hover {
  border: 1px solid var(--blackColor2);
  color: var(--blackColor2);
}

.single__blog__content {
  padding: 30px 20px 40px 30px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .single__blog__content {
    padding: 30px 10px 40px 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__blog__content {
    padding: 20px 10px 20px 10px;
  }
}
.single__blog__content p {
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--primaryColor);
}
.single__blog__content h4 a {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--blackColor2);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .single__blog__content h4 a {
    font-size: 20px;
    line-height: 27px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__blog__content h4 a {
    font-size: 20px;
    line-height: 23px;
  }
}
.single__blog__content h4 a:hover {
  color: var(--primaryColor);
}
.single__blog__content .single__blog__bottom__button a {
  font-weight: 500;
  line-height: 19px;
  color: var(--blackColor2);
}
.single__blog__content .single__blog__bottom__button a:hover {
  color: var(--primaryColor);
}

.blogarea__bottom__button {
  text-align: center;
  margin-top: 50px;
}
.blogarea__bottom__button a {
  padding: 15px 47px;
}

.blog__content__wraper__2 {
  margin-bottom: 35px;
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.05);
}
.blog__content__wraper__2 .blogarea__text__wraper__2 {
  padding: 26px 30px 20px 30px;
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__heading__2 h3 {
  margin: 0;
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__heading__2 h3 a {
  font-weight: 700;
  font-size: 34px;
  line-height: 54px;
  color: var(--blackColor2);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__heading__2 h3 a {
    font-size: 28px;
    line-height: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__heading__2 h3 a {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__heading__2 h3 a {
    font-size: 24px;
    line-height: 34px;
  }
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__heading__2 h3 a:hover {
  color: var(--primaryColor);
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__list__2 {
  padding-bottom: 19px;
  border-bottom: 1px solid var(--borderColor2);
  margin-bottom: 14px;
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__list__2 ul li {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-right: 12px;
  position: relative;
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__list__2 ul li:after {
  position: absolute;
  content: "";
  height: 11px;
  width: 1px;
  background: var(--borderColor);
  right: -9px;
  bottom: 3px;
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__paragraph {
  margin-bottom: 17px;
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__button__2 {
  display: flex;
  justify-content: space-between;
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__button__2 a {
  font-weight: 500;
  line-height: 19px;
  color: var(--primaryColor);
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__button__2 a:hover {
  color: var(--secondaryColor);
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__button__2 .blogarea__icon__2 i {
  height: 28px;
  width: 32px;
  background: var(--whitegrey1);
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  line-height: 28px;
  transition: var(--transition);
}
.blog__content__wraper__2 .blogarea__text__wraper__2 .blogarea__button__2 .blogarea__icon__2 i:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}

.blogarae__img__2 {
  position: relative;
}
.blogarae__img__2.course__details__img__2 {
  margin-bottom: 20px;
}
.blogarae__img__2 img {
  width: 100%;
}
.blogarae__img__2 .blogarea__date__2 {
  background: var(--primaryColor);
  position: absolute;
  color: var(--whiteColor);
  padding: 11px 18px;
  top: 24px;
  right: 22px;
  border-radius: var(--borderRadius);
  text-align: center;
}
.blogarae__img__2 .blogarea__date__2 span {
  display: block;
  font-family: "Hind";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
}
.blogarae__img__2 .blog__video__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blogarae__img__2 .blog__video__button i {
  height: 78px;
  width: 78px;
  line-height: 78px;
  text-align: center;
  border-radius: 100%;
  background: var(--whiteColor);
  display: inline-block;
  color: var(--primaryColor);
  transition: var(--transition);
}
.blogarae__img__2 .blog__video__button i:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}

.main__pagination__wrapper {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 30px;
}
.main__pagination__wrapper ul li {
  margin-right: 10px;
}
.main__pagination__wrapper ul li a {
  height: 50px;
  width: 50px;
  background: var(--whitegrey1);
  color: var(--blackColor2);
  display: inline-block;
  line-height: 50px;
  text-align: center;
}
@media (max-width: 767px) {
  .main__pagination__wrapper ul li a {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
.main__pagination__wrapper ul li a.active {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.main__pagination__wrapper ul li a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.main__pagination__wrapper ul li a.disable {
  cursor: not-allowed;
}

.blogsidebar__content__wraper__2 {
  padding: 30px 35px 30px 30px;
  border: 1px solid var(--borderColor2);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);
  margin-bottom: 30px;
}
.blogsidebar__content__wraper__2.tab__instructor {
  border: none;
  box-shadow: 5px 5px 20px 5px rgba(0, 0, 0, 0.03);
}
.blogsidebar__content__wraper__2.tab__instructor .blogsidebar__content__inner__2 {
  display: flex;
  text-align: left;
}
@media (max-width: 767px) {
  .blogsidebar__content__wraper__2.tab__instructor .blogsidebar__content__inner__2 {
    flex-wrap: wrap;
  }
}
.blogsidebar__content__wraper__2.tab__instructor .blogsidebar__content__inner__2 .blogsidebar__img__2 {
  margin-right: 20px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blogsidebar__content__wraper__2 {
    padding: 20px 20px 20px 20px;
  }
}
@media (max-width: 767px) {
  .blogsidebar__content__wraper__2 {
    padding: 20px 20px 20px 20px;
  }
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 {
  text-align: center;
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blogsidebar__img__2 {
  margin-bottom: 30px;
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blogsidebar__name__2 {
  margin-bottom: 12px;
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blogsidebar__name__2 h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: var(--blackColor2);
  margin: 0;
  margin-bottom: 7px;
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blogsidebar__name__2 h5 a {
  color: var(--blackColor2);
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blogsidebar__name__2 h5 a:hover {
  color: var(--primaryColor);
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blogsidebar__name__2 p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--contentColor2);
  margin: 0;
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blog__sidebar__text__2 p {
  line-height: 26px;
  font-size: 14px;
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blogsidbar__icon__2 ul li {
  margin-right: 6px;
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blogsidbar__icon__2 ul li a {
  width: 35px;
  height: 35px;
  line-height: 32px;
  display: inline-block;
  border: 1px solid var(--borderColor2);
  border-radius: var(--borderRadius);
  transition: var(--transition);
  text-align: center;
}
.blogsidebar__content__wraper__2 .blogsidebar__content__inner__2 .blogsidbar__icon__2 ul li a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
  border: 1px solid var(--primaryColor);
}

.blogsidebar__content__wraper__2 form .blogsudebar__input__area {
  position: relative;
}
.blogsidebar__content__wraper__2 form .blogsudebar__input__area input {
  width: 100%;
  border: none;
  background: var(--lightGrey10);
  padding: 15px 16px;
  font-size: 14px;
  line-height: 26px;
  color: var(--contentColor);
}
.blogsidebar__content__wraper__2 form .blogsudebar__input__area .blogsidebar__input__icon {
  position: absolute;
  position: absolute;
  top: 20%;
  right: 20px;
  border: none;
  background: none;
  color: var(--contentColor);
}
.blogsidebar__content__wraper__2 form .blogsudebar__input__area .blogsidebar__input__icon:hover {
  color: var(--primaryColor);
}

.sidebar__title {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  position: relative;
  padding-left: 8px;
  margin-bottom: 25px;
}
.sidebar__title::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 21px;
  background: var(--primaryColor);
  left: 0;
  bottom: 5px;
}

.blogsidebar__content__wraper__2 .categorie__list li {
  display: block;
  border: 1px solid var(--borderColor2);
  margin-bottom: 16px;
  padding: 8px 16px;
  transition: var(--transition);
}
.blogsidebar__content__wraper__2 .categorie__list li:hover {
  background: var(--primaryColor);
}
.blogsidebar__content__wraper__2 .categorie__list li:hover a {
  color: var(--whiteColor);
}
.blogsidebar__content__wraper__2 .categorie__list li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
}
.blogsidebar__content__wraper__2 .categorie__list li a span {
  float: right;
}
.blogsidebar__content__wraper__2 .recent__list li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.blogsidebar__content__wraper__2 .recent__list li .recent__img {
  position: relative;
  padding-right: 20px;
  width: 40%;
}
.blogsidebar__content__wraper__2 .recent__list li .recent__img img {
  width: 100%;
}
.blogsidebar__content__wraper__2 .recent__list li .recent__img .recent__number {
  position: absolute;
  top: -4px;
  left: 0;
}
.blogsidebar__content__wraper__2 .recent__list li .recent__img .recent__number span {
  height: 24px;
  width: 23px;
  line-height: 24px;
  color: var(--whiteColor);
  background: var(--primaryColor);
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  text-align: center;
}
.blogsidebar__content__wraper__2 .recent__list li .recent__text {
  width: 60%;
}
.blogsidebar__content__wraper__2 .recent__list li .recent__text .recent__date a {
  font-weight: 500;
  line-height: 15px;
  font-size: 14px;
}
.blogsidebar__content__wraper__2 .recent__list li .recent__text h6 a {
  font-weight: 700;
  line-height: 22px;
  color: var(--blackColor2);
}
.blogsidebar__content__wraper__2 .recent__list li .recent__text h6 a:hover {
  color: var(--primaryColor);
}
.blogsidebar__content__wraper__2 .photo__gallery__img {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(3, 1fr);
}
.blogsidebar__content__wraper__2 .photo__gallery__img .single__gallery__img {
  position: relative;
}
.blogsidebar__content__wraper__2 .photo__gallery__img .single__gallery__img::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  transition: var(--transition);
}
.blogsidebar__content__wraper__2 .photo__gallery__img .single__gallery__img:hover::before {
  background: rgba(0, 0, 0, 0.5);
}
.blogsidebar__content__wraper__2 .photo__gallery__img .single__gallery__img:hover .gallery__icon a {
  opacity: 1;
}
.blogsidebar__content__wraper__2 .photo__gallery__img .single__gallery__img img {
  width: 100%;
}
.blogsidebar__content__wraper__2 .photo__gallery__img .single__gallery__img .gallery__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blogsidebar__content__wraper__2 .photo__gallery__img .single__gallery__img .gallery__icon a {
  color: var(--whiteColor);
  opacity: 0;
}
.blogsidebar__content__wraper__2 .get__touch__input input {
  border: none;
  border-bottom: 1px solid var(--borderColor2);
  width: 100%;
  background: none;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: var(--contentColor);
}
.blogsidebar__content__wraper__2 .get__touch__input a {
  padding: 13px 55px;
}
.blogsidebar__content__wraper__2 .populer__tag__list ul li {
  margin: 5px 5px;
}
.blogsidebar__content__wraper__2 .populer__tag__list ul li a {
  padding: 3px 19px;
  border: 1px solid var(--borderColor2);
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
}
.blogsidebar__content__wraper__2 .populer__tag__list ul li a:hover {
  color: var(--whiteColor);
  background: var(--primaryColor);
}
.blogsidebar__content__wraper__2 .follow__icon ul li {
  margin-right: 14px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blogsidebar__content__wraper__2 .follow__icon ul li {
    margin-right: 6px;
  }
}
@media (max-width: 767px) {
  .blogsidebar__content__wraper__2 .follow__icon ul li {
    margin-right: 6px;
  }
}
.blogsidebar__content__wraper__2 .follow__icon ul li a {
  width: 39px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  background: var(--whitegrey2);
  color: var(--blackColor2);
  display: inline-block;
  border-radius: var(--borderRadius);
}
.blogsidebar__content__wraper__2 .follow__icon ul li a:hover {
  color: var(--whiteColor);
  background: var(--primaryColor);
}

@media (min-width: 768px) and (max-width: 991px) {
  .blog__details__content__wraper {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .blog__details__content__wraper {
    margin-bottom: 30px;
  }
}
.blog__details__content__wraper .blog__details__img {
  margin-bottom: 30px;
}
.blog__details__content__wraper .blog__details__img img {
  width: 100%;
}
.blog__details__content__wraper .blog__details__content p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: var(--darkdeep4);
}
.blog__details__content__wraper .blog__details__content p.content__1 {
  margin-bottom: 25px;
}
.blog__details__content__wraper .blog__details__content p.content__2 {
  margin-bottom: 20px;
}
.blog__details__content__wraper .blog__details__content p.content__3 {
  margin-bottom: 20px;
}
.blog__details__content__wraper .blog__details__content p.content__4 {
  margin-bottom: 30px;
}
.blog__details__content__wraper .blog__details__content p.content__5 {
  margin-bottom: 35px;
}
.blog__details__content__wraper .blog__details__content p.content__6 {
  margin-bottom: 30px;
}
.blog__details__content__wraper .blog__details__content p.content__7 {
  margin-bottom: 30px;
}
.blog__details__content__wraper .blog__details__content p.content__9 {
  margin-bottom: 30px;
}
.blog__details__content__wraper .blog__details__content .blog__details__heading h5 {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
}
.blog__details__content__wraper .blog__details__content .blockquate__wraper {
  background: var(--blackColor2);
  padding: 55px 40px 60px 70px;
  position: relative;
  margin-bottom: 43px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .blog__details__content__wraper .blog__details__content .blockquate__wraper {
    padding: 20px;
  }
}
.blog__details__content__wraper .blog__details__content .blockquate__wraper .blockquate__content h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--whiteColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__details__content__wraper .blog__details__content .blockquate__wraper .blockquate__content h3 {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 767px) {
  .blog__details__content__wraper .blog__details__content .blockquate__wraper .blockquate__content h3 {
    font-size: 19px;
    line-height: 36px;
  }
}
.blog__details__content__wraper .blog__details__content .blockquate__wraper .blockquate__content a {
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: var(--whiteColor);
  position: relative;
  padding-left: 60px;
}
.blog__details__content__wraper .blog__details__content .blockquate__wraper .blockquate__content a::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 45px;
  background: var(--whiteColor);
  left: 0;
  top: 40%;
}
.blog__details__content__wraper .blog__details__content .blockquate__wraper .blockquate__img img {
  position: absolute;
  left: 37%;
  top: 14%;
}
@media (max-width: 767px) {
  .blog__details__content__wraper .blog__details__content .blockquate__wraper .blockquate__img img {
    left: 22%;
    top: 22%;
  }
}
.blog__details__content__wraper .blog__details__list__2 ul li {
  display: flex;
  margin-bottom: 0;
}
.blog__details__content__wraper .blog__details__list__2 ul li i {
  width: 31px;
}
.blog__details__content__wraper .blog__details__list__2 ul li p {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  width: 508px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__details__content__wraper .blog__details__list__2 ul li p {
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 7px;
  }
}
.blog__details__content__wraper .blog__details__comment {
  border-top: 1px solid var(--borderColor2);
  padding-top: 50px;
}
.blog__details__content__wraper .blog__details__comment .blog__details__comment__heading h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.blog__details__content__wraper .blog__details__comment .blog__details__comment__inner {
  display: flex;
}
.blog__details__content__wraper .blog__details__comment .blog__details__comment__inner .author__img {
  margin-right: 30px;
}
.blog__details__content__wraper .blog__details__comment .blog__details__comment__inner .author__content .author__name h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin: 0;
}
.blog__details__content__wraper .blog__details__comment .blog__details__comment__inner .author__content .author__name p {
  font-weight: 600;
  font-size: 12px;
  line-height: 29px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 5px;
}
.blog__details__content__wraper .blog__details__comment .blog__details__comment__inner .author__content .author__text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
}

@media (min-width: 992px) and (max-width: 1365px) {
  .blog__details__img__2 {
    margin-bottom: 30px;
  }
}
.blog__details__img__2 img {
  width: 100%;
}

@media (max-width: 767px) {
  .blog__details__heading__2 {
    margin-top: 20px;
  }
}
.blog__details__heading__2 h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
}

.blog__details__margin {
  margin-bottom: 50px;
}

.blog__details__tag {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--borderColor2);
  border-bottom: 1px solid var(--borderColor2);
  padding: 40px 0;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .blog__details__tag {
    flex-wrap: wrap;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  .blog__details__tag ul.share__list {
    margin-top: 10px;
  }
}
.blog__details__tag ul.share__list li a {
  height: 38px;
  width: 41px;
  border-radius: var(--borderRadius);
}
.blog__details__tag ul li {
  margin-right: 5px;
}
.blog__details__tag ul li.heading__tag {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .blog__details__tag ul li.heading__tag {
    font-size: 18px;
    line-height: 28px;
  }
}
.blog__details__tag ul li a {
  font-size: 12px;
  line-height: 30px;
  text-transform: uppercase;
  border: 1px solid var(--borderColor2);
  padding: 9px 12px;
  border-radius: var(--borderRadius);
}
@media (max-width: 767px) {
  .blog__details__tag ul li a {
    font-size: 11px;
    padding: 5px 8px;
  }
}
.blog__details__tag ul li a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
  border-color: var(--primaryColor);
}

.blog__details__comment {
  border-top: 1px solid var(--borderColor2);
  padding-top: 50px;
  border-bottom: 1px solid var(--borderColor2);
  padding-bottom: 15px;
}
.blog__details__comment .blog__details__comment__heading {
  margin-bottom: 30px;
}
.blog__details__comment .blog__details__comment__heading h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.blog__details__comment .blog__details__comment__inner {
  display: flex;
  position: relative;
  margin-bottom: 40px;
}
.blog__details__comment .blog__details__comment__inner.author__padding__left {
  padding-left: 100px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__details__comment .blog__details__comment__inner.author__padding__left {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .blog__details__comment .blog__details__comment__inner.author__padding__left {
    padding-left: 0px;
  }
}
.blog__details__comment .blog__details__comment__inner .author__img {
  margin-right: 30px;
}
@media (max-width: 767px) {
  .blog__details__comment .blog__details__comment__inner .author__img {
    margin-right: 20px;
  }
}
.blog__details__comment .blog__details__comment__inner .author__content .author__name h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin: 0;
}
.blog__details__comment .blog__details__comment__inner .author__content .author__name h6 a {
  color: var(--blackColor);
}
.blog__details__comment .blog__details__comment__inner .author__content .author__name h6 a:hover {
  color: var(--primaryColor);
}
.blog__details__comment .blog__details__comment__inner .author__content .author__name p {
  font-weight: 600;
  font-size: 12px;
  line-height: 29px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 5px;
}
.blog__details__comment .blog__details__comment__inner .author__content .author__text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
}
.blog__details__comment .author__icon {
  position: absolute;
  right: 0;
}
.blog__details__comment .author__icon button {
  border: none;
  background: none;
}
.blog__details__comment .author__icon button:hover path {
  fill: var(--primaryColor);
}

.blog__details__form {
  padding-top: 50px;
}
.blog__details__form .blog__details__input__heading {
  padding-bottom: 30px;
}
.blog__details__form .blog__details__input__heading h5 {
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
}
.blog__details__form .blog__details__input {
  margin-bottom: 10px;
}
.blog__details__form .blog__details__input input {
  border: 1px solid var(--borderColor2);
  width: 100%;
  margin-bottom: 30px;
  padding-left: 20px;
  height: 60px;
  background: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  border-radius: var(--borderRadius);
  color: var(--contentColor);
}
.blog__details__form .blog__details__input textarea {
  height: 209px;
  padding-left: 20px;
  width: 100%;
  border: 1px solid var(--borderColor2);
  padding-top: 20px;
  background: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  border-radius: var(--borderRadius);
  color: var(--contentColor);
}

.blog__check__box {
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  padding-left: 150px;
  color: var(--contentColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .blog__check__box {
    padding-left: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog__check__box {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .blog__check__box {
    padding-left: 0px;
  }
}

.blog__details__button {
  text-align: center;
  margin-top: 30px;
}
.blog__details__button a {
  padding: 13px 70px;
}

@media (min-width: 992px) and (max-width: 1365px) {
  .blogarea__2 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blogarea__2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .blogarea__2 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.early__programs .single__blog__content h4 a {
  font-size: 34px;
  line-height: 1.5;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .early__programs .single__blog__content h4 a {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .early__programs .single__blog__content h4 a {
    font-size: 24px;
  }
}
@media (max-width: 767px) {
  .early__programs .single__blog__content h4 a {
    font-size: 24px;
  }
}
.early__programs .single__blog__content {
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1365px), (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .early__programs .single__blog__wraper {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .research__programs .single__blog__wraper {
    margin-bottom: 30px;
  }
}

.blogarae__img__2 .registerarea__content .registerarea__video a,
.blog__details__img__2 .registerarea__content .registerarea__video a {
  margin-right: 0;
}

.gallery .blogsidebar__content__wraper__2 .photo__gallery__img {
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 767px) {
  .gallery .blogsidebar__content__wraper__2 .photo__gallery__img {
    grid-template-columns: repeat(2, 1fr);
  }
}

.zoom__meetings__details .course__details__img__2 {
  margin-bottom: 50px;
}

/*------------------------------
Course
-------------------------------*/
.course__sidebar__wraper {
  border: 1px solid var(--borderColor);
  padding: 40px 25px 33px 20px;
  margin-bottom: 10px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .course__sidebar__wraper {
    padding: 30px 15px 23px 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .course__sidebar__wraper {
    padding: 30px 15px 23px 10px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .course__sidebar__wraper {
    padding: 30px 15px 23px 10px;
    margin-bottom: 30px;
  }
}

.course__heading h5 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}

.course__input {
  position: relative;
}
.course__input input {
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  height: 46px;
  padding-left: 13px;
  border: none;
  background: rgba(245, 245, 245, 0.71);
}
.course__input .search__button {
  position: absolute;
  top: 23%;
  right: 12px;
}
.course__input .search__button button {
  background: none;
  border: none;
}
.course__input .search__button button:hover {
  color: var(--secondaryColor);
}

.course__categories__list ul li {
  display: block;
}
.course__categories__list ul li:last-child a {
  margin: 0;
}
.course__categories__list ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  border: 1px solid var(--borderColor);
  width: 100%;
  padding: 8px 13px;
  display: block;
  margin-bottom: 15px;
}
.course__categories__list ul li a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.course__categories__list ul li a span {
  float: right;
}

.course__tag__list ul li {
  display: block;
  margin-bottom: 23px;
}
.course__tag__list ul li:last-child {
  margin-bottom: 0;
}
.course__tag__list ul li a {
  display: flex;
  align-items: center;
}
.course__tag__list ul li a .course__check__box {
  height: 15px;
  width: 14px;
  border: 1px solid var(--darkdeep6);
  margin-right: 13px;
}
.course__tag__list ul li a .course__check__box:hover {
  background: var(--primaryColor);
  border: 1px solid var(--borderColor);
}
.course__tag__list ul li a .course__check__box.active {
  background: var(--primaryColor);
  border: 1px solid var(--borderColor);
}
.course__tag__list ul li a span {
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
}
.course__tag__list ul li a span.active {
  color: var(--primaryColor);
}

.course__skill__list ul li {
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 10px;
  display: block;
}
.course__skill__list ul li:last-child {
  margin: 0;
}

.course__text__wraper {
  border: 1px solid var(--borderColor);
  display: flex;
  justify-content: space-between;
  padding: 10px 13px;
  align-items: center;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .course__text__wraper {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .course__text__wraper .course__text {
    margin-bottom: 20px;
  }
}
.course__text__wraper .course__text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--blackColor);
  margin: 0;
}
.course__text__wraper .course__icon ul li.short__by__new {
  padding-left: 80px;
  padding-right: 10px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .course__text__wraper .course__icon ul li.short__by__new {
    padding-left: 50px;
  }
}
.course__text__wraper .course__icon ul li.short__by__new .form-select {
  border: none;
  background-color: none;
  appearance: down;
}
.course__text__wraper .course__icon ul li a {
  margin-right: 11px;
}
.course__text__wraper .course__icon ul li a.active {
  color: var(--primaryColor);
}

@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .with__sidebar__content .gridarea__wraper.gridarea__course__list {
    padding-right: 15px;
  }
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__img {
  width: 40%;
}
@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__content {
  width: 60%;
  padding-left: 30px;
}
@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__content {
    width: 100%;
    padding-left: 0;
  }
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading h3 {
  font-size: 30px;
  line-height: 33px;
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__list ul li {
  width: 30%;
}

.with__sidebar__content .gridarea__wraper.gridarea__course__list .gridarea__content .gridarea__heading h3 {
  font-size: 26px;
  line-height: 30px;
}

.course__button__wraper {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
}

.course__button a {
  height: 26px;
  width: 114px;
  background: var(--primaryColor);
  color: var(--whiteColor);
  display: inline-block;
  text-align: center;
  line-height: 23px;
  font-weight: 600;
  font-size: 14px;
  margin-right: 20px;
  border-radius: var(--borderRadius);
  border: 1px solid var(--primaryColor);
}
.course__button a:hover {
  background-color: var(--whiteColor);
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}
.course__button a.course__2 {
  background: var(--indigo);
  border: 1px solid var(--indigo);
}
.course__button a.course__2:hover {
  background-color: var(--whiteColor);
  color: var(--indigo);
  border: 1px solid var(--indigo);
}

.course__date p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
}
.course__date p span {
  color: var(--blackColor);
}

.course__details__heading h3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 58px;
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .course__details__heading h3 {
    font-size: 32px;
    line-height: 43px;
  }
}

.course__details__price {
  margin-bottom: 30px;
}
.course__details__price ul li {
  margin-right: 20px;
}
.course__details__price ul li .course__price {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: var(--primaryColor);
}
.course__details__price ul li .course__price del {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--lightGrey4);
}
.course__details__price ul li .course__details__date {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--blackColor);
}
.course__details__price ul li .course__details__date i {
  color: var(--primaryColor);
}
.course__details__price ul li .course__star i {
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: var(--yellow);
}
.course__details__price ul li .course__star span {
  color: var(--blackColor);
}

.course__details__paragraph {
  margin-bottom: 30px;
}
.course__details__paragraph p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
@media (max-width: 767px) {
  .course__details__paragraph p {
    font-size: 14px;
  }
}

.course__details__wraper {
  background: var(--darkdeep3);
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.course__details__wraper ul {
  width: 50%;
  border-right: 2px solid var(--borderColor);
  padding: 55px 70px 55px 50px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .course__details__wraper ul {
    padding: 35px 30px 35px 30px;
  }
}
@media (max-width: 767px) {
  .course__details__wraper ul {
    padding: 10px 10px 10px 10px;
    width: 100%;
  }
}
.course__details__wraper ul:last-child {
  border: none;
}
.course__details__wraper ul li {
  color: var(--contentColor2);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.course__details__wraper ul li:last-child {
  margin-bottom: 0;
}
.course__details__wraper ul li span {
  color: var(--deepgreen);
  font-weight: 500;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .course__details__wraper ul li span {
    font-size: 14px;
  }
}
.course__details__tab__wrapper {
  margin-bottom: 40px;
}

.content__cirriculum__wrap .accordion-button {
  font-size: 20px;
  color: var(--headingColor);
  font-weight: 700;
  box-shadow: none;
}
.content__cirriculum__wrap .accordion-body {
  padding: 10px 30px;
}
@media (max-width: 767px) {
  .content__cirriculum__wrap .accordion-body {
    padding: 10px;
  }
}
.content__cirriculum__wrap .accordion-button:focus {
  box-shadow: none;
}

.scc__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
  padding: 15px 0;
  flex-wrap: wrap;
}

.scc__wrap:last-child {
  border-bottom: none;
}

.scc__info {
  display: flex;
  align-items: center;
}

.scc__info h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 300;
}

.scc__info i {
  margin-right: 10px;
  font-size: 16px;
}

.scc__info h5 span {
  font-weight: 500;
}

.scc__wrap .question {
  display: inline-block;
  height: 24px;
  line-height: 23px;
  color: var(--whiteColor);
  padding: 0 10px;
  border-radius: var(--borderRadius);
  background: var(--primaryColor);
  margin-left: 20px;
}

.scc__meta {
  font-size: 14px;
}

.content__cirriculum__wrap .accordion-item {
  margin-bottom: 25px;
  border: 1px solid var(--borderColor);
}

.course__tap__wrap {
  margin-bottom: 30px;
}
.course__tap__wrap li {
  border-right: 2px solid var(--borderColor);
}
.course__tap__wrap li:last-child {
  border: none;
}
.course__tap__wrap li:hover button {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.course__tap__wrap li button {
  padding: 15px 45px;
  border: none;
  background: var(--whiteColor);
  color: var(--blackColor);
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .course__tap__wrap li button {
    padding: 12px 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .course__tap__wrap li button {
    padding: 15px 25px;
  }
}
@media (max-width: 767px) {
  .course__tap__wrap li button {
    padding: 10px;
  }
}
.course__tap__wrap li button i {
  margin-right: 8px;
}
.course__tap__wrap li button.active {
  background: var(--primaryColor);
  color: var(--whiteColor);
}

.experence__heading h5 {
  font-weight: 700;
  font-size: 26px;
  line-height: 56px;
  color: var(--blackColor);
}

.experence__description {
  margin-bottom: 20px;
}
.experence__description p {
  font-weight: 400;
  line-height: 30px;
  font-size: 18px;
  color: var(--darkdeep4);
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .experence__description p {
    font-size: 14px;
    line-height: 27px;
  }
}
@media (max-width: 767px) {
  .experence__description p {
    font-size: 14px;
    line-height: 27px;
  }
}

.course__list__wraper .blog__details__list__2 {
  margin-bottom: 20px;
}

.course__details__populer__list li {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.course__details__populer__list li .course__details__populer__content {
  margin-left: 20px;
}
.course__details__populer__list li .course__details__populer__content span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--primaryColor);
}
.course__details__populer__list li .course__details__populer__content h6 {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.course__details__populer__list li .course__details__populer__content h6 a {
  color: var(--blackColor);
}
.course__details__populer__list li .course__details__populer__content h6 a:hover {
  color: var(--primaryColor);
}

.review__box {
  background: var(--whiteColor);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  text-align: center;
  min-width: 200px;
  padding: 29px 10px;
}
.review__box .review__number {
  font-weight: 800;
  font-size: 72px;
  line-height: 90px;
  color: var(--blackColor);
}
.review__box .review__icon i {
  color: var(--secondaryColor);
}
.review__box span {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--blackColor);
}

.review__wrapper .single__progress__bar {
  position: relative;
}
.review__wrapper .single__progress__bar .rating__text {
  display: inline-block;
  position: relative;
  top: 19px;
}
.review__wrapper .single__progress__bar .rating__text i {
  color: var(--secondaryColor);
}
.review__wrapper .single__progress__bar .progress {
  max-width: 85%;
  margin-left: 38px;
  height: 10px;
  background: var(--borderColor);
}
@media (max-width: 767px) {
  .review__wrapper .single__progress__bar .progress {
    margin-right: 25px;
  }
}
.review__wrapper .single__progress__bar .progress .progress-bar {
  background-color: var(--secondaryColor);
}
.review__wrapper .single__progress__bar span {
  position: absolute;
  right: 0;
  top: 50%;
}

.property__comment .property__comment__list {
  list-style: none;
  border-top: 1px solid var(--borderColor2);
  padding-top: 35px;
  position: relative;
  margin-top: 35px;
}
.property__comment .property__comment__list:first-child {
  margin-top: 0;
}
.property__comment .property__comment__list .property__comment__img {
  max-width: 100px;
  margin-right: 30px;
  float: left;
}
.property__comment .property__comment__list .property__comment__img img {
  width: 100%;
  border-radius: 100%;
}
.property__comment .property__comment__list .property__comment__comment h6 {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
}
.property__comment .property__comment__list .property__comment__comment h6 a {
  color: var(--headingColor);
}
.property__comment .property__comment__list .property__comment__comment h6 a:hover {
  color: var(--secondaryColor);
}
.property__comment .property__comment__list .property__comment__comment .property__sidebar__icon ul li i {
  color: var(--secondaryColor);
}
.property__comment .property__comment__list .property__comment__comment p {
  font-size: 14px;
  margin: 0;
}
.property__comment .property__comment__list .property__comment__comment span {
  position: absolute;
  padding: 0 25px 0;
  border: 2px solid var(--borderColor2);
  display: inline-block;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: 36px;
  top: 27px;
  right: 0;
  transition: 0.3s;
}
@media (min-width: 320px) and (max-width: 575px) {
  .property__comment .property__comment__list .property__comment__comment span {
    top: 172px;
  }
}
.property__comment .property__comment__list .property__comment__comment span:hover {
  border-color: var(--secondaryColor);
}

.add__a__review__wrapper {
  padding: 50px;
  background-color: var(--lightGrey12);
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .add__a__review__wrapper {
    padding: 20px;
  }
}
.add__a__review__wrapper h4 {
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 15px;
}
.add__a__review__wrapper .add__a__review {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.add__a__review__wrapper .add__a__review h6 {
  margin: 0;
  margin-right: 15px;
}
.add__a__review__wrapper .add__a__review .property__sidebar__icon ul li i {
  color: var(--secondaryColor);
}
.add__a__review__wrapper .add__a__review .property__sidebar__icon ul li i:hover {
  color: var(--primaryColor);
}
.add__a__review__wrapper .add__a__review__input {
  margin-bottom: 30px;
}
.add__a__review__wrapper .add__a__review__input input {
  padding: 15px 20px;
  min-height: 65px;
  width: 100%;
  border: 2px solid var(--whiteColor);
  font-size: 15px;
}
.add__a__review__wrapper .add__a__review__input textarea {
  padding: 15px 20px;
  min-height: 150px;
  width: 100%;
  border: 2px solid var(--whiteColor);
  font-size: 15px;
  position: relative;
}
.add__a__review__wrapper .add__a__review__button {
  margin-top: 30px;
}

.property__facts__feature.property__facts__feature__2 {
  margin-top: 60px;
  margin-bottom: 40px;
}
.property__facts__feature h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
  border-left: 2px solid var(--secondaryColor);
  padding-left: 10px;
}
.property__facts__feature .single__grid__details__icon {
  margin-bottom: 25px;
}
.property__facts__feature .single__grid__details__icon li {
  width: 33.33%;
}
.property__facts__feature p {
  margin: 24px 0;
  font-size: 16px;
}

.event__details__icon i {
  font-size: 22px;
}

.event__details__date a {
  color: var(--contentColor);
  font-weight: 700;
}
.event__details__date a:hover {
  color: var(--secondaryColor);
}

.course__details__top--2 {
  padding-top: 70px;
  position: relative;
  z-index: 9;
}

.course__details__sidebar--2 {
  position: relative;
  top: -340px;
  z-index: 10;
}
@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .course__details__sidebar--2 {
    top: 0;
  }
}
.course__details__sidebar--2 .event__details__date {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.course__details__sidebar--2 .event__details__list ul li {
  padding-bottom: 18px;
  margin-bottom: 18px;
  color: var(--blackColor);
}

.lesson__content__main {
  position: relative;
}

.lesson__content__wrap {
  position: absolute;
  z-index: 999;
  background: var(--primaryColor);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .lesson__content__wrap {
    top: 5px 100px;
  }
}
.lesson__content__wrap h3 {
  color: var(--whiteColor);
  font-size: 22px;
  margin: 0;
}
@media (min-width: 320px) and (max-width: 575px) {
  .lesson__content__wrap h3 {
    font-size: 16px;
  }
}
.lesson__content__wrap a {
  color: var(--whiteColor);
}

.course__details__sidebar .event__sidebar__wraper .event__price__wraper {
  margin-bottom: 20px;
}

.course__summery__lists {
  margin-top: 20px;
}
.course__summery__lists li {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid var(--borderColor);
}
.course__summery__lists li .course__summery__item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  color: var(--contentColor);
}
.course__summery__lists li .course__summery__item .sb_content {
  background: var(--borderColor);
  padding: 6px 10px;
  line-height: 13px;
  font-size: 12px;
  border-radius: 50px;
}
.course__summery__lists li .course__summery__item .sb_label {
  font-weight: 500;
}

.course__summery__button {
  text-align: center;
}
.course__summery__button span {
  font-size: 13px;
  color: var(--contentColor);
}
.course__summery__button .default__button {
  width: 100%;
  margin-bottom: 10px;
}
.course__summery__button p {
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 5px;
}
.course__summery__button .default__button--3 {
  font-size: 20px;
  font-weight: 700;
}

.content__cirriculum__wrap .accordion-button span {
  font-size: 12px;
  background: var(--borderColor);
  padding: 4px 10px;
  margin-left: 10px;
  line-height: 1;
  border-radius: 20px;
  font-weight: 700;
}

/*------------------------------
Contact Area
-------------------------------*/
.single__contact {
  border: 1px solid var(--borderColor);
  padding: 40px 35px 35px 40px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 15px rgba(174, 181, 206, 0.09);
  margin-bottom: 30px;
  transition: var(--transition);
  position: relative;
}
.single__contact:hover {
  box-shadow: 0px 4px 25px rgba(174, 181, 206, 0.3);
  transform: translateY(-5px);
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__contact {
    padding: 15px 35px 25px 20px;
  }
}
@media (max-width: 767px) {
  .single__contact {
    padding: 15px 35px 25px 20px;
  }
}
.single__contact .contact__text {
  margin-left: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__contact .contact__text {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .single__contact .contact__text {
    margin-left: 20px;
  }
}
.single__contact .contact__text h5 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: var(--blackColor);
}
.single__contact .contact__text p {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .single__contact .contact__text p {
    font-size: 13px;
    line-height: 20px;
  }
}
@media (max-width: 767px) {
  .single__contact .contact__text p {
    font-size: 13px;
    line-height: 20px;
  }
}
.single__contact .contact__text span {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: var(--contentColor);
}

.contact__form__inner {
  padding: 90px 70px 70px 70px;
  border: 1px solid var(--borderColor2);
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact__form__inner {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .contact__form__inner {
    padding: 20px;
  }
}
.contact__form__inner .contact__form__heading {
  margin-bottom: 40px;
}
.contact__form__inner .contact__form__heading h3 {
  font-weight: 700;
  font-size: 44px;
  line-height: 70px;
  color: var(--blackColor);
  margin: 0;
}
@media (max-width: 767px) {
  .contact__form__inner .contact__form__heading h3 {
    font-size: 23px;
    line-height: 40px;
  }
}
.contact__form__inner .contact__form__heading p {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
}
@media (max-width: 767px) {
  .contact__form__inner .contact__form__heading p {
    font-size: 13px;
    line-height: 20px;
  }
}

.contact__input__wraper {
  position: relative;
}
.contact__input__wraper input {
  height: 60px;
  width: 100%;
  padding-left: 27px;
  border: 1px solid var(--borderColor2);
  margin-bottom: 30px;
  border-radius: var(--borderRadius);
  background: transparent;
  color: var(--contentColor);
}
.contact__input__wraper .contact__icon {
  position: absolute;
  top: 17px;
  right: 24px;
}
.contact__input__wraper .contact__icon i {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: var(--primaryColor);
}
.contact__input__wraper textarea {
  width: 100%;
  border: 1px solid var(--borderColor2);
  padding-left: 27px;
  height: 285PX;
  padding-top: 30PX;
  border-radius: var(--borderRadius);
  background: transparent;
  color: var(--contentColor);
}

.contact__button {
  margin-top: 40px;
}
.contact__button a {
  padding: 17px 48px;
}

.form-messege {
  padding-top: 10px;
}

/*------------------------------
Error
-------------------------------*/
.errorarea__inner {
  text-align: center;
}
.errorarea__inner .error__img {
  margin-bottom: 60px;
}
.errorarea__inner .error__img img {
  width: 100%;
}
.errorarea__inner .error__text h3 {
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  margin: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .errorarea__inner .error__text h3 {
    font-size: 40px;
    line-height: 58px;
  }
}
@media (max-width: 767px) {
  .errorarea__inner .error__text h3 {
    font-size: 35px;
    line-height: 40px;
  }
}
.errorarea__inner .error__text p {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.errorarea__inner .error__button {
  margin-top: 50px;
}
.errorarea__inner .error__button a {
  padding: 16px 43px;
}

.loginarea .tab__button__wrap li.nav-item {
  width: 50%;
}

.loginarea__wraper {
  padding: 31px 50px 50px;
  border-radius: 5px;
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
}
@media (max-width: 767px) {
  .loginarea__wraper {
    padding: 10px 20px 40px;
  }
}
.loginarea__wraper .login__heading {
  text-align: center;
}
.loginarea__wraper .login__heading .login__title {
  font-size: 32px;
  line-height: 1.5;
  margin: 0 0 8px;
}
.loginarea__wraper .login__heading .login__description a {
  position: relative;
}
.loginarea__wraper .login__heading .login__description a:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  background: var(--primaryColor);
  bottom: 0;
  left: 0;
  transition: var(--transition);
}
.loginarea__wraper .login__heading .login__description a:hover {
  color: var(--primaryColor);
}
.loginarea__wraper .login__heading .login__description a:hover:after {
  width: 100%;
}
.loginarea__wraper form .login__form {
  margin-top: 25px;
}
.loginarea__wraper form .login__form label {
  display: block;
  margin-bottom: 10px;
  color: var(--contentColor);
}
.loginarea__wraper form .login__form .common__login__input {
  background-color: transparent;
  border: 1px solid var(--borderColor);
  font-size: 14px;
  font-weight: 400;
  height: 52px;
  padding: 3px 20px;
  width: 100%;
  border-radius: 5px;
  color: var(--contentColor);
}
.loginarea__wraper form .login__form .form__check {
  display: flex;
}
.loginarea__wraper form .login__form .form__check input {
  width: 18px;
  margin-right: 8px;
}
.loginarea__wraper form .login__form .form__check label {
  margin-bottom: 0;
}
.loginarea__wraper form .login__form .login__form__link a {
  position: relative;
}
.loginarea__wraper form .login__form .login__form__link a:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  background: var(--primaryColor);
  bottom: 0;
  left: 0;
  transition: var(--transition);
}
.loginarea__wraper form .login__form .login__form__link a:hover {
  color: var(--primaryColor);
}
.loginarea__wraper form .login__form .login__form__link a:hover:after {
  width: 100%;
}
.loginarea__wraper form .login__button {
  margin-top: 25px;
}
.loginarea__wraper form .login__button a {
  width: 100%;
}
.loginarea__wraper .login__social__option {
  margin-top: 25px;
  text-align: center;
}
.loginarea__wraper .login__social__option p {
  position: relative;
}
.loginarea__wraper .login__social__option p::before {
  content: "";
  position: absolute;
  height: 1px;
  background: var(--borderColor4);
  width: 40%;
  left: 0;
  top: 16px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .loginarea__wraper .login__social__option p::before {
    width: 30%;
  }
}
@media (max-width: 767px) {
  .loginarea__wraper .login__social__option p::before {
    width: 30%;
  }
}
.loginarea__wraper .login__social__option p::after {
  content: "";
  position: absolute;
  height: 1px;
  background: var(--borderColor4);
  width: 40%;
  right: 0;
  top: 16px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .loginarea__wraper .login__social__option p::after {
    width: 30%;
  }
}
@media (max-width: 767px) {
  .loginarea__wraper .login__social__option p::after {
    width: 30%;
  }
}
.loginarea__wraper .login__social__option .login__social__btn li a {
  padding: 10px 44px;
}
.loginarea__wraper .login__social__option .login__social__btn li .login__button__1 {
  margin-right: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .loginarea__wraper .login__social__option .login__social__btn li .login__button__1 {
    margin-right: 10px;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .loginarea__wraper .login__social__option .login__social__btn li .login__button__1 {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.loginarea {
  position: relative;
}

.login__shape__img .hero__shape {
  position: absolute;
}
.login__shape__img .hero__shape.hero__shape__1 {
  right: 14%;
  top: 30%;
  animation: 3s linear 0s infinite alternate none running dot-anim;
}
.login__shape__img .hero__shape.hero__shape__2 {
  top: 50%;
  left: 5%;
  animation: 3s linear 0s infinite alternate none running dot-anim-2;
}
.login__shape__img .hero__shape.hero__shape__3 {
  left: 50%;
  bottom: 60px;
  animation: cir-anim 6s linear 0s infinite alternate;
}
.login__shape__img .hero__shape.hero__shape__4 {
  left: 50%;
  top: 40px;
  animation: cir-anim 6s linear 0s infinite alternate;
}

.maintaince .row {
  height: 100vh;
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .maintaince h1 {
    font-size: 100px;
  }
}
.maintaince .featurearea__countdown {
  margin: 50px 0 0 0;
}
.maintaince .featurearea__countdown .count {
  margin: 0 20px;
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .maintaince .featurearea__countdown .count {
    height: 120px;
    width: 120px;
  }
}
.maintaince .featurearea__countdown .count p {
  margin-bottom: 10px;
}
@media (min-width: 1500px) and (max-width: 1920px) {
  .maintaince .featurearea__countdown .count p {
    font-size: 40px;
  }
}
.maintaince .featurearea__countdown .count span {
  font-size: 20px;
}

/*------------------------------
Breadcrumb Area
-------------------------------*/
.breadcrumbarea {
  /* padding-top: 162px; */
  /* padding-bottom: 150px; */
  padding-top: 50px;
  padding-bottom: 80px;
  background: var(--lightGrey10);
  position: relative;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .breadcrumbarea {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .breadcrumbarea {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .breadcrumbarea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.breadcrumbarea--2 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.breadcrumbarea--3 {
  padding-bottom: 0;
  padding-top: 50px;
}

.breadcrumb__content__wraper {
  text-align: center;
  position: relative;
  z-index: 9;
}
.breadcrumb__content__wraper .breadcrumb__title h2 {
  font-weight: 700;
  font-size: 55px;
  line-height: 72px;
  color: var(--blackColor2);
  margin: 0;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .breadcrumb__content__wraper .breadcrumb__title h2 {
    font-size: 40px;
    line-height: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .breadcrumb__content__wraper .breadcrumb__title h2 {
    font-size: 40px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .breadcrumb__content__wraper .breadcrumb__title h2 {
    font-size: 30px;
  }
}

.shape__icon__2 .shape__icon__img {
  position: absolute;
}
.shape__icon__2 .shape__icon__img.shape__icon__img__1 {
  bottom: 60px;
  left: 165px;
  animation: 3s linear 0s infinite normal none running move5;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__1 {
    bottom: 21px;
    left: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__1 {
    bottom: 0px;
    left: 14px;
  }
}
@media (max-width: 767px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__1 {
    bottom: 0px;
    left: 0px;
  }
}
.shape__icon__2 .shape__icon__img.shape__icon__img__2 {
  top: 100px;
  left: 50px;
  animation: cir-anim 6s linear 0s infinite alternate;
}
@media (min-width: 768px) and (max-width: 991px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__2 {
    top: 0px;
    left: 0px;
  }
}
@media (max-width: 767px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__2 {
    top: 0px;
    left: 0px;
  }
}
.shape__icon__2 .shape__icon__img.shape__icon__img__3 {
  top: 80px;
  right: 575px;
  animation: 3s linear 0s infinite alternate none running dot-anim;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__3 {
    top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__3 {
    top: 0px;
    right: 40px;
  }
}
@media (max-width: 767px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__3 {
    top: 0px;
    right: 30px;
  }
}
.shape__icon__2 .shape__icon__img.shape__icon__img__4 {
  top: 100px;
  right: 45px;
  animation: 3s linear 0s infinite alternate none running dot-anim-2;
}
@media (min-width: 768px) and (max-width: 991px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__4 {
    top: 157px;
    right: 40px;
  }
}
@media (max-width: 767px) {
  .shape__icon__2 .shape__icon__img.shape__icon__img__4 {
    top: 212px;
    right: 30px;
  }
}

.breadcrumb__inner ul li {
  font-size: 18px;
  line-height: 22px;
  color: var(--blackColor2);
  font-weight: 400;
}
.breadcrumb__inner ul li a {
  position: relative;
  padding-right: 26px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--blackColor2);
}
.breadcrumb__inner ul li a:hover {
  color: var(--primaryColor);
}
.breadcrumb__inner ul li a:after {
  position: absolute;
  content: "\eaa0";
  font-family: "icofont";
  top: 0px;
  right: 3px;
}

.breadcrumbarea--3 .blogarae__img__2.course__details__img__2 {
  margin-bottom: 0;
}

.animate__content {
  background-color: var(--borderColor);
  overflow: hidden;
}
.animate__content__wrap {
  display: flex;
  width: fit-content;
  white-space: nowrap;
  position: relative;
  will-change: transform;
  animation: marquee 50s linear 0s infinite normal;
  animation-play-state: running;
}
.animate__content__wrap:hover {
  animation-play-state: paused;
}
.animate__content__single {
  display: block;
  width: 100%;
  min-width: max-content;
  padding-inline: 16px;
}
.animate__content__single span {
  font-size: 30px;
  line-height: 1;
  font-weight: 500;
  color: var(--darkdeep2);
}
.animate__content__single span a {
  color: var(--darkdeep2);
}
.animate__content__single span a:hover {
  color: var(--primaryColor);
}
.animate__content__single--2 span {
  color: var(--primaryColor);
}
.animate__content__single--2 span a {
  color: var(--primaryColor);
}
.animate__content__single--2 span a:hover {
  color: var(--secondaryColor);
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .featurearea {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .featurearea {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .featurearea {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.featurearea__img img {
  width: 100%;
}
.featurearea__small__title {
  margin-bottom: 15px;
}
.featurearea__small__title span {
  font-size: 18px;
  line-height: 1.2;
  color: var(--blackColor);
}
.featurearea__main__title h3 {
  font-size: 36px;
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__main__title h3 {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  .featurearea__main__title h3 {
    font-size: 21px;
    margin-bottom: 10px;
  }
}
.featurearea__price span {
  font-size: 18px;
  margin-right: 6px;
  color: var(--blackColor);
}
.featurearea__price span.featurearea__price__button {
  padding: 5px 10px;
  background: var(--primaryColor);
  color: var(--whiteColor);
  font-size: 15px;
  border-radius: var(--borderRadius);
}
.featurearea__price span.featurearea__price__button.black__color {
  background: var(--blackColor);
}
.featurearea__countdown__title {
  display: flex;
  margin-top: 20px;
}
.featurearea__countdown__title svg {
  color: var(--blackColor);
}
.featurearea__countdown__title h5 {
  margin-left: 6px;
}
.featurearea__countdown {
  display: flex;
  margin-bottom: 25px;
}
.featurearea__countdown .count {
  margin-right: 6px;
  background: var(--primaryColor);
  text-align: center;
  line-height: 1;
  border-radius: 10px;
  display: flex;
  height: 80px;
  width: 80px;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__countdown .count {
    height: 70px;
    width: 70px;
  }
}
@media (max-width: 767px) {
  .featurearea__countdown .count {
    height: 60px;
    width: 60px;
  }
}
.featurearea__countdown .count p {
  margin-bottom: 5px;
  color: var(--whiteColor);
  font-size: 20px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__countdown .count p {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .featurearea__countdown .count p {
    font-size: 15px;
  }
}
.featurearea__countdown .count span {
  color: var(--whiteColor);
  font-size: 16px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__countdown .count span {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .featurearea__countdown .count span {
    font-size: 15px;
  }
}
.featurearea .progress {
  height: 4px;
}
.featurearea .progress .progress-bar {
  background: var(--primaryColor);
}
.featurearea__progress__text h6 {
  color: var(--blackColor);
}
.featurearea__size {
  margin-top: 20px;
  margin-bottom: 7px;
  color: var(--blackColor);
}
.featurearea__size__button ul li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  color: var(--blackColor);
  border: 1px solid var(--borderColor);
  display: inline-block;
  text-align: center;
  border-radius: var(--borderRadius);
}
.featurearea__size__button ul li a:hover {
  background: var(--blackColor);
  color: var(--whiteColor);
}
.featurearea__size__img ul {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.featurearea__size__img ul li a img {
  border: 1px solid var(--borderColor);
  border-radius: var(--borderRadius);
  padding: 3px;
  max-width: 60px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__size__img ul li a img {
    max-width: 40px;
  }
}
@media (max-width: 767px) {
  .featurearea__size__img ul li a img {
    max-width: 36px;
  }
}
.featurearea__quantity__button {
  margin-right: 20px;
  padding: 10px 37px;
  border: 2px solid var(--blackColor);
  border-radius: 40px;
  color: var(--blackColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .featurearea__quantity__button {
    margin-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__quantity__button {
    margin-right: 5px;
  }
}
@media (max-width: 767px) {
  .featurearea__quantity__button {
    margin-right: 10px;
    padding: 3px 15px;
  }
}
.featurearea__quantity__button:hover a {
  color: var(--primaryColor);
}
.featurearea__quantity {
  display: flex;
  margin-bottom: 30px;
}
.featurearea__bottom__button a {
  background: var(--blackColor);
  color: var(--whiteColor);
  padding: 15px 110px;
  border-radius: 30px;
  display: inline-block;
}
@media (min-width: 768px) and (max-width: 991px) {
  .featurearea__bottom__button a {
    padding: 15px 90px;
  }
}
.featurearea__bottom__button a:hover {
  background: var(--primaryColor);
}
@media (max-width: 767px) {
  .featurearea__bottom__button a {
    padding: 12px 60px;
  }
}
.featurearea__thumb__img {
  padding-top: 10px;
}
.featurearea__thumb__img.slider__default__arrow .slick-arrow {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
}
.featurearea__single__big__img {
  border: 1px solid var(--borderColor);
}
.featurearea__single__big__img img {
  width: 100%;
}
.featurearea__single__thumb__img {
  border: 1px solid var(--borderColor);
  cursor: pointer;
}
.featurearea__single__thumb__img img {
  width: 100%;
}

.tabcollectionarea__item__wrap {
  margin-bottom: 20px;
}
.tabcollectionarea__item__wrap.text-center {
  justify-content: center;
}
.tabcollectionarea__product__item__link {
  border: none;
  margin: 0 30px 0 0;
  background: none;
  font-size: 20px;
  font-weight: 500;
}
.tabcollectionarea__product__item__link:hover {
  color: var(--primaryColor);
}
.tabcollectionarea__product__item__link.active {
  color: var(--primaryColor);
}

.descriptionarea__tab__wrapper {
  border: 1px solid var(--borderColor);
  padding: 30px 30px 37px;
}
.descriptionarea__link {
  font-size: 18px;
  text-transform: capitalize;
  line-height: 1;
  position: relative;
  padding: 0 0 10px;
  margin: 0 32px 15px 0;
  border: none;
  background: none;
  color: var(--blackColor);
}
.descriptionarea__link.active:after {
  width: 100%;
}
.descriptionarea__link:hover:after {
  width: 100%;
}
.descriptionarea__link:after {
  content: "";
  height: 2px;
  left: 0;
  width: 0;
  position: absolute;
  -webkit-transition: width 0.3s ease-out;
  -o-transition: width 0.3s ease-out;
  transition: width 0.3s ease-out;
  background-color: var(--primaryColor);
  bottom: 0;
}

@media (min-width: 768px) {
  .featurearea__details__img {
    position: sticky;
    top: 100px;
    margin-bottom: 0;
  }
}
.cart__pm__btn__wrap {
  display: flex;
  align-items: center;
}

.faqarea__heading {
  position: relative;
  transform: rotate(-90deg) translatex(-50%);
}
@media (min-width: 768px) and (max-width: 991px) {
  .faqarea__heading {
    transform: none;
  }
}
@media (max-width: 767px) {
  .faqarea__heading {
    transform: none;
  }
}
.faqarea__heading h3 {
  color: var(--lightGrey);
  font-size: 200px;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.5;
}
@media (min-width: 1366px) and (max-width: 1499px) {
  .faqarea__heading h3 {
    font-size: 140px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .faqarea__heading h3 {
    font-size: 140px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .faqarea__heading h3 {
    font-size: 150px;
  }
}
@media (max-width: 767px) {
  .faqarea__heading h3 {
    font-size: 150px;
  }
}
.faqarea__wrapper .accordion-item .accordion-header button {
  padding: 24px 25px 25px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  transform: inherit;
  background-color: var(--whiteColor);
  display: flex;
  align-items: center;
  width: 100%;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .faqarea__wrapper .accordion-item .accordion-header button {
    font-size: 18px;
    padding: 14px 10px 15px;
  }
}

@media (min-width: 1366px) and (max-width: 1499px) {
  .cartarea {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .cartarea {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cartarea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .cartarea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.cartarea__table__content table {
  border: 1px solid var(--borderColor);
  width: 100%;
}
.cartarea__table__content table thead tr {
  background-color: var(--greywhite);
  border: 1px solid var(--borderColor);
}
.cartarea__table__content table thead tr th {
  border-top: medium none;
  font-size: 14px;
  font-weight: 600;
  padding: 21px 45px 22px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  color: var(--blackColor);
  min-width: 100px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .cartarea__table__content table thead tr th {
    padding: 21px 25px 22px;
  }
}
@media (max-width: 767px) {
  .cartarea__table__content table thead tr th {
    padding: 13px 4px 9px;
    font-size: 10px;
  }
}
.cartarea__table__content table tbody tr {
  border-bottom: 1px solid var(--borderColor);
  color: var(--blackColor);
}
.cartarea__table__content table tbody tr td {
  font-size: 16px;
  text-align: center;
  border: 1px solid var(--borderColor);
  padding: 20px 0;
}
@media (max-width: 767px) {
  .cartarea__table__content table tbody tr td {
    font-size: 10px;
    padding: 15px 0;
  }
}
.cartarea__table__content table tbody tr td a img {
  max-width: 80px;
}
.cartarea__table__content table tbody tr td a svg {
  width: 25px;
}
.cartarea__product__name {
  width: 435px;
}
.cartarea__product__quantity {
  width: 435px;
}
.cartarea__plus__minus {
  display: inline-block;
  height: 55px;
  padding: 0;
  position: relative;
  border: 2px solid var(--borderColor2);
  overflow: hidden;
  max-width: 150px;
}
@media (max-width: 767px) {
  .cartarea__plus__minus {
    width: 90px;
  }
}
.cartarea__plus__minus .qtybutton {
  color: var(--blackColor);
  cursor: pointer;
  float: inherit;
  font-size: 16px;
  margin: 0;
  position: absolute;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 20px;
  text-align: center;
}
.cartarea__plus__minus .qtybutton.dec {
  left: 10px;
  top: 10px;
}
.cartarea__plus__minus .qtybutton.inc {
  right: 10px;
  top: 10px;
}
.cartarea__plus__minus__box {
  color: var(--blackColor);
  float: left;
  font-size: 14px;
  height: 50px;
  margin: 0;
  width: 100%;
  background: transparent none repeat scroll 0 0;
  border: none;
  padding: 0;
  text-align: center;
}
@media (max-width: 767px) {
  .cartarea__plus__minus__box {
    width: 90px;
  }
}
.cartarea__product__thumbnail a img {
  width: 100%;
}
.cartarea__shiping__update__wrapper {
  padding: 30px 0 55px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .cartarea__shiping__update__wrapper {
    padding: 22px 0 36px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
}
.cartarea__shiping__update a {
  background-color: var(--blackColor);
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 40px 17px;
  text-transform: uppercase;
  color: var(--whiteColor);
}
@media (max-width: 767px) {
  .cartarea__shiping__update a {
    padding: 18px 20px;
  }
}
.cartarea__shiping__update a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.cartarea__clear a {
  background-color: var(--blackColor);
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 40px 17px;
  text-transform: uppercase;
  margin-right: 20px;
  color: var(--whiteColor);
}
@media (max-width: 767px) {
  .cartarea__clear a {
    padding: 18px 20px;
  }
}
.cartarea__clear a:last-child {
  margin-right: 0;
}
.cartarea__clear a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.cartarea__tax {
  background-color: var(--greywhite);
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  padding: 45px 30px 50px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .cartarea__tax {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .cartarea__tax {
    margin-bottom: 30px;
  }
}
.cartarea__title {
  position: relative;
}
.cartarea__title::after {
  background-color: var(--borderColor2);
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 10px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}
.cartarea__title h4 {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding-right: 18px;
  background-color: var(--whiteColor);
  position: relative;
  z-index: 99;
}
.cartarea__text {
  margin-top: 22px;
}
.cartarea__tax__select {
  margin-bottom: 20px;
}
.cartarea__tax__select label {
  color: var(--blackColor);
}
.cartarea__tax__select select {
  box-shadow: none;
  font-size: 12px;
  height: 40px;
  padding: 0 50px 0 15px;
  width: 100%;
  cursor: pointer;
}
.cartarea__code {
  margin-bottom: 20px;
}
.cartarea__code label {
  color: var(--blackColor);
}
.cartarea__code input {
  box-shadow: none;
  font-size: 12px;
  height: 40px;
  padding: 0 50px 0 15px;
  width: 100%;
  cursor: pointer;
  border: 1px solid var(--borderColor2);
}
.cartarea__discount__code {
  margin: 21px 0 0;
}
.cartarea__discount__code textarea {
  border: 1px solid var(--borderColor2);
  background: var(--whiteColor);
  width: 100%;
  height: 100px;
}
.cartarea__grand__totall h5 {
  font-size: 14px;
  margin: 36px 0 27px;
}
.cartarea__grand__totall h5 span {
  float: right;
  font-size: 18px;
  font-weight: 500;
}
.cartarea__grand__totall a {
  color: var(--whiteColor);
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 10px 17px;
  text-align: center;
  text-transform: uppercase;
  background: var(--blackColor);
}
.cartarea__grand__totall a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}

@media (max-width: 767px) {
  .cartarea__shiping__update {
    margin-bottom: 10px;
  }
}

@media (min-width: 1366px) and (max-width: 1499px) {
  .checkoutarea {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .checkoutarea {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .checkoutarea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .checkoutarea {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.checkoutarea__billing__heading h2 {
  border-bottom: 1px solid var(--borderColor);
  color: var(--blackColor);
  font-size: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.checkoutarea__inputbox label {
  font-weight: 500;
  color: var(--blackColor);
  margin-bottom: 5px;
  font-size: 14px;
}
.checkoutarea__inputbox input {
  background: transparent;
  border: 1px solid var(--borderColor);
  color: var(--blackColor);
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
}
.checkoutarea__payment__wraper {
  background: var(--greyColor2);
  padding: 35px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .checkoutarea__payment__wraper {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .checkoutarea__payment__wraper {
    padding: 10px;
  }
}
.checkoutarea__total h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.checkoutarea__table {
  width: 100%;
}
.checkoutarea__item {
  border-top: 1px solid var(--borderColor2);
}
.checkoutarea__item td {
  border-style: solid none none;
  border-width: 1px medium medium;
  font-weight: 500;
  padding: 15px;
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .checkoutarea__item td {
    padding: 10px;
  }
}
.checkoutarea__payment__input__box {
  margin-top: 30px;
}
.checkoutarea__payment__input__box a {
  padding: 10px 40px;
}

.checkoutarea__payment__type label {
  color: var(--blackColor);
  padding-left: 5px;
  padding-bottom: 10px;
}

.shoptab {
  display: flex;
  padding: 10px 30px;
  background-color: var(--darkdeep3);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .shoptab {
    padding: 10px;
  }
}
.shoptab__inner ul li {
  padding-right: 10px;
}
.shoptab__inner ul li button {
  border: none;
  background: none;
}
.shoptab__inner ul li button svg {
  width: 20px;
  height: 20px;
  display: inline-block;
  color: var(--blackColor);
}
.shoptab__inner ul li button:hover svg, .shoptab__inner ul li button.active svg {
  color: var(--primaryColor);
}
.shoptab__shoing__wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.shoptab__shoing__wrap p {
  margin: 0;
}
.shoptab__select {
  margin-right: 50px;
  align-items: center;
  color: var(--blackColor);
}
.shoptab__select label {
  margin-right: 10px;
}
.shoptab__select select {
  height: 40px;
  padding: 10px;
  cursor: pointer;
  border: none;
  background: none;
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .shoptab__select select {
    max-width: 170px;
  }
}

.shopsidebar__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.shopsidebar__top h2 {
  font-size: 22px;
}
.shopsidebar__remove a {
  position: relative;
}
.shopsidebar__remove a:hover:after {
  background: var(--primaryColor);
}
.shopsidebar__remove a:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: var(--blackColor);
  bottom: -1px;
  left: 0;
}
.shopsidebar__bitton {
  margin-bottom: 30px;
}
.shopsidebar__widget {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 50px;
}
.shopsidebar__list {
  margin-top: 10px;
}
.shopsidebar__list ul li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.shopsidebar__widget summary {
  color: var(--blackColor);
}
.shopsidebar__widget .shopsidebar__box label {
  color: var(--blackColor);
}

.swiper.modal__gallery {
  margin-top: 10px;
}

.dashboardarea__img {
  position: relative;
  z-index: 2;
}
.dashboardarea__img img {
  width: 100%;
}
.dashboardarea__img.dashboardarea__margin__0 {
  margin: 0;
  margin-bottom: 30px;
}
.dashboardarea__inner {
  background-color: var(--naveBlue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  border-radius: var(--borderRadius2);
  flex-wrap: wrap;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .dashboardarea__inner {
    bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .dashboardarea__inner {
    bottom: 0px;
  }
}
@media (max-width: 767px) {
  .dashboardarea__inner {
    padding: 20px;
    text-align: center;
    justify-content: center;
  }
}
.dashboardarea__inner.student__dashboard__inner {
  background: var(--skycolor);
}
.dashboardarea__inner.student__dashboard__inner .default__button {
  background-color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
}
.dashboardarea__inner.admin__dashboard__inner {
  background-color: var(--primaryColor);
}
.dashboardarea__inner.admin__dashboard__inner .default__button {
  border: 1px solid var(--whiteColor);
}
.dashboardarea__left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .dashboardarea__left {
    justify-content: center;
  }
}
.dashboardarea__left__img {
  width: 120px;
  height: 120px;
  margin-right: 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .dashboardarea__left__img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
}
.dashboardarea__left__img img {
  width: 100%;
  border-radius: 100%;
  border: 2px solid var(--darkdeep7);
  padding: 4px;
}
.dashboardarea__left__content h4 {
  margin: 0;
  margin-bottom: 0;
  line-height: 1.24;
  color: var(--whiteColor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .dashboardarea__left__content h4 {
    margin-bottom: 0px;
    line-height: 1;
  }
}
.dashboardarea__left__content h5 {
  color: var(--whiteColor);
  margin-bottom: 5px;
}
.dashboardarea__left__content ul {
  margin-top: 5px;
}
.dashboardarea__left__content ul li {
  color: var(--whiteColor);
  font-size: 14px;
  margin-right: 15px;
}
.dashboardarea__left__content ul li svg {
  width: 18px;
}
.dashboardarea__star {
  text-align: center;
}
.dashboardarea__star svg, .dashboardarea__star i {
  width: 16px;
  color: var(--yellow);
  font-size: 16px;
}
.dashboardarea__star span {
  color: var(--whiteColor);
  font-size: 16px;
  display: block;
}

.dashboard {
  padding-top: 30px;
}
.dashboard__inner {
  padding: 20px 30px 30px;
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.02);
  background: var(--whiteColor);
  border-radius: var(--borderRadius3);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .dashboard__inner {
    padding: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .dashboard__inner {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .dashboard__inner {
    margin-bottom: 30px;
  }
}
.dashboard__nav__title {
  margin-bottom: 10px;
  margin-top: 20px;
}
.dashboard__nav__title h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  background: var(--lightGrey5);
  padding: 10px 10px 7px;
  color: var(--contentColor);
  line-height: 1;
}
.dashboard__nav ul li {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid var(--borderColor);
  width: 100%;
  position: relative;
}
.dashboard__nav ul li a {
  font-size: 16px;
}
.dashboard__nav ul li a.active {
  color: var(--primaryColor);
}
.dashboard__nav ul li a svg {
  width: 16px;
  margin-right: 10px;
}
.dashboard__content__wraper {
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.02);
  padding: 50px 40px;
  background: var(--whiteColor);
  margin-bottom: 30px;
  border-radius: var(--borderRadius2);
}
@media (max-width: 767px) {
  .dashboard__content__wraper {
    padding: 10px;
  }
}
.dashboard__section__title {
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--borderColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard__section__title h4 {
  margin: 0;
}
.dashboard__single__counter {
  padding: 50px 40px;
  background: var(--lightGrey5);
  margin-bottom: 20px;
  border-radius: var(--borderRadius3);
  box-shadow: 0 0 1px 1px rgba(95, 45, 237, 0.2);
}
@media (max-width: 767px) {
  .dashboard__single__counter {
    padding: 20px;
  }
}
.dashboard__table table {
  width: 100%;
}
.dashboard__table table thead {
  background: var(--lightGrey5);
}
.dashboard__table table thead tr {
  width: 100%;
}
.dashboard__table table thead tr th {
  padding: 10px 20px;
}
@media (max-width: 767px) {
  .dashboard__table table thead tr th {
    padding: 10px 5px;
    min-width: 150px;
    font-size: 14px;
  }
}
.dashboard__table table tbody tr {
  width: 100%;
}
.dashboard__table table tbody tr th {
  font-weight: 400;
  font-size: 16px;
  padding: 10px 20px;
  border: 0 none;
  color: var(--darkblack);
}
@media (max-width: 767px) {
  .dashboard__table table tbody tr th {
    padding: 10px 5px;
    font-size: 13px;
  }
}
.dashboard__table table tbody tr th p {
  margin: 0;
}
@media (max-width: 767px) {
  .dashboard__table table tbody tr th p {
    font-size: 13px;
  }
}
.dashboard__table table tbody tr th span {
  color: var(--blackColor);
  font-weight: 700;
}
@media (max-width: 767px) {
  .dashboard__table table tbody tr th span {
    font-size: 13px;
  }
}
.dashboard__table table tbody tr th p a {
  color: var(--blackColor);
}
@media (max-width: 767px) {
  .dashboard__table table tbody tr th p a {
    font-size: 13px;
  }
}
.dashboard__table table tbody tr td {
  padding: 10px 20px;
  color: var(--contentColor);
}
@media (max-width: 767px) {
  .dashboard__table table tbody tr td {
    padding: 10px 5px;
    font-size: 13px;
  }
}
.dashboard__table table tbody tr td span.dashboard__td {
  background: var(--greencolor2);
  padding: 5px 7px;
  font-size: 12px;
  display: flex;
  height: 22px;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  width: max-content;
  color: var(--whiteColor);
}
@media (max-width: 767px) {
  .dashboard__table table tbody tr td span.dashboard__td {
    font-size: 13px;
  }
}
.dashboard__table table tbody tr td span.dashboard__td--cancel {
  background-color: var(--secondaryColor);
}
.dashboard__table table tbody tr td span.dashboard__td--over {
  background-color: var(--primaryColor);
}
.dashboard__table table tbody tr td span.dashboard__td--comming {
  background-color: var(--skycolor);
}
.dashboard__table table tbody tr td span.dashboard__td__2 {
  color: var(--whiteColor);
  background: var(--primaryColor);
}
@media (max-width: 767px) {
  .dashboard__table table tbody tr td span.dashboard__td__2 {
    font-size: 13px;
  }
}
.dashboard__table table tbody tr td span {
  color: var(--blackColor);
  font-weight: 700;
}
@media (max-width: 767px) {
  .dashboard__table table tbody tr td span {
    font-size: 13px;
  }
}
.dashboard__table__star svg, .dashboard__table__star i {
  width: 16px;
  color: var(--primaryColor);
  font-size: 16px;
}
.dashboard__table__row {
  background: var(--lightGrey5);
}
.dashboard__form {
  font-size: 18px;
  line-height: 1.67;
  color: var(--contentColor);
}
.dashboard__form__margin {
  margin-top: 15px;
}
.dashboard__star__course {
  font-size: 15px;
  line-height: 1.67;
}
.dashboard__star__course a {
  color: var(--blackColor);
  font-weight: 700;
}
.dashboard__star__course a :hover {
  color: var(--secondaryColor);
}
.dashboard__small__text {
  margin: 0;
}
.dashboard__rating__count {
  font-size: 14px;
}
.dashboard__small__btn {
  font-size: 14px;
  margin: 10px;
  color: var(--blackColor);
  position: relative;
  transition: 0.3s;
  font-weight: 700;
}
.dashboard__small__btn:hover::after {
  width: 100%;
}
.dashboard__small__btn::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 0;
  background: var(--primaryColor);
  bottom: -4px;
  left: 0;
  transition: 0.3s;
}
.dashboard__small__btn svg {
  width: 14px;
}
.dashboard__small__btn__2 {
  color: var(--whiteColor);
  transition: 0.3s;
  font-weight: 700;
  padding: 0 14px;
  font-size: 14px;
  height: 30px;
  line-height: 29px;
  background: var(--primaryColor);
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--primaryColor);
  justify-content: center;
  margin: 5px 0;
}
.dashboard__small__btn__2:hover {
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  background: var(--whiteColor);
}
.dashboard__small__btn__2 svg {
  width: 14px;
}
.dashboard__small__btn__3 {
  background: var(--secondaryColor);
  color: var(--whiteColor);
  border: 1px solid var(--secondaryColor);
}
.dashboard__button__wrap li {
  margin-right: 10px;
}
.dashboard__button__wrap li button {
  padding: 15px 39px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .dashboard__button__wrap li button {
    padding: 15px 20px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .dashboard__button__wrap li button {
    padding: 10px 20px;
    font-size: 14px;
  }
}
.dashboard__select__heading span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.5;
  margin-bottom: 6px;
  display: inline-block;
}
.dashboard__selector select {
  padding: 13px;
  border: none;
  box-shadow: var(--boxshadow1);
}
.dashboard__Announcement__wraper {
  background: var(--lightGrey7);
  padding: 25px 30px;
  box-shadow: var(--shadow-1);
  border-radius: 6px;
  margin-bottom: 60px;
}
.dashboard__Announcement h5 {
  margin: 0;
  margin-bottom: 5px;
}
.dashboard__Announcement p {
  margin: 0;
}
.dashboard__form__input label {
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--blackColor);
}
.dashboard__form__input label svg {
  width: 14px;
}
.dashboard__form__input input {
  width: 100%;
  background-color: transparent;
  border: 2px solid var(--borderColor);
  border-radius: 6px;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: var(--contentColor);
  margin-bottom: 15px;
}
.dashboard__form__input textarea {
  width: 100%;
  background-color: transparent;
  border: 2px solid var(--borderColor);
  border-radius: 6px;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: var(--contentColor);
  margin-bottom: 15px;
}
.dashboard .counterarea__text__wraper {
  margin-bottom: 0;
}

.become__instructor__heading {
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 40px;
  font-size: 48px;
  font-weight: 600;
}
.become__instructor__small__heading {
  font-weight: 600;
  font-size: 24px;
}
.become__instructor__text p {
  margin-top: 20px;
}
.become__instructor__img {
  width: 25px;
  height: 25px;
}
.become__instructor__img img {
  width: 100%;
}
.become__instructor__list {
  margin-bottom: 30px;
}
.become__instructor__list ul li {
  margin-top: 20px;
  display: flex;
  color: var(--contentColor);
}
.become__instructor__list ul li .become__instructor__img {
  margin-right: 20px;
}
.become__instructor__form {
  background: var(--darkdeep3);
  padding: 40px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .become__instructor__form {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .become__instructor__form {
    padding: 10px;
  }
}
.become__instructor__form .dashboard__form__wraper .dashboard__form__input input {
  background: var(--whiteColor);
}
.become__instructor__form .dashboard__form__wraper .dashboard__form__input textarea {
  background: var(--whiteColor);
}
.become__instructor__check {
  margin-bottom: 13px;
  color: var(--contentColor);
}
.become__instructor__check__label a {
  color: var(--blackColor);
  font-weight: 700;
  position: relative;
}
.become__instructor__check__label a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: var(--blackColor);
  left: 0;
  bottom: -2px;
}
.become__instructor__check__label a:hover {
  color: var(--primaryColor);
}
.become__instructor__check__label a:hover::after {
  color: var(--primaryColor);
}

@media (min-width: 1366px) and (max-width: 1499px) {
  .create__course {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .create__course {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .create__course {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .create__course {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.create__course__accordion__wraper .accordion-item {
  margin-bottom: 20px;
  background: var(--whiteColor);
  border: none;
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.02);
}
.create__course__accordion__wraper .accordion-item h2 {
  padding: 20px 30px;
}
.create__course__accordion__wraper .accordion-item h2 button {
  font-size: 18px;
  line-height: 27px;
  width: 100%;
  text-align: left;
  padding: 0;
  background-color: transparent;
  font-weight: 600;
  color: var(--blackColor);
}
.create__course__margin {
  margin-bottom: 20px;
}
.create__course__textarea {
  width: 100%;
  background-color: transparent;
  border: 2px solid var(--borderColor);
  border-radius: 6px;
  line-height: 23px;
  padding: 10px 20px;
  font-size: 14px;
  color: var(--contentColor);
  margin-bottom: 15px;
}
.create__course__small {
  margin-top: 10px;
  color: var(--contentColor);
}
.create__course__small svg {
  width: 14px;
}
.create__course__single__img {
  margin-bottom: 20px;
}
.create__course__single__img img {
  width: 100%;
}
.create__course__bottom__button {
  margin-top: 20px;
}
.create__course__bottom__button a {
  background: var(--primaryColor);
  color: var(--whiteColor);
  display: inline-block;
  text-align: center;
  padding: 13px;
  width: 100%;
  border-radius: 6px;
}
.create__course__bottom__button a:hover {
  background: var(--secondaryColor);
  color: var(--whiteColor);
}
.create__course__wraper {
  padding: 30px;
  border: 2px solid var(--primaryColor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .create__course__wraper {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .create__course__wraper {
    margin-top: 20px;
  }
}
.create__course__title {
  margin-bottom: 30px;
}
.create__course__title h4 {
  font-size: 30px;
  line-height: 1.25;
  font-weight: 700;
}
@media (max-width: 767px) {
  .create__course__title h4 {
    font-size: 24px;
  }
}
.create__course__list ul li {
  display: flex;
  font-size: 18px;
  margin: 7px 0;
  line-height: 1.45;
  color: var(--contentColor);
}
.create__course__list ul li svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: var(--greencolor);
}
.create__course small {
  color: var(--contentColor);
}

.dashboard__admin__report__wrap {
  display: flex;
  justify-content: center;
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.02);
  background: var(--whiteColor);
  padding: 40px 20px;
  border-radius: var(--borderRadius2);
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.dashboard__admin__report {
  padding: 0 20px;
}
@media (max-width: 767px) {
  .dashboard__admin__report {
    padding: 10px 0;
  }
}

.dashboard__linechart {
  width: 65%;
}
@media (max-width: 767px) {
  .dashboard__linechart {
    width: 100%;
  }
}

.dashboard__piechart {
  width: 35%;
}
@media (max-width: 767px) {
  .dashboard__piechart {
    width: 100%;
  }
}

.dashboard__admin__report__top .form-select {
  max-width: 170px;
}

.admin__content__wrapper {
  max-height: 550px;
  overflow: auto;
}

.dashboard__popular__instructor__single {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
  padding: 15px 0;
  flex-wrap: wrap;
}
.dashboard__popular__instructor__single:last-child {
  border-bottom: none;
  padding-bottom: inherit;
}
.dashboard__popular__instructor__img {
  max-width: 20%;
  padding-right: 10px;
}
@media (max-width: 767px) {
  .dashboard__popular__instructor__img {
    max-width: 100%;
  }
}
.dashboard__popular__instructor__img img {
  width: 100%;
  border-radius: 100px;
}
.dashboard__popular__instructor__content {
  max-width: 80%;
}
@media (max-width: 767px) {
  .dashboard__popular__instructor__content {
    max-width: 100%;
  }
}
.dashboard__popular__instructor__content li {
  font-size: 14px;
  margin-right: 15px;
  color: var(--darkblack);
}
.dashboard__popular__instructor__content h4 {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 1;
}
.dashboard__popular__instructor__content span {
  color: var(--darkblack);
}
.dashboard__popular__instructor.dashboard__notificatoin__wrap img {
  border-radius: 0;
  max-width: 50px;
}
.dashboard__popular__instructor.dashboard__notificatoin__wrap .dashboard__popular__instructor__img {
  padding-right: 20px;
}

.dashboard__recent__course__single {
  display: flex;
  border-bottom: 1px solid var(--borderColor);
  padding: 20px 0px;
  align-items: center;
  flex-wrap: wrap;
}
.dashboard__recent__course__single:last-child {
  border-bottom: none;
  padding-bottom: inherit;
}
.dashboard__recent__course__heading h3 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .dashboard__recent__course__heading h3 {
    margin-top: 10px;
    font-size: 14px;
  }
}
.dashboard__recent__course__img {
  width: 30%;
  padding-right: 20px;
}
@media (max-width: 767px) {
  .dashboard__recent__course__img {
    width: 100%;
    padding-right: 0;
  }
}
.dashboard__recent__course__img img {
  width: 100%;
}
.dashboard__recent__course__content {
  width: 70%;
}
@media (max-width: 767px) {
  .dashboard__recent__course__content {
    width: 100%;
  }
}
.dashboard__recent__course__meta ul {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.dashboard__recent__course__meta ul li {
  color: var(--darkblack);
}

.dashboard__meessage {
  min-width: 280px;
  height: 100%;
  background: var(--whiteColor);
  overflow: hidden;
  position: relative;
  border: 1px solid var(--borderColor);
  border-radius: 10px;
  max-height: 600px;
  overflow-y: scroll;
}
.dashboard__meessage__chat {
  padding: 15px 30px;
  background: var(--deepgreen);
}
.dashboard__meessage__chat h3 {
  font-size: 22px;
  line-height: 30px;
  color: var(--whiteColor);
  font-weight: 600;
  margin: 0px;
}
.dashboard__meessage__search {
  padding: 30px 30px;
  position: relative;
  border-bottom: 1px solid var(--borderColor);
}
.dashboard__meessage__search button {
  position: absolute;
  top: 42px;
  left: 50px;
  font-size: 18px;
  color: var(--darkdeep4);
  font-weight: 400;
  cursor: pointer;
  background: transparent;
  z-index: 1;
  transition: all 500ms ease;
  border: none;
}
.dashboard__meessage__search input {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid var(--borderColor);
  border-radius: 25px;
  padding: 10px 20px 10px 47px;
  font-size: 14px;
  font-weight: 500;
  color: var(--darkdeep4);
  background: var(--whiteColor);
}
.dashboard__meessage__contact ul li {
  position: relative;
  padding: 15px 30px 15px 30px;
  cursor: pointer;
  border-bottom: 1px solid var(--borderColor);
  width: 100%;
}
.dashboard__meessage__dot {
  position: absolute;
  left: 38px;
  bottom: 0px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid var(--whiteColor);
  z-index: 1;
  background: var(--primaryColor);
}
.dashboard__meessage__contact__wrap {
  position: relative;
  display: flex;
}
.dashboard__meessage__chat__img {
  margin-right: 20px;
  max-width: 50px;
}
.dashboard__meessage__chat__img img {
  width: 100%;
}
.dashboard__meessage__meta {
  position: relative;
  min-height: 50px;
}
.dashboard__meessage__meta h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 0px;
}
.dashboard__meessage__meta p {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: var(--darkdeep4);
  font-weight: 400;
  margin: 0px;
}
.dashboard__meessage__meta .chat__time {
  position: absolute;
  top: 0px;
  right: -180px;
  font-size: 14px;
  line-height: 24px;
  color: var(--darkdeep4);
  font-weight: 400;
  margin: 0px;
}
.dashboard__meessage__profile {
  position: relative;
  display: block;
  padding: 1px 0px 12px 65px;
  border-bottom: 1px solid var(--borderColor);
  min-height: 60px;
}
.dashboard__meessage__profile__img img {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.dashboard__meessage__profile__meta h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 0px;
}
.dashboard__meessage__profile__meta p {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: var(--darkdeep4);
  font-weight: 400;
  margin: 0px;
}
.dashboard__meessage__profile__chat__option {
  position: absolute;
  right: 0px;
  bottom: 12px;
}
.dashboard__meessage__profile__chat__option a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: var(--darkdeep4);
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid var(--borderColor);
}
.dashboard__meessage__profile__chat__option a:first-child {
  margin-right: 6px;
}
.dashboard__meessage__profile__chat__option a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}
.dashboard__meessage__sent__item__img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.dashboard__meessage__sent__item__img img {
  width: 100%;
  border-radius: 100%;
}
.dashboard__meessage__sent {
  margin-top: 40px;
  max-height: 500px;
  overflow-y: scroll;
}
.dashboard__meessage__sent ul li {
  position: relative;
  max-width: 415px;
  display: flex;
}
.dashboard__meessage__sent ul li p {
  display: block;
  padding: 10px 15px;
  border-radius: 5px;
  background: var(--darkdeep3);
  color: var(--blackColor);
  font-size: 14px;
  line-height: 26px;
}
.dashboard__meessage__sent ul li span {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: var(--darkdeep4);
  margin-bottom: 10px;
}
.dashboard__meessage__sent__item {
  margin-left: auto;
}
.dashboard__meessage__sent__item .dashboard__meessage__sent__item__img {
  margin-right: 0;
  margin-left: 20px;
}
.dashboard__meessage__sent__item .dashboard__meessage__sent__item__content {
  text-align: right;
}
.dashboard__meessage__input {
  position: relative;
  padding-right: 30px;
}
@media (max-width: 767px) {
  .dashboard__meessage__input {
    padding-right: 0;
  }
}
.dashboard__meessage__input input {
  position: relative;
  width: 100%;
  height: 50px;
  background: var(--darkdeep3);
  font-size: 14px;
  font-weight: 500;
  color: var(--darkdeep4);
  border-radius: 25px;
  padding: 10px 30px 10px 85px;
  border: none;
}
.dashboard__meessage__input .attachment {
  position: absolute;
  left: 25px;
  z-index: 1;
  font-size: 20px;
  color: var(--darkdeep4);
  top: 15px;
  cursor: pointer;
}
.dashboard__meessage__input .attachment:after {
  position: absolute;
  content: "";
  background: #e5eded;
  height: 50px;
  width: 1px;
  top: -15px;
  right: -20px;
}
.dashboard__meessage__input button {
  position: absolute;
  right: 30px;
  top: 0px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  align-items: center;
  border-radius: 50%;
  text-align: center;
  background: var(--primaryColor);
  border: none;
}
@media (max-width: 767px) {
  .dashboard__meessage__input button {
    right: 0;
  }
}
.dashboard__meessage__input button i {
  font-size: 20px;
  color: var(--whiteColor);
}

.dashboard__meessage__content__wrap {
  background: var(--whiteColor);
  padding: 10px;
  border-radius: var(--borderRadius3);
}
@media (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .dashboard__meessage__content__wrap {
    margin-top: 30px;
  }
}

.dashboard__label {
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  display: inline-block;
  color: var(--whiteColor);
  padding: 0px 9px;
  border-radius: 15px;
  background-color: var(--primaryColor);
}

.grid__course__status {
  padding-top: 20px;
}
.grid__course__status .progress {
  margin-bottom: 15px;
  height: 25px;
}
.grid__course__status .progress-bar {
  background-color: var(--primaryColor);
  font-size: 10px;
}
.grid__course__status .default__button {
  display: block;
}

/*------------------------------
Footer Area
-------------------------------*/
.footerarea {
  background: var(--darkblack);
}
.footerarea__newsletter__wraper {
  padding-bottom: 45px;
  padding-top: 65px;
  border-bottom: 1px solid var(--darkcolor);
}
.footerarea__newsletter {
  margin-left: 30px;
}
@media (max-width: 767px) {
  .footerarea__newsletter {
    margin-left: 0px;
    margin-top: 30px;
  }
}
.footerarea__newsletter__input {
  position: relative;
}
.footerarea__newsletter__input input {
  width: 100%;
  color: var(--whiteColor);
  height: 62px;
  background: var(--deepgray);
  font-size: 16px;
  padding-left: 15px;
  border: 1px solid var(--deepgray);
  border-radius: var(--borderRadius);
}
.footerarea__newsletter__input input::placeholder {
  color: var(--darkgray);
}
.footerarea__newsletter__input input:focus {
  border: 1px solid var(--whitegrey);
}
.footerarea__newsletter__button {
  position: absolute;
  top: 0;
  right: 0;
}
.footerarea__newsletter__button .subscribe__btn {
  padding: 0 20px;
  background: var(--primaryColor);
  text-align: center;
  color: var(--whiteColor);
  display: inline-block;
  border: 1px solid var(--primaryColor);
  border-radius: var(--borderRadius);
  font-size: 15px;
  height: 62px;
  line-height: 62px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerarea__newsletter__button .subscribe__btn {
    padding: 0 10px;
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .footerarea__newsletter__button .subscribe__btn {
    padding: 0 13px;
    font-size: 12px;
  }
}
.footerarea__newsletter__button .subscribe__btn:hover {
  background: inherit;
  border: 1px solid var(--primaryColor);
}

.footerarea__newsletter__wraper .row {
  align-items: center;
}

.footerarea__wrapper {
  padding-top: 110px;
  padding-bottom: 80px;
  border-bottom: 1px solid var(--darkcolor);
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerarea__wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .footerarea__wrapper {
    padding-top: 60px;
    padding-bottom: 50px;
  }
}
.footerarea__wrapper.footerarea__wrapper__2 {
  border-bottom: none;
}
.footerarea__wrapper .footerarea__padding__left {
  padding-left: 60px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footerarea__wrapper .footerarea__padding__left {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerarea__wrapper .footerarea__padding__left {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .footerarea__wrapper .footerarea__padding__left {
    padding-left: 0;
  }
}
.footerarea__wrapper .footerarea__right__wraper {
  padding-left: 50px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footerarea__wrapper .footerarea__right__wraper {
    padding-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerarea__wrapper .footerarea__right__wraper {
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .footerarea__wrapper .footerarea__right__wraper {
    padding-left: 0;
  }
}
.footerarea__wrapper .footerarea__about__us {
  margin-right: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerarea__wrapper .footerarea__inner {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .footerarea__wrapper .footerarea__inner {
    margin-top: 20px;
  }
}
.footerarea__wrapper .footerarea__inner .footerarea__heading {
  margin-bottom: 20px;
}
.footerarea__wrapper .footerarea__inner .footerarea__heading h3 {
  font-size: 22px;
  font-weight: 700;
  color: var(--whiteColor);
  font-family: var(--headingFont);
}
.footerarea__wrapper .footerarea__inner .footerarea__content {
  margin-bottom: 30px;
}
.footerarea__wrapper .footerarea__inner .footerarea__content p {
  color: var(--darkgray);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footerarea__wrapper .footerarea__inner .footerarea__content p {
    font-size: 14px;
  }
}
.footerarea__wrapper .footerarea__inner .footerarea__icon {
  margin-top: 30px;
}
.footerarea__wrapper .footerarea__inner .footerarea__icon ul li {
  margin-right: 5px;
}
.footerarea__wrapper .footerarea__inner .footerarea__icon ul li a {
  height: 44px;
  width: 44px;
  line-height: 44px;
  background: var(--deepgray);
  font-size: 16px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  color: var(--whitegrey);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footerarea__wrapper .footerarea__inner .footerarea__icon ul li a {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
.footerarea__wrapper .footerarea__inner .footerarea__icon ul li a:hover {
  color: var(--whiteColor);
}
.footerarea__wrapper .footerarea__inner .footerarea__list ul li {
  display: block;
  margin-bottom: 12px;
}
.footerarea__wrapper .footerarea__inner .footerarea__list ul li a {
  color: var(--darkgray);
  font-size: 16px;
  font-weight: 400;
  font-family: var(--contentFont);
  transition: 0.3s;
  position: relative;
}
.footerarea__wrapper .footerarea__inner .footerarea__list ul li a:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 0;
  background: var(--primaryColor);
  left: 0;
  bottom: -5px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.footerarea__wrapper .footerarea__inner .footerarea__list ul li a:hover {
  color: var(--primaryColor);
}
.footerarea__wrapper .footerarea__inner .footerarea__list ul li a:hover::after {
  opacity: 1;
  visibility: visible;
  width: 100%;
}

.footerarea__right__wraper .footerarea__right__list ul li {
  margin-bottom: 15px;
  display: block;
}
.footerarea__right__wraper .footerarea__right__list ul li a {
  display: flex;
  align-items: center;
}
.footerarea__right__wraper .footerarea__right__list ul li a:hover .footerarea__right__content h6 {
  color: var(--primaryColor);
}
.footerarea__right__wraper .footerarea__right__list ul li a .footerarea__right__img {
  margin-right: 11px;
  max-width: 60px;
}
.footerarea__right__wraper .footerarea__right__list ul li a .footerarea__right__content span {
  font-size: 12px;
  color: var(--darkgray);
}
.footerarea__right__wraper .footerarea__right__list ul li a .footerarea__right__content h6 {
  font-size: 15px;
  color: var(--whiteColor);
}

.footerarea__copyright__wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerarea__copyright__wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footerarea__copyright__wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerarea__copyright__wrapper .footerarea__copyright__content.footerarea__copyright__content__2 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footerarea__copyright__wrapper .footerarea__copyright__content.footerarea__copyright__content__2 {
    margin-bottom: 20px;
  }
}
.footerarea__copyright__wrapper .footerarea__copyright__content.footerarea__copyright__content__2 p {
  color: var(--whiteColor);
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footerarea__copyright__wrapper .footerarea__copyright__content.footerarea__copyright__content__2 p {
    font-size: 14px;
  }
}
.footerarea__copyright__wrapper .footerarea__copyright__content p {
  color: var(--darkgray);
  margin-bottom: 0;
}
.footerarea__copyright__wrapper .footerarea__copyright__content p span {
  color: var(--primaryColor);
}
.footerarea__copyright__wrapper .footerarea__copyright__content p a {
  color: var(--darkgray);
}
.footerarea__copyright__wrapper .footerarea__copyright__content p a:hover {
  color: var(--primaryColor);
}
.footerarea__copyright__wrapper .footerarea__copyright__list {
  text-align: right;
}
@media (max-width: 767px) {
  .footerarea__copyright__wrapper .footerarea__copyright__list {
    text-align: center;
    margin-top: 20px;
  }
}
.footerarea__copyright__wrapper .footerarea__copyright__list ul li {
  color: var(--darkgray);
  position: relative;
  padding-right: 25px;
}
.footerarea__copyright__wrapper .footerarea__copyright__list ul li a {
  color: var(--darkgray);
}
.footerarea__copyright__wrapper .footerarea__copyright__list ul li a:hover {
  color: var(--primaryColor);
}
.footerarea__copyright__wrapper .footerarea__copyright__list ul li:last-child {
  padding-right: 0;
}
.footerarea__copyright__wrapper .footerarea__copyright__list ul li:last-child::after {
  display: none;
}
.footerarea__copyright__wrapper .footerarea__copyright__list ul li:after {
  position: absolute;
  content: "";
  width: 1px;
  height: 13px;
  background: var(--darkgray);
  bottom: 5px;
  right: 9px;
}

.footerarea__text h3 {
  font-size: 40px;
  line-height: 64px;
  color: var(--whiteColor);
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerarea__text h3 {
    font-size: 25px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .footerarea__text h3 {
    font-size: 36px;
    line-height: 50px;
  }
}
.footerarea__text h3 span {
  color: var(--primaryColor);
}
.footerarea__text p {
  color: rgba(255, 255, 255, 0.65);
  margin-bottom: 0;
}

.foter__bottom__text {
  display: flex;
  align-items: center;
}
.foter__bottom__text .footer__bottom__icon {
  margin-right: 22px;
}
.foter__bottom__text .footer__bottom__icon i {
  width: 78px;
  height: 78px;
  color: var(--whiteColor);
  background: var(--primaryColor);
  display: inline-block;
  text-align: center;
  line-height: 78px;
  font-size: 30px;
}
.foter__bottom__text .footer__bottom__content h6 {
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  color: var(--whiteColor);
  text-transform: uppercase;
  margin-bottom: 0;
}
.foter__bottom__text .footer__bottom__content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.63);
  display: block;
  margin-bottom: 7px;
}

.footerarea__copyright__wrapper__2 .row {
  align-items: center;
}

.footerarea__icon__2 {
  text-align: right;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerarea__icon__2 {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .footerarea__icon__2 {
    text-align: left;
  }
}
.footerarea__icon__2 ul li a {
  width: 40.19px;
  height: 37px;
  background: rgba(255, 255, 255, 0.1);
  color: var(--whiteColor);
  display: inline-block;
  text-align: center;
  line-height: 37px;
  margin-right: 7px;
}
@media (min-width: 992px) and (max-width: 1365px) {
  .footerarea__icon__2 ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin-right: 3px;
  }
}
.footerarea__icon__2 ul li a:hover {
  background: var(--primaryColor);
  color: var(--whiteColor);
}

@media (min-width: 992px) and (max-width: 1365px), (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .footerarea.footerarea__image {
    background-image: none !important;
  }
}

/*------------------------------
Dark Mode
-------------------------------*/
.is_dark .mode_switcher.my_switcher button {
  color: var(--blackColor);
  background: var(--whiteColor);
  border-color: var(--blackColor);
}
.is_dark .headerarea__main__menu nav ul > li .headerarea__submenu > li:hover > a {
  color: var(--blackColor);
}
.is_dark .headerarea__button a {
  color: var(--blackColor);
}
.is_dark .headerarea__login a:hover {
  color: var(--blackColor);
}
.is_dark .herobannerarea__title .herobannerarea__title__heading__2 h2 {
  color: var(--blackColor);
}
.is_dark .herobannerarea__text p {
  color: var(--blackColor);
}
.is_dark .default__button {
  color: var(--blackColor);
}
.is_dark .single__service:hover .service__content h3 a {
  color: var(--blackColor);
}
.is_dark .single__service:hover .service__content h3 a:hover {
  color: var(--secondaryColor);
}
.is_dark .single__service:hover .service__content p {
  color: var(--blackColor);
}
.is_dark .gridarea__wraper .gridarea__img .gridarea__small__button .grid__badge {
  color: var(--blackColor);
}
.is_dark .gridarea__wraper .gridarea__img .gridarea__small__icon i {
  color: var(--blackColor);
}
.is_dark .gridarea__wraper {
  background: var(--naveBlue);
}
.is_dark .single__service:hover .service__img .service__icon__bg path {
  fill: var(--blackColor);
}
.is_dark .gridarea__wraper {
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.1);
}
.is_dark .registerarea__section__title .section__title__heading h2 {
  color: var(--blackColor);
}
.is_dark .registerarea__content .registerarea__video p {
  color: var(--blackColor);
}
.is_dark .registerarea__form {
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.1);
  background: var(--naveBlue);
}
.is_dark .single__event__wraper:hover {
  background: var(--blackColor);
}
.is_dark .default__arrow .slick-arrow {
  color: var(--blackColor);
}
.is_dark .default__arrow .slick-arrow:hover {
  color: var(--secondaryColor);
}
.is_dark .footerarea {
  background: var(--lightGrey);
}
.is_dark .footerarea__newsletter__button a {
  color: var(--blackColor);
}
.is_dark .footerarea__newsletter__button a:hover {
  color: var(--primaryColor);
}
.is_dark .footerarea__wrapper .footerarea__inner .footerarea__heading h3 {
  color: var(--blackColor);
}
.is_dark .footerarea__right__wraper .footerarea__right__list ul li a .footerarea__right__content h6 {
  color: var(--blackColor);
}
.is_dark .footerarea__right__wraper .footerarea__right__list ul li a:hover .footerarea__right__content h6 {
  color: var(--primaryColor);
}
.is_dark .footerarea__wrapper .footerarea__inner .footerarea__icon ul li a {
  color: var(--whiteColor);
}
.is_dark .footerarea__wrapper .footerarea__inner .footerarea__icon ul li a:hover {
  color: var(--blackColor);
  background: var(--secondaryColor);
}
.is_dark .mobile-off-canvas .mobile-aside-button {
  color: var(--blackColor);
}
.is_dark .mobile-off-canvas-active .mobile-aside-close {
  background: var(--darkdeep1);
  color: var(--blackColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form input {
  color: var(--blackColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
  color: var(--blackColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
  border-bottom: 1px solid var(--blackColor);
}
.is_dark .mobile-off-canvas-active .mobile-aside-close:hover {
  background: var(--secondaryColor);
}
.is_dark .mobile-off-canvas-active .mobile-aside-close:hover i {
  color: var(--blackColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  border-bottom: 1px solid var(--blackColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  color: var(--blackColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a:hover {
  color: var(--secondaryColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: var(--secondaryColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a {
  color: var(--blackColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a:hover {
  color: var(--secondaryColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
  color: var(--blackColor);
}
.is_dark .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button:hover {
  color: var(--secondaryColor);
}
.is_dark .populerarea__content__wraper__2 .populerarea__button__2 .default__button {
  color: var(--blackColor);
}
.is_dark .aboutarea__para__3 .aboutarea__icon__3 i {
  color: var(--blackColor);
}
.is_dark .blogarea__content__wraper .blogarea__img .blogarea__date {
  color: var(--blackColor);
}
.is_dark .footerarea__text h3 {
  color: var(--blackColor);
}
.is_dark .foter__bottom__text .footer__bottom__content h6 {
  color: var(--blackColor);
}
.is_dark .foter__bottom__text .footer__bottom__icon i {
  color: var(--blackColor);
}
.is_dark .footerarea__copyright__wrapper .footerarea__copyright__content.footerarea__copyright__content__2 p {
  color: var(--blackColor);
}
.is_dark .footerarea__icon__2 ul li a {
  color: var(--blackColor);
}
.is_dark .single__team .teamarea__img .teamarea__icon ul li i:hover {
  color: var(--blackColor);
}
.is_dark .testimonialarea__paragraph__3 p {
  color: var(--blackColor);
}
.is_dark .testimonial__slider__active__3 .slick-arrow {
  background: var(--blackColor);
}
.is_dark .testimonial__slider__active__3 .slick-arrow:hover {
  background: var(--secondaryColor);
  color: var(--blackColor);
}
.is_dark #scrollUp {
  background: var(--primaryColor);
  color: var(--blackColor);
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.1);
}
.is_dark .blogarae__img__2 .blogarea__date__2 {
  color: var(--blackColor);
}
.is_dark .blog__content__wraper__2 {
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.15);
}
.is_dark .main__pagination__wrapper ul li a.active {
  color: var(--secondaryColor);
}
.is_dark .blogsidebar__content__wraper__2 .populer__tag__list ul li a:hover {
  color: var(--blackColor);
  border-color: var(--primaryColor);
}
.is_dark .blogsidebar__content__wraper__2 .follow__icon ul li a:hover {
  color: var(--blackColor);
}
.is_dark .blogsidebar__content__wraper__2 .categorie__list li:hover {
  border: var(--primaryColor);
}
.is_dark .blogsidebar__content__wraper__2 .categorie__list li:hover a {
  color: var(--blackColor);
}
.is_dark .blogsidebar__content__wraper__2 .photo__gallery__img .single__gallery__img .gallery__icon a {
  color: var(--blackColor);
}
.is_dark .is_dark .topbararea {
  background: var(--lightGrey10);
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
}
.is_dark .topbar__left ul li {
  color: var(--blackColor);
}
.is_dark .topbar__right .topbar__text p {
  color: var(--blackColor);
}
.is_dark .topbar__right .topbar__list ul li a {
  color: var(--blackColor);
}
.is_dark .contact__form__inner {
  border: none;
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
}
.is_dark .course__categories__list ul li a:hover {
  color: var(--blackColor);
}
.is_dark .course__input input {
  border: 1px solid var(--blackColor);
  background: none;
  color: var(--blackColor);
}
.is_dark .course__input .search__button button {
  color: var(--blackColor);
}
.is_dark .course__button a {
  color: var(--blackColor);
}
.is_dark .course__tap__wrap li button.active {
  background: var(--primaryColor);
  color: var(--blackColor);
}
.is_dark .course__tap__wrap li:hover button {
  color: var(--blackColor);
}
.is_dark .content__cirriculum__wrap .accordion-body {
  background: var(--whiteColor);
}
.is_dark .scc__info i {
  color: var(--blackColor);
}
.is_dark .scc__meta {
  color: var(--blackColor);
}
.is_dark .scc__wrap .question {
  color: var(--blackColor);
}
.is_dark .add__a__review__wrapper {
  background-color: transparent;
  box-shadow: 0 0 20px 10px rgba(95, 45, 237, 0.1);
}
.is_dark .add__a__review__wrapper .add__a__review__input input {
  border: 1px solid var(--blackColor);
  background: none;
  color: var(--blackColor);
}
.is_dark .add__a__review__wrapper .add__a__review__input textarea {
  border: 1px solid var(--blackColor);
  background: none;
  color: var(--blackColor);
}
.is_dark .add__a__review__wrapper .mb-0 {
  color: var(--blackColor);
}
.is_dark .blog__details__comment .author__icon button path {
  fill: var(--blackColor);
}
.is_dark .property__comment .property__comment__list .property__comment__comment span {
  color: var(--blackColor);
}
.is_dark .headerarea__right .header__cart::before {
  color: var(--blackColor);
}
.is_dark .eventarea__details__small__button a {
  color: var(--blackColor);
}
.is_dark .single__expart__teacher .teacher__icon ul li a:hover {
  color: var(--blackColor);
}
.is_dark .topbararea {
  background: var(--lightGrey10);
}
.is_dark .headerarea__right .header__cart .header__right__dropdown__wrapper {
  background-color: var(--darkdeep3);
}
.is_dark .headerarea__right .header__cart .header__right__dropdown__wrapper .header__right__dropdown__button a.white__color:hover {
  color: var(--blackColor);
}
.is_dark .blog__details__tag ul li a:hover {
  color: var(--blackColor);
}
.is_dark .aboutarea__list__2 ul li:hover i {
  color: var(--blackColor);
}
.is_dark .content__cirriculum__wrap .accordion-button {
  background-color: var(--bodyBg);
}
.is_dark .blogarea__content__wraper {
  box-shadow: 0 0 50px 10px rgba(95, 45, 237, 0.1);
}
.is_dark .blogsidebar__content__wraper__2 .recent__list li .recent__img .recent__number span {
  color: var(--blackColor);
}
.is_dark .keyfeatures li {
  color: var(--blackColor);
}
.is_dark .herobannerarea.herobannerarea__university .herobannerarea__single__slider .herobannerarea__title h2 {
  color: var(--blackColor);
}
.is_dark .herobannerarea.herobannerarea__university .footerarea__icon__2 p {
  color: var(--blackColor);
}
.is_dark .herobannerarea.herobannerarea__machine__learning .herobannerarea__text__2 p {
  color: var(--blackColor);
}
.is_dark .herobannerarea__machine__learning .slider__controls__wrap.slider__controls__pagination .swiper-pagination-bullet {
  outline: 2px solid var(--blackColor);
}
.is_dark .herobannerarea__machine__learning .slider__controls__wrap.slider__controls__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--blackColor);
}
.is_dark .search__form .search__form__categories svg {
  color: var(--whiteColor);
}
.is_dark .featurearea__price span.featurearea__price__button {
  color: var(--blackColor);
}
.is_dark .featurearea__price span.featurearea__price__button.black__color {
  color: var(--whiteColor);
}
.is_dark .featurearea__countdown .count p {
  color: var(--blackColor);
}
.is_dark .featurearea__countdown .count span {
  color: var(--blackColor);
}
.is_dark .faqarea__heading h3 {
  color: var(--blackColor);
}
.is_dark .faqarea__wrapper .accordion-body {
  background: var(--whiteColor);
}
.is_dark .tab__button__wrap li button {
  box-shadow: 0px 0 20px 5px rgba(255, 255, 255, 0.1);
}
.is_dark .headerarea .mega__menu__thumb {
  box-shadow: 0px 0 20px 5px rgba(255, 255, 255, 0.2);
}
.is_dark .headerarea .mega__menu__thumb__title {
  color: var(--blackColor);
}
.is_dark .headerarea .mega__menu__thumb__label {
  color: var(--blackColor);
}
.is_dark .dashboard__table__row {
  background: var(--whiteColor);
}
.is_dark .dashboard__nav__title h6 {
  background: var(--whiteColor);
}
.is_dark .dashboard__single__counter {
  background: var(--whiteColor);
}
.is_dark .dashboardarea__star span {
  color: var(--blackColor);
}
.is_dark .dashboardarea__left__content h5 {
  color: var(--blackColor);
}
.is_dark .dashboardarea__left__content h4 {
  color: var(--blackColor);
}
.is_dark .dashboard__content__wraper,
.is_dark .dashboard__admin__report__wrap,
.is_dark .dashboard__inner {
  box-shadow: 0 0 1px 1px rgba(95, 45, 237, 0.2);
}
.is_dark .modal__wraper .btn-close {
  background-color: var(--blackColor);
}
.is_dark .create__course__accordion__wraper .accordion-item {
  box-shadow: 0 0 1px 1px rgba(95, 45, 237, 0.2);
}
.is_dark .shopsidebar__widget {
  box-shadow: 0 0 1px 1px rgba(95, 45, 237, 0.2);
}
.is_dark .dashboard__table table thead {
  background: var(--whiteColor);
}
.activeLink{
  color: var(--primaryColor) !important;
}

.cursor-pointer{
  cursor: pointer;
}

