.button-loader {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  
  .spinner {
    width: 100%;
    height: 100%;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left-color: #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  