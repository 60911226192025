:root {
    // color
    --bodyColor: #222222;
    --bodyBg: #fbfbfb;
    --bodyBg1: #F7F7F7;
    --whiteColor: #ffffff;
    --whitegrey: #6A7C92;
    --whitegrey1: #EDEDED;
    --whitegrey2: #F2F6F7;
    --whitegrey3: #E7DEFF;
    --headingColor: #222222;
    --contentColor: #5F6C76;
    --contentColor2: #5C727D;
    --primaryColor: #5F2DED;
    --secondaryColor: #F2277E;
    --secondaryColor2: #FF275A;
    --secondaryColor3: #FF2828;
    --blackColor: #000000;
    --blackColor2: #191B1E;
    --darkblack: #0C0E2B;
    --darkcolor: #263546;
    --darkgray: #9FA2CE;
    --deepgray: #202942;
    --lightGrey: #7B8696;
    --lightGrey2: #7B8697;
    --lightGrey3: #7E858B;
    --lightGrey4: #A2A9B6;
    --lightGrey5: #F0F0F5;
    --lightGrey8: #F0F1F3;
    --lightGrey6: #6D6E75;
    --lightGrey7: #F3F4FD;
    --lightGrey9: #9F98B2;
    --lightGrey10: #F5F5FE;
    --lightGrey11: #F7F8FD;
    --lightGrey12: #f0f4f7;
    --greencolor: #44CEA9;
    --greencolor2: #1EC902;
    --greencolor3: #2EC14A;
    --orange: #FE2323;
    --borderColor: #eeeeee;
    --borderColor1: #E5E5E5;
    --borderColor2: #dddddd;
    --borderColor3: #f8f8f8;
    --borderColor4: #cacaca;
    --darkdeep1: #170F2F;
    --darkdeep2: #37404E;
    --darkdeep3: #F4F4F8;
    --darkdeep4: #949392;
    --darkdeep5: #62605D;
    --darkdeep6: #7E8285;
    --darkdeep7: #C4C4C4;
    --indigo: #B13BFF;
    --deepgreen: #133344;
    --deepblue: #505975;
    --deepred: #DC164D;
    --skycolor: #03A9F4;
    --yellow: #FF912C;
    --yellow1: #F0B410;
    --blue: #2864FF;
    --naveBlue: #17093e;
    //--boxshadow1:0px 16px 32px 0px rgb(0 0 0 / 4%);
    //--boxshadow2:0px 0px 0px 8px rgb(255 255 255 / 20%);
    --borderRadius: 4px;
    --borderRadius2: 20px;
    --borderRadius3: 10px;
    --transition: .3s;
    //--gradientColor : linear-gradient(132.71deg, var(--secondaryColor) 18.12%, var(--headingColor)2 81.74%);
    //--gradientColor2 :  linear-gradient(3.98deg, rgba(0, 0, 0, 0) 3.24%, var(--blackColor) 93.62%);
    //--defaultShadow : 4px 4px 60px rgba(99, 69, 237, 0.2);
    --bodyFont: 'Inter', sans-serif;
    --headingFont: 'Hind', sans-serif;
    --contentFont: 'Inter', sans-serif;
}

.is_dark {
    --bodyColor: #fff;
    --bodyBg: #0C0E2B;
    --bodyBg1: #0C0E2B;
    --headingColor: #fff;
    --blackColor: #fff;
    --blackColor2: #fff;
    --whiteColor: #0C0E2B;
    --darkdeep2: #fff;
    --darkblack: #fff;
    --lightGrey: #0C0E2B;
    --lightGrey7: #170F2F;
    --lightGrey10: #170F2F;
    --lightGrey11: #170F2F;
    --contentColor: #fff;
    --darkdeep5: #fff;
    --borderColor: #312a57;
    --whitegrey: #fff;
    --whitegrey1: #312a57;
    --whitegrey2: #312a57;
    --deepgray: #fff;
    --borderColor2: #fff;
    --darkdeep3: #17093e;
    --contentColor2: #fff;
    --deepgreen: #fff;
    --bs-body-color:#fff;
}

// Responsive Variables
$device-xxl: '(min-width: 1500px) and (max-width: 1920px)';
$device-xl: '(min-width: 1366px) and (max-width: 1499px)';
$device-lg: '(min-width: 992px) and (max-width: 1365px)';
$device-md: '(min-width: 768px) and (max-width: 991px)';
$device-sm:'(max-width: 767px)';
$device-sm-large:'(min-width: 576px) and (max-width: 767px)';
$device-sm-small:'(min-width: 320px) and (max-width: 575px)';