/*------------------------------
Breadcrumb Area
-------------------------------*/

.breadcrumbarea {
    padding-top: 162px;
    padding-bottom: 150px;
    background: var(--lightGrey10);
    position: relative;
    @media #{$device-lg} {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media #{$device-md} {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media #{$device-sm} {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &--2 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &--3 {
        padding-bottom: 0;
        padding-top: 50px;
    }
}

.breadcrumb__content__wraper {
    text-align: center;
    position: relative;
    z-index: 9;
    .breadcrumb__title {
        h2 {
            font-weight: 700;
            font-size: 55px;
            line-height: 72px;
            color: var(--blackColor2);
            margin: 0;
            @media #{$device-lg} {
                font-size: 40px;
                line-height: 60px;
            }
            @media #{$device-md} {
                font-size: 40px;
                line-height: 60px;
            }
            @media #{$device-sm} {
                font-size: 30px;
            }
        }
    }
}

.shape__icon__2 {
    .shape__icon__img {
        position: absolute;
        &.shape__icon__img__1 {
            bottom: 60px;
            left: 165px;
            animation: 3s linear 0s infinite normal none running move5;
            @media #{$device-lg} {
                bottom: 21px;
                left: 50px;
            }
            @media #{$device-md} {
                bottom: 0px;
                left: 14px;
            }
            @media #{$device-sm} {
                bottom: 0px;
                left: 0px;
            }
        }
        &.shape__icon__img__2 {
            top: 100px;
            left: 50px;
            animation: cir-anim 6s linear 0s infinite alternate;
            @media #{$device-md} {
                top: 0px;
                left: 0px;
            }
            @media #{$device-sm} {
                top: 0px;
                left: 0px;
            }
        }
        &.shape__icon__img__3 {
            top: 80px;
            right: 575px;
            animation: 3s linear 0s infinite alternate none running dot-anim;
            @media #{$device-lg} {
                top: 0px;
            }
            @media #{$device-md} {
                top: 0px;
                right: 40px;
            }
            @media #{$device-sm} {
                top: 0px;
                right: 30px;
            }
        }
        &.shape__icon__img__4 {
            top: 100px;
            right: 45px;
            animation: 3s linear 0s infinite alternate none running dot-anim-2;
            @media #{$device-md} {
                top: 157px;
                right: 40px;
            }
            @media #{$device-sm} {
                top: 212px;
                right: 30px;
            }
        }
    }
}

.breadcrumb__inner {
    ul {
        li {
            font-size: 18px;
            line-height: 22px;
            color: var(--blackColor2);
            font-weight: 400;
            a {
                position: relative;
                padding-right: 26px;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: var(--blackColor2);
                &:hover {
                    color: var(--primaryColor);
                }
                &:after {
                    position: absolute;
                    content: "\eaa0";
                    font-family: "icofont";
                    top: 0px;
                    right: 3px;
                }
            }
        }
    }
}

.breadcrumbarea--3 .blogarae__img__2.course__details__img__2 {
    margin-bottom: 0;
}